export enum NotificationType {
  DOCUMENTS_SHARED = 'DOCUMENTS_SHARED',
  DOCUMENT_EXPIRING = 'DOCUMENT_EXPIRING',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  DOCUMENT_ACCESSED = 'DOCUMENT_ACCESSED',
}

export interface Notification {
  id: string;
  userId: string;
  type: NotificationType;
  title: string;
  link?: string;
  read: boolean;
  createdAt: string;
  updatedAt: string;
}