export default {
  translation: {
    common: {
      appName: 'ComplyDoc',
      profile: 'Profil',
      settings: 'Paramètres',
      notifications: 'Notifications',
      userMenu: 'Menu utilisateur',
      menu: 'Menu',
      logout: 'Déconnexion',
      close: 'Fermer',
      cancel: 'Annuler',
      save: 'Enregistrer',
      loading: 'Chargement...',
      error: 'Erreur',
      success: 'Succès',
      updating: 'Mise à jour...',
      plan: 'Souscription',
      or: 'ou'
    },
    navigation: {
      dashboard: 'Tableau de bord',
      documents: 'Documents',
      clients: 'Clients',
      messages: 'Messages',
      reports: 'Rapports',
      settings: 'Paramètres'
    },
    auth: {
      login: {
        title: 'Connexion',
        email: 'Email',
        password: 'Mot de passe',
        submit: 'Se connecter',
        forgotPassword: 'Mot de passe oublié ?',
        noAccount: 'Pas encore de compte ?',
        signUp: "S'inscrire",
        or: 'ou',
        errors: {
          invalidEmail: 'Adresse email invalide',
          emailRequired: "L'email est requis",
          passwordRequired: 'Le mot de passe est requis',
          loginFailed: 'La connexion a échoué'
        }
      },
      register: {
        title: 'Inscription',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Email',
        password: 'Mot de passe',
        role: 'Rôle',
        roles: {
          freelancer: 'Freelance',
          client: 'Client'
        },
        submit: "S'inscrire",
        haveAccount: 'Déjà un compte ?',
        signIn: 'Se connecter',
        or: 'ou',
        selectedPlan: 'Plan sélectionné',
        errors: {
          firstNameRequired: 'Le prénom est requis',
          firstNameMin: 'Le prénom doit contenir au moins 2 caractères',
          firstNameMax: 'Le prénom doit contenir moins de 50 caractères',
          lastNameRequired: 'Le nom est requis',
          lastNameMin: 'Le nom doit contenir au moins 2 caractères',
          lastNameMax: 'Le nom doit contenir moins de 50 caractères',
          invalidEmail: 'Adresse email invalide',
          emailRequired: "L'email est requis",
          passwordMin: 'Le mot de passe doit contenir au moins 8 caractères',
          passwordLowercase: 'Le mot de passe doit contenir au moins une lettre minuscule',
          passwordUppercase: 'Le mot de passe doit contenir au moins une lettre majuscule',
          passwordNumber: 'Le mot de passe doit contenir au moins un chiffre',
          passwordRequired: 'Le mot de passe est requis',
          invalidRole: 'Rôle invalide',
          roleRequired: 'Le rôle est requis'
        }
      },
      forgotPassword: {
        title: 'Réinitialisation du mot de passe',
        description: 'Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
        email: 'Adresse e-mail',
        submit: 'Envoyer le lien',
        backToLogin: 'Retour à la connexion',
        success: 'Un e-mail de réinitialisation a été envoyé à votre adresse.',
        errors: {
          invalidEmail: 'Adresse e-mail invalide',
          emailRequired: 'L\'adresse e-mail est requise',
          resetFailed: 'La réinitialisation a échoué. Veuillez réessayer.',
          userNotFound: 'Aucun compte associé à cette adresse e-mail',
          tooManyAttempts: 'Trop de tentatives. Veuillez réessayer plus tard.',
        },
      },
      socialAuth: {
        continueWithGoogle: 'Continuer avec Google',
        continueWithGithub: 'Continuer avec Github'
      },
      alreadyHaveAccount: 'Vous avez déjà un compte ?',
      logout: 'Déconnexion',
    },
    profile: {
      title: 'Mon Profil',
      description: 'Gérez vos informations personnelles et professionnelles',
      updateSuccess: 'Profil mis à jour avec succès',
      tabs: {
        personal: 'Informations Personnelles',
        company: 'Informations Société',
        companies: 'Mes Sociétés',
        security: 'Sécurité',
        notifications: 'Notifications',
      },
      fields: {
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Email',
        phone: 'Téléphone',
        password: 'Mot de passe',
        description: 'Description du profil',
        addressTitle: 'Informations d\'adresse',
        contact: 'Informations de contact',
        freelancer: 'Informations freelance',
        address: 'Adresse',
        city: 'Ville',
        postalCode: 'Code Postal',
        country: 'Pays',
        companyName: 'Nom de la société',
        siret: 'SIRET',
        vatNumber: 'Numéro de TVA',
        companyAddress: 'Adresse de la société',
        companyCity: 'Ville',
        companyPostalCode: 'Code Postal',
        companyCountry: 'Pays',
        registrationNumber: 'Numéro d\'immatriculation',
        legalStatus: 'Statut juridique',
      },
      notifications: {
        title: 'Préférences de notification',
        description: 'Choisissez les notifications que vous souhaitez recevoir',
        email: 'Notifications par email',
        expiry: 'Alertes d\'expiration',
        digest: 'Résumé hebdomadaire',
        security: 'Alertes de sécurité',
        save: 'Enregistrer les préférences',
        success: 'Préférences mises à jour',
      },
      security: {
        password: {
          title: 'Modifier le mot de passe',
          description: 'Choisissez un mot de passe fort pour sécuriser votre compte',
          current: 'Mot de passe actuel',
          new: 'Nouveau mot de passe',
          confirm: 'Confirmer le mot de passe',
          update: 'Mettre à jour',
          success: 'Mot de passe mis à jour avec succès',
          validation: {
            required: 'Le mot de passe est requis',
            minLength: 'Le mot de passe doit contenir au moins 8 caractères',
            pattern: 'Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre',
            match: 'Les mots de passe doivent correspondre'
          }
        },
        twoFactor: {
          title: 'Authentification à deux facteurs',
          enable: 'Activer l\'authentification à deux facteurs',
        },
      },
    },
    subscription: {
      title: 'Gestion de l\'abonnement',
      description: 'Gérez votre abonnement, consultez l\'historique de facturation et téléchargez vos factures',
      currentPlan: {
        title: 'Abonnement actuel',
        status: {
          active: 'Actif',
          canceled: 'Annulé',
          past_due: 'En retard',
          incomplete: 'Incomplet'
        },
        renewalInfo: 'Votre abonnement sera {{action}} le',
        renewal: {
          renew: 'renouvelé',
          end: 'terminé'
        }
      },
      billing: {
        history: 'Historique de facturation',
        noInvoices: 'Aucune facture disponible',
        downloadInvoice: 'Télécharger',
        date: 'Date',
        amount: 'Montant',
        status: 'Statut',
        invoice: 'Facture'
      },
      actions: {
        manage: 'Gérer l\'abonnement',
        cancel: 'Annuler l\'abonnement'
      }
    },
    notifications: {
      title: 'Notifications',
      empty: 'Aucune notification',
      markAllRead: 'Tout marquer comme lu',
      loadMore: 'Charger plus',
      aria: {
        toggle: 'Afficher/masquer les notifications',
      },
    },
    certification: {
      title: 'Statut de Certification',
      badges: {
        gold: 'Certifié Or',
        silver: 'Certifié Argent',
        bronze: 'Certifié Bronze',
        none: 'Non Certifié',
      },
      details: {
        title: 'Détails de la Certification',
        status: 'Statut',
        validity: 'Période de Validité',
        requirements: 'Exigences',
      },
      requirements: {
        kbis: 'Document KBIS',
        urssaf: 'Certificat URSSAF',
        insurance: 'Assurance Professionnelle',
        tax: 'Conformité Fiscale',
        identity: 'Document d\'Identité',
        bank: 'Coordonnées Bancaires',
      },
      status: {
        valid: 'Valide',
        expired: 'Expiré',
        expiringSoon: 'Expire Bientôt',
        missing: 'Manquant',
      },
    },
    landing: {
      nav: {
        features: 'Fonctionnalités',
        pricing: 'Tarifs',
      },
      hero: {
        title: 'Simplifiez la Gestion de la Conformité',
        subtitle: 'Centralisez, Partagez et Validez vos Documents Administratifs',
        startTrail: 'Essai Gratuit',
        signIn: 'Se connecter'
      },
      features: {
        title: 'Fonctionnalités principales',
        documentUpload: {
          title: 'Gestion des documents',
          description: 'Téléchargez et organisez vos documents en toute simplicité'
        },
        reminders: {
          title: 'Rappels intelligents',
          description: 'Recevez des notifications pour vos échéances importantes'
        },
        sharing: {
          title: 'Partage sécurisé',
          description: 'Partagez vos documents en toute sécurité avec vos clients'
        },
        timeSaving: {
          title: 'Gain de temps',
          description: 'Automatisez vos tâches administratives'
        }
      },
      plans: {
        basic: {
          name: 'Basique',
          features: {
            singlecompany: 'Une seule entreprise',
            basicdocumentmanagement: 'Gestion basique des documents',
            manualdocumentupload: 'Téléchargement manuel des documents',
            basicnotifications: 'Notifications basiques'
          }
        },
        pro: {
          name: 'Pro',
          features: {
            multiplecompanies: 'Plusieurs entreprises',
            automaticdocumentretrieval: 'Récupération automatique des documents',
            advancednotifications: 'Notifications avancées',
            detaileddashboard: 'Tableau de bord détaillé',
            prioritysupport: 'Support prioritaire'
          }
        }
      },
      pricing: {
        subtitle: 'Tarification simple et transparente',
        startFree: 'Commencer gratuitement',
        startTrial: 'Commencer votre essai gratuit',
        perMonth: '/mois',
        perYear: '/an',
        starter: {
          title: 'Débutant',
          price: '0',
          features: ['10 documents max.', 'Partage basique'],
        },
        professional: {
          title: 'Pro',
          price: '29',
          features: ['Documents illimités', 'Partage avancé', 'Sécurité renforcée'],
        },
        enterprise: {
          title: 'Entreprise',
          price: 'Sur mesure',
          features: ['Intégration personnalisée', 'Analyses avancées'],
        },
      },
    },
    footer: {
      about: {
        title: 'À Propos',
        description: 'Nous aidons les freelances à gérer leur conformité.',
      },
      followUs: 'Suivez-nous',
      newsletter: {
        title: 'Newsletter',
        description: 'Abonnez-vous à notre newsletter pour recevoir nos actualités et conseils de conformité.',
        placeholder: 'Votre email',
        subscribe: 'S\'inscrire',
        success: 'Inscription réussie !',
        error: 'Email invalide',
      },
    },
    chatbot: {
      title: 'Assistant Support',
      welcome: 'Bonjour ! Comment puis-je vous aider ?',
      suggestions: {
        upload: 'Télécharger des documents',
        share: 'Partager des documents',
        pricing: 'Voir les tarifs',
      },
    },
    dashboard: {
      title: 'Tableau de Bord',
    },
    reports: {
      title: 'Rapports',
      description: 'Consultez et générez des rapports détaillés pour votre entreprise',
      types: {
        annual: 'Rapport de Performance Annuel',
        declaration: 'Déclaration Annuelle',
        compliance: 'Rapport de Conformité Réglementaire',
        financial: 'Analyse Financière'
      },
      filters: {
        title: 'Paramètres du Rapport',
        period: 'Période',
        periods: {
          monthly: 'Mensuel',
          quarterly: 'Trimestriel',
          annual: 'Annuel'
        },
        indicators: 'Indicateurs',
        layout: 'Options de Mise en Page'
      },
      data: {
        company: 'Informations de l\'Entreprise',
        financial: 'Données Financières',
        management: 'Informations de Gestion',
        legal: 'Documents Juridiques',
        annual: "Rapports Annuels",
        declaration: "Rapports de Déclaration",
        compliance: "Rapports de Conformité",
      },
      actions: {
        generate: 'Générer le Rapport',
        export: 'Exporter',
        share: 'Partager',
        exportFormats: {
          pdf: 'PDF',
          excel: 'Excel',
          csv: 'CSV'
        }
      },
      alerts: {
        deadline: 'Échéances Importantes',
        changes: 'Changements Significatifs',
        upcoming: 'Événements à Venir'
      },
      kpi: {
        revenue: 'Chiffre d\'Affaires',
        profit: 'Bénéfices',
        growth: 'Taux de Croissance',
        ratios: 'Ratios Financiers'
      },
      loading: {
        data: 'Chargement des données de l\'entreprise...',
        generating: 'Génération du rapport...',
        exporting: 'Préparation de l\'export...'
      },
      errors: {
        api: 'Erreur lors de la récupération des données Pappers',
        generation: 'Erreur lors de la génération du rapport',
        export: 'Erreur lors de l\'export du rapport'
      },
      success: {
        generated: 'Rapport généré avec succès',
        exported: 'Rapport exporté avec succès',
        shared: 'Rapport partagé avec succès'
      },
      subscription: {
        proFeature: 'Fonctionnalité Pro',
        basicMessage: 'Passez au plan Pro pour accéder à toutes les fonctionnalités des rapports',
        upgradeButton: 'Passer au Pro',
        preview: {
          title: 'Aperçu des Rapports',
          description: 'Découvrez nos fonctionnalités complètes de reporting',
          limitedAccess: 'Accès limité avec le plan Basic',
          fullAccess: 'Accès complet avec le plan Pro',
          features: {
            basic: [
              'Informations de base sur l\'entreprise',
              'Métriques financières limitées',
              'Aperçu des documents légaux'
            ],
            pro: [
              'Analyse détaillée de l\'entreprise',
              'Données financières complètes',
              'Tous les documents légaux',
              'Capacités d\'export et de partage',
              'Génération de rapports personnalisés'
            ]
          }
        }
      },
    },
    config: {
      firebase: {
        errors: {
          auth: {
            domainNotAllowed: "Domaine non autorisé",
            projectNotFound: "Projet non trouvé",
            storageBucketError: "Erreur de stockage",
            messagingError: "Erreur de messagerie",
            appIdError: "Erreur d'identifiant d'application",
            measurementError: "Erreur de mesure"
          }
        }
      },
      stripe: {
        errors: {
          invalidKey: "Clé API invalide",
          connectionError: "Erreur de connexion au service de paiement",
          configError: "Erreur de configuration"
        }
      }
    },
    clients: {
      title: 'Clients',
      description: 'Gérez vos clients et leurs informations',
      add: 'Ajouter un client',
      search: 'Rechercher un client',
      noResults: 'Aucun client trouvé',
      table: {
        name: 'Nom',
        email: 'Email',
        company: 'Entreprise',
        status: 'Statut',
        lastActivity: 'Dernière activité',
        actions: 'Actions'
      },
      status: {
        active: 'Actif',
        inactive: 'Inactif',
        pending: 'En attente'
      },
      actions: {
        edit: 'Modifier',
        delete: 'Supprimer',
        view: 'Voir'
      },
      form: {
        title: 'Informations du client',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Email',
        company: 'Entreprise',
        phone: 'Téléphone',
        address: 'Adresse',
        save: 'Enregistrer',
        cancel: 'Annuler'
      }
    },
    documents: {
      title: 'Documents',
      description: 'Gérez vos documents et fichiers',
      search: 'Rechercher un document',
      noResults: 'Aucun document trouvé',
      table: {
        name: 'Nom',
        type: 'Type',
        size: 'Taille',
        uploadDate: 'Date d\'envoi',
        status: 'Statut',
        actions: 'Actions'
      },
      status: {
        draft: 'Brouillon',
        pending: 'En attente',
        approved: 'Approuvé',
        rejected: 'Rejeté'
      },
      actions: {
        edit: 'Modifier',
        delete: 'Supprimer',
        download: 'Télécharger',
        preview: 'Aperçu',
        share: 'Partager'
      },
      filters: {
        all: 'Tous',
        recent: 'Récents',
        shared: 'Partagés',
        favorites: 'Favoris'
      },
      upload: {
        title: 'Enregistrer',
        dragDrop: 'Glissez-déposez vos fichiers ici',
        browse: 'Parcourir',
        maxSize: 'Taille maximale : {{size}}',
        allowedTypes: 'Types de fichiers autorisés : {{types}}'
      }
    },
  },
};
