import { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import {
  Circle as UnreadIcon,
  Description as DocumentIcon,
  Warning as AlertIcon,
  Message as MessageIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Notification, NotificationType } from '../../types/notification';

interface NotificationItemProps {
  notification: Notification;
  onRead: (id: string) => Promise<void>;
  onClose: () => void;
}

const NotificationItem = ({ notification, onRead, onClose }: NotificationItemProps) => {
  const navigate = useNavigate();
  const [isReading, setIsReading] = useState(false);

  const getIcon = () => {
    switch (notification.type) {
      case NotificationType.DOCUMENTS_SHARED:
        return <DocumentIcon color="primary" />;
      case NotificationType.DOCUMENT_EXPIRING:
      case NotificationType.DOCUMENT_EXPIRED:
      case NotificationType.DOCUMENT_ACCESSED:
        return <AlertIcon color="warning" />;
      default:
        return <AlertIcon />;
    }
  };

  const handleClick = async () => {
    if (!notification.read) {
      setIsReading(true);
      await onRead(notification.id);
      setIsReading(false);
    }

    if (notification.link) {
      onClose();
      navigate(notification.link);
    }
  };

  return (
    <ListItem
      button
      onClick={handleClick}
      sx={{
        bgcolor: notification.read ? 'transparent' : 'action.hover',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <ListItemIcon>
        {getIcon()}
      </ListItemIcon>
      <ListItemText
        primary={notification.title}
        secondary={
          <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              component="span"
            >
              {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
            </Typography>
            {!notification.read && !isReading && (
              <UnreadIcon sx={{ fontSize: 8, color: 'primary.main' }} />
            )}
            {isReading && <CheckIcon sx={{ fontSize: 12, color: 'success.main' }} />}
          </Box>
        }
      />
    </ListItem>
  );
};

export default NotificationItem;