import { CompanyData } from '../types/company';

interface CachedData<T> {
  data: T;
  timestamp: number;
  expiresAt: number;
}

interface CacheOptions {
  forceRefresh?: boolean;
  expirationInMs?: number;
}

class CacheService {
  private static MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000; // 30 jours en millisecondes

  // Vérifie si les données sont expirées
  private isExpired(timestamp: number): boolean {
    const now = Date.now();
    return now >= timestamp;
  }

  // Sauvegarde les données dans le localStorage avec une date d'expiration
  async setItem<T>(
    key: string, 
    data: T, 
    options: CacheOptions = {}
  ): Promise<void> {
    const { expirationInMs = CacheService.MONTH_IN_MS } = options;
    
    const cachedData: CachedData<T> = {
      data,
      timestamp: Date.now(),
      expiresAt: Date.now() + expirationInMs
    };
    
    try {
      localStorage.setItem(key, JSON.stringify(cachedData));
    } catch (error) {
      console.error('Erreur lors de la sauvegarde dans le cache:', error);
      // En cas d'erreur (ex: localStorage plein), on nettoie le cache
      this.clearExpiredCache();
      // On réessaie de sauvegarder
      localStorage.setItem(key, JSON.stringify(cachedData));
    }
  }

  // Récupère les données du cache si elles ne sont pas expirées
  async getItem<T>(
    key: string,
    options: CacheOptions = {}
  ): Promise<T | null> {
    const { forceRefresh = false } = options;

    // Si on force le rafraîchissement, on ignore le cache
    if (forceRefresh) {
      return null;
    }

    const cachedDataString = localStorage.getItem(key);
    
    if (!cachedDataString) {
      return null;
    }

    try {
      const cachedData: CachedData<T> = JSON.parse(cachedDataString);
      
      if (this.isExpired(cachedData.expiresAt)) {
        localStorage.removeItem(key);
        return null;
      }

      return cachedData.data;
    } catch {
      return null;
    }
  }

  // Supprime une entrée spécifique du cache
  async removeItem(key: string): Promise<void> {
    localStorage.removeItem(key);
  }

  // Nettoie les entrées expirées du cache
  async clearExpiredCache(): Promise<void> {
    const keys = Object.keys(localStorage);
    
    for (const key of keys) {
      try {
        const cachedDataString = localStorage.getItem(key);
        if (cachedDataString) {
          const cachedData = JSON.parse(cachedDataString);
          if (this.isExpired(cachedData.expiresAt)) {
            localStorage.removeItem(key);
          }
        }
      } catch {
        // Si on ne peut pas parser les données, on les supprime
        localStorage.removeItem(key);
      }
    }
  }

  // Vide complètement le cache
  async clearAll(): Promise<void> {
    localStorage.clear();
  }

  // Préfixe une clé avec un namespace pour éviter les collisions
  getKeyWithNamespace(namespace: string, key: string): string {
    return `${namespace}:${key}`;
  }
}

export const cacheService = new CacheService();
