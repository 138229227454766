import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Link as MuiLink,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useAuthStore } from '../../stores/authStore';
import AuthLayout from '../../layouts/AuthLayout';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { resetPassword } = useAuthStore();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('auth.forgotPassword.errors.invalidEmail', ''))
      .required(t('auth.forgotPassword.errors.emailRequired', )),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        setError('');
        await resetPassword(values.email);
        setSuccess(true);
      } catch (err) {
        setError(err instanceof Error ? err.message : t('auth.forgotPassword.errors.resetFailed'));
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <AuthLayout>
      <Container maxWidth="sm">
        <Paper 
          elevation={3}
          sx={{ 
            p: 4,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'background.paper' : 'background.default',
          }}
        >
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            {t('auth.forgotPassword.title')}
          </Typography>

          <Typography variant="body2" align="center" sx={{ mb: 3 }}>
            {t('auth.forgotPassword.description')}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {success ? (
            <Box>
              <Alert severity="success" sx={{ mb: 2 }}>
                {t('auth.forgotPassword.success')}
              </Alert>
              <Button
                fullWidth
                variant="contained"
                onClick={() => navigate('/login')}
                sx={{ mt: 2 }}
              >
                {t('auth.forgotPassword.backToLogin')}
              </Button>
            </Box>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label={t('auth.forgotPassword.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
                type="email"
                autoComplete="email"
                disabled={isSubmitting}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  t('auth.forgotPassword.submit')
                )}
              </Button>

              <Box sx={{ textAlign: 'center' }}>
                <MuiLink 
                  component={Link} 
                  to="/login"
                  variant="body2"
                  sx={{ 
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {t('auth.forgotPassword.backToLogin')}
                </MuiLink>
              </Box>
            </form>
          )}
        </Paper>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPassword;
