import { useQuery } from '@tanstack/react-query';
import { getUserProfile, createDefaultProfile } from '../services/profile';
import { useAuthStore } from '../stores/authStore';

export const useProfileData = (userId?: string) => {
  const { user } = useAuthStore();
  
  return useQuery({
    queryKey: ['profile', userId],
    queryFn: async () => {
      if (!userId) throw new Error('No user ID');

      try {
        return await getUserProfile(userId);
      } catch (error) {
        // If profile doesn't exist, create a default one
        if (error instanceof Error && error.message === 'Profile not found') {
          if (!user?.email) throw new Error('User email is required to create profile');
          
          return await createDefaultProfile(userId, {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email
          });
        }
        throw error;
      }
    },
    enabled: !!userId,
  });
};