import { useTranslation } from 'react-i18next';

export interface Message {
  type: 'user' | 'bot';
  content: string;
}

type Locale = 'en' | 'fr';

interface ChatbotResponse {
  [key: string]: {
    [key in Locale]: string;
  };
}

const responses: ChatbotResponse = {
  default: {
    en: "I'm not sure how to help with that. Could you try rephrasing your question?",
    fr: "Je ne suis pas sûr de pouvoir vous aider avec ça. Pourriez-vous reformuler votre question ?"
  },
  greeting: {
    en: "Hello! How can I help you today?",
    fr: "Bonjour ! Comment puis-je vous aider aujourd'hui ?"
  },
  upload_document: {
    en: "To upload a document, go to the Documents section and click the 'Upload Document' button. You can then select your file and add relevant details like title and expiry date.",
    fr: "Pour télécharger un document, allez dans la section Documents et cliquez sur le bouton 'Télécharger un document'. Vous pourrez ensuite sélectionner votre fichier et ajouter les détails pertinents comme le titre et la date d'expiration."
  },
  share_document: {
    en: "To share documents with clients, first ensure they are added to your client list. Then, you can grant them access through the Clients section by toggling the 'Document Access' switch.",
    fr: "Pour partager des documents avec vos clients, assurez-vous d'abord qu'ils sont ajoutés à votre liste de clients. Ensuite, vous pouvez leur accorder l'accès via la section Clients en activant le bouton 'Accès aux documents'."
  },
  document_types: {
    en: "We support various document types including: Identity documents, Insurance certificates, Tax documents, Business documents, and Certifications. All documents should be in PDF or image format.",
    fr: "Nous prenons en charge différents types de documents, notamment : Documents d'identité, Attestations d'assurance, Documents fiscaux, Documents commerciaux et Certifications. Tous les documents doivent être au format PDF ou image."
  },
  pricing: {
    en: "We offer three plans: Starter (free), Professional (€29/month), and Enterprise (custom pricing). Each plan includes different features and document limits. Check our pricing page for more details.",
    fr: "Nous proposons trois forfaits : Débutant (gratuit), Professionnel (29€/mois) et Entreprise (prix sur mesure). Chaque forfait comprend différentes fonctionnalités et limites de documents. Consultez notre page de tarifs pour plus de détails."
  },
  security: {
    en: "We take security seriously. All documents are encrypted, and we use secure cloud storage. Access is strictly controlled through user permissions, and all actions are logged.",
    fr: "Nous prenons la sécurité au sérieux. Tous les documents sont cryptés et nous utilisons un stockage cloud sécurisé. L'accès est strictement contrôlé par des permissions utilisateur, et toutes les actions sont enregistrées."
  }
};

const findBestMatch = (input: string, locale: Locale): string => {
  input = input.toLowerCase();
  
  if (input.includes('hello') || input.includes('hi') || input.includes('bonjour')) {
    return responses.greeting[locale];
  }
  if (input.includes('upload') || input.includes('télécharger')) {
    return responses.upload_document[locale];
  }
  if (input.includes('share') || input.includes('partager')) {
    return responses.share_document[locale];
  }
  if (input.includes('document type') || input.includes('type de document')) {
    return responses.document_types[locale];
  }
  if (input.includes('price') || input.includes('pricing') || input.includes('tarif')) {
    return responses.pricing[locale];
  }
  if (input.includes('security') || input.includes('secure') || input.includes('sécurité')) {
    return responses.security[locale];
  }

  return responses.default[locale];
};

export const getChatbotResponse = async (input: string): Promise<string> => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  const locale = (document.documentElement.lang || 'en') as Locale;
  return findBestMatch(input, locale);
};