/// <reference types="@types/google.maps" />

declare global {
  interface Window {
    google: typeof google;
  }
}

const waitForGoogleMapsToLoad = (): Promise<void> => {
  return new Promise((resolve) => {
    if (window.google && window.google.maps) {
      resolve();
      return;
    }

    const checkGoogleInterval = setInterval(() => {
      if (window.google && window.google.maps) {
        clearInterval(checkGoogleInterval);
        resolve();
      }
    }, 100);
  });
};

const PLACES_API_BASE_URL = 'https://maps.googleapis.com/maps/api/place';

export interface PlaceAutocompleteResult {
  description: string;
  placeId: string;
  mainText: string;
  secondaryText: string;
}

export interface PlaceDetails {
  address: string;
  postalCode: string;
  city: string;
  country: string;
}

export async function searchAddresses(input: string): Promise<PlaceAutocompleteResult[]> {
  await waitForGoogleMapsToLoad();
  
  return new Promise((resolve, reject) => {
    const autocompleteService = new google.maps.places.AutocompleteService();
    
    autocompleteService.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: 'fr' },
        types: ['address'],
      },
      (
        predictions: google.maps.places.AutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
          reject(new Error('Failed to fetch address suggestions'));
          return;
        }

        resolve(
          predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
            mainText: prediction.structured_formatting?.main_text || '',
            secondaryText: prediction.structured_formatting?.secondary_text || '',
          }))
        );
      }
    );
  });
}

export async function getPlaceDetails(placeId: string): Promise<PlaceDetails> {
  await waitForGoogleMapsToLoad();

  return new Promise((resolve, reject) => {
    const tempDiv = document.createElement('div');
    const placesService = new google.maps.places.PlacesService(tempDiv);

    placesService.getDetails(
      {
        placeId,
        fields: ['address_components'],
      },
      (
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !place || !place.address_components) {
          reject(new Error('Failed to fetch place details'));
          return;
        }

        const details: PlaceDetails = {
          address: '',
          postalCode: '',
          city: '',
          country: '',
        };

        place.address_components.forEach((component) => {
          const longName = component.long_name || '';
          
          if (component.types.includes('street_number') || component.types.includes('route')) {
            details.address += details.address ? ' ' + longName : longName;
          }
          if (component.types.includes('postal_code')) {
            details.postalCode = longName;
          }
          if (component.types.includes('locality')) {
            details.city = longName;
          }
          if (component.types.includes('country')) {
            details.country = longName;
          }
        });

        resolve(details);
      }
    );
  });
}
