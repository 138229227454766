import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Alert,
  Stack,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { Client } from '../types';
import ClientFormDrawer from '../components/ClientFormDrawer';
import { getClients, createClient, updateClient, deleteClient } from '../services/firestore';
import { useAuthStore } from '../stores/authStore';
import ComplianceBadge from '../components/ComplianceBadge';
import { calculateCertificationStatus } from '../services/certification';
import { Document } from '../types';
import { getDocuments } from '../services/documents';
import { DataGrid, GridColDef, GridToolbar, GridRenderCellParams, GridLocaleText } from '@mui/x-data-grid';
import ShareDocumentsDrawer from '../components/ShareDocumentsDrawer';
import SharedDocumentsBadge from '../components/SharedDocumentsBadge';
import { shareDocuments } from '../services/documentSharing';

const Clients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [documents, setDocuments] = useState<Document[]>([]);
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const user = useAuthStore(state => state.user);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchClients = async () => {
    if (!user?.id) return;
    
    try {
      setLoading(true);
      const fetchedClients = await getClients(user.id);
      console.log('Clients chargés:', fetchedClients);
      setClients(fetchedClients);
    } catch (err) {
      setError('Échec du chargement des clients');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocuments = async () => {
    if (user?.id) {
      try {
        // 1. Récupérer tous les documents
        const docs = await getDocuments(user.id);
        console.log('Documents chargés:', docs);
        
        // 2. Marquer les documents partagés
        const sharedDocs = docs.filter(doc => 
          doc.shares?.some(share => share.clientId) || doc.sharedWithClientIds?.length
        );
        console.log('Documents partagés:', sharedDocs);
        
        setDocuments(docs);
      } catch (error) {
        console.error('Erreur lors du chargement des documents:', error);
      }
    }
  };

  useEffect(() => {
    fetchClients();
    fetchDocuments();
  }, [user?.id]);

  const handleOpenDrawer = (client?: Client) => {
    setSelectedClient(client || null);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setSelectedClient(null);
    setIsDrawerOpen(false);
  };

  const handleSaveClient = async (clientData: Client) => {
    try {
      if (!user?.id) throw new Error('User not authenticated');
      setError('');

      const clientWithUserId = {
        ...clientData,
        userId: user.id,
        updatedAt: new Date().toISOString(),
      };

      if (selectedClient?.id) {
        // Update existing client - remove id from the update data
        const { id, ...updateData } = clientWithUserId;
        await updateClient(selectedClient.id, updateData);
      } else {
        // Create new client
        await createClient(user.id, clientWithUserId);
      }
      
      await fetchClients();
      handleCloseDrawer();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Échec de l\'enregistrement du client');
      console.error(err);
    }
  };

  const handleDeleteClient = async (clientId: string) => {
    try {
      await deleteClient(clientId);
      fetchClients();
    } catch (err) {
      setError('Échec de la suppression du client');
      console.error(err);
    }
  };

  const handleShareDocuments = async (clientIds: string[], documentIds: string[]) => {
    if (!user?.id) {
      setError('Utilisateur non authentifié');
      return;
    }

    try {
      setLoading(true);
      setError('');

      // Share documents with each client
      for (const clientId of clientIds) {
        await shareDocuments(documentIds, clientId, user.id);
      }

      // Refresh documents to show updated sharing status
      await fetchDocuments();
      
      console.log('Documents partagés avec succès');
    } catch (err) {
      console.error('Erreur lors du partage des documents:', err);
      setError('Échec du partage des documents');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenShareDrawer = () => {
    setShareDrawerOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareDrawerOpen(false);
    setSelectedClients([]);
  };

  const handleEditClient = (client: Client) => {
    setSelectedClient(client);
    setIsDrawerOpen(true);
  };

  const handleEditDocument = (document: Document) => {
    console.log('Édition du document:', document);
    // TODO: Implémenter l'édition du document
  };

  const handleViewDocument = (document: Document) => {
    console.log('Consultation du document:', document);
    // TODO: Implémenter la consultation du document
  };

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Société',
      flex: 1,
    },
    {
      field: 'certification',
      headerName: 'Certification',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const clientDocuments = documents.filter(doc => 
          doc.userId === params.row.id
        );
        return (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}>
            <ComplianceBadge 
              status={calculateCertificationStatus(clientDocuments)} 
            />
          </Box>
        );
      },
    },
    {
      field: 'documents',
      headerName: 'Documents',
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const clientId = params.row.id;
        const clientDocs = documents.filter(doc => 
          doc.sharedWithClientIds?.includes(clientId) || 
          doc.shares?.some(share => share.clientId === clientId)
        );
        
        return (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}>
            {clientDocs.length > 0 ? (
              <SharedDocumentsBadge 
                documents={clientDocs}
                onEdit={handleEditDocument}
                onView={handleViewDocument}
              />
            ) : null}
          </Box>
        );
      }
    },
    ...(isMobile ? [] : [{ 
      field: 'contactName', 
      headerName: 'Contact', 
      flex: 1,
      renderCell: (params: { row: { contactFirstName?: string, contactLastName?: string } }) => {
        const fullName = `${params.row.contactFirstName || ''} ${params.row.contactLastName || ''}`.trim();
        return (
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '100%'
            }}>
              {fullName || 'Non renseigné'}
            </Typography>
          </Box>
        );
      }
    }]),
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Tooltip title="Partager">
            <IconButton 
              onClick={() => {
                setSelectedClients([params.row]);
                setShareDrawerOpen(true);
              }}
              size="small"
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier">
            <IconButton 
              onClick={() => handleEditClient(params.row)}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer">
            <IconButton 
              onClick={() => handleDeleteClient(params.row.id)}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', width: '100%', p: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">Clients</Typography>
        <Stack direction="row" spacing={2}>
          {selectedClients.length > 0 && (
            <Button
              variant="contained"
              startIcon={<ShareIcon />}
              onClick={() => setShareDrawerOpen(true)}
            >
              Partager avec {selectedClients.length} client{selectedClients.length > 1 ? 's' : ''}
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDrawer()}
          >
            Nouveau client
          </Button>
        </Stack>
      </Stack>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Paper sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
        <DataGrid
          rows={clients}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelectionModel) => {
            const selectedRows = clients.filter(client => 
              newSelectionModel.includes(client.id)
            );
            setSelectedClients(selectedRows);
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          localeText={{
            toolbarExport: 'Exporter',
            toolbarExportCSV: 'Télécharger en CSV',
            toolbarExportPrint: 'Imprimer',
            toolbarFilters: 'Filtres',
            toolbarFiltersLabel: 'Afficher les filtres',
            toolbarFiltersTooltipHide: 'Cacher les filtres',
            toolbarFiltersTooltipShow: 'Afficher les filtres',
            toolbarDensity: 'Densité',
            toolbarDensityLabel: 'Densité',
            toolbarDensityCompact: 'Compact',
            toolbarDensityStandard: 'Standard',
            toolbarDensityComfortable: 'Confortable',
            toolbarColumns: 'Colonnes',
            toolbarColumnsLabel: 'Sélectionner les colonnes',
            columnsPanelTextFieldLabel: 'Rechercher une colonne',
            columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
            columnsPanelShowAllButton: 'Tout afficher',
            columnsPanelHideAllButton: 'Tout masquer',
            filterPanelAddFilter: 'Ajouter un filtre',
            filterPanelRemoveAll: 'Tout supprimer',
            filterPanelDeleteIconLabel: 'Supprimer',
            filterPanelOperators: 'Opérateurs',
            filterPanelOperatorAnd: 'Et',
            filterPanelOperatorOr: 'Ou',
            filterPanelColumns: 'Colonnes',
            filterOperatorContains: 'contient',
            filterOperatorEquals: 'égal à',
            filterOperatorStartsWith: 'commence par',
            filterOperatorEndsWith: 'se termine par',
            filterOperatorIs: 'est',
            filterOperatorNot: 'n\'est pas',
            filterOperatorAfter: 'postérieur',
            filterOperatorOnOrAfter: 'postérieur ou égal',
            filterOperatorBefore: 'antérieur',
            filterOperatorOnOrBefore: 'antérieur ou égal',
            filterOperatorIsEmpty: 'est vide',
            filterOperatorIsNotEmpty: 'n\'est pas vide',
            filterOperatorIsAnyOf: 'fait partie de',
            MuiTablePagination: {
              labelRowsPerPage: 'Lignes par page:',
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`,
            },
          } as Partial<GridLocaleText>}
          autoHeight
          sx={{
            '& .MuiDataGrid-toolbarContainer': {
              padding: 2,
            },
          }}
        />
      </Paper>

      <ClientFormDrawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        client={selectedClient}
        onSave={handleSaveClient}
      />

      <ShareDocumentsDrawer
        open={shareDrawerOpen}
        onClose={handleCloseShareDrawer}
        clients={selectedClients}
        documents={documents}
        onShare={handleShareDocuments}
      />
    </Box>
  );
};

export default Clients;