import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Tooltip,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  Add as AddIcon,
  Visibility as VisibilityIcon,
  GetApp as GetAppIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { Document } from '../types';
import DocumentUploadDrawer from '../components/DocumentUploadDrawer';
import { getDocuments, deleteDocumentWithFile } from '../services/documents';
import { useAuthStore } from '../stores/authStore';

const Documents = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const user = useAuthStore(state => state.user);

  const fetchDocuments = async () => {
    if (!user?.id) return;
    
    try {
      setLoading(true);
      const fetchedDocuments = await getDocuments(user.id);
      setDocuments(fetchedDocuments);
    } catch (err) {
      setError('Échec du chargement des documents');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [user?.id]);

  const handleOpenUploadDrawer = () => {
    setIsUploadDrawerOpen(true);
  };

  const handleCloseUploadDrawer = () => {
    setIsUploadDrawerOpen(false);
  };

  const handleDeleteDocument = async (documentId: string) => {
    try {
      await deleteDocumentWithFile(documentId);
      setDocuments(documents.filter(doc => doc.id !== documentId));
    } catch (err) {
      setError('Échec de la suppression du document');
      console.error(err);
    }
  };

  const handleViewDocument = (fileUrl: string) => {
    window.open(fileUrl, '_blank');
  };

  const handleDownloadDocument = async (fileUrl: string, title: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = title;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError('Échec du téléchargement du document');
      console.error(err);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'valid':
        return 'success';
      case 'expired':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">Documents</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenUploadDrawer}
        >
          Télécharger un document
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Titre</TableCell>
                    <TableCell>Catégorie</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Date d'expiration</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Box sx={{ py: 4 }}>
                          <Typography variant="body1" color="text.secondary" gutterBottom>
                            Aucun document trouvé
                          </Typography>
                          <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={handleOpenUploadDrawer}
                          >
                            Télécharger votre premier document
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    documents.map((doc) => (
                      <TableRow key={doc.id}>
                        <TableCell>{doc.title}</TableCell>
                        <TableCell>
                          <Chip
                            label={doc.category}
                            size="small"
                            sx={{ textTransform: 'capitalize' }}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={doc.status}
                            color={getStatusColor(doc.status)}
                            size="small"
                            sx={{ textTransform: 'capitalize' }}
                          />
                        </TableCell>
                        <TableCell>
                          {doc.expiryDate
                            ? new Date(doc.expiryDate).toLocaleDateString()
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Afficher">
                            <IconButton 
                              size="small"
                              onClick={() => handleViewDocument(doc.fileUrl)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Télécharger">
                            <IconButton 
                              size="small"
                              onClick={() => handleDownloadDocument(doc.fileUrl, doc.title)}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Supprimer">
                            <IconButton 
                              size="small" 
                              color="error"
                              onClick={() => handleDeleteDocument(doc.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <DocumentUploadDrawer
        open={isUploadDrawerOpen}
        onClose={handleCloseUploadDrawer}
        onUploadSuccess={fetchDocuments}
      />
    </Box>
  );
};

export default Documents;