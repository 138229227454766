import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { searchCompanyBySiret } from '../../services/inseeApi';
import { useAuthStore } from '../../stores/authStore';

interface Company {
  id: string;
  siret: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  isMain: boolean;
}

interface CompanyListProps {
  companies: Company[];
  onAddCompany: (company: Omit<Company, 'id'>) => Promise<void>;
  onUpdateCompany: (id: string, company: Partial<Company>) => Promise<void>;
  onDeleteCompany: (id: string) => Promise<void>;
  maxCompanies: number;
}

export const CompanyList: React.FC<CompanyListProps> = ({
  companies,
  onAddCompany,
  onUpdateCompany,
  onDeleteCompany,
  maxCompanies,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [siret, setSiret] = useState('');
  const [editingCompany, setEditingCompany] = useState<Company | null>(null);
  const { user } = useAuthStore();

  const handleClose = () => {
    setOpen(false);
    setSiret('');
    setError(null);
    setEditingCompany(null);
  };

  const handleSearchSiret = async () => {
    if (!siret || siret.length !== 14) {
      setError('Le SIRET doit contenir 14 chiffres');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const companyData = await searchCompanyBySiret(siret);
      
      if (editingCompany) {
        await onUpdateCompany(editingCompany.id, {
          ...companyData,
          isMain: editingCompany.isMain,
        });
      } else {
        await onAddCompany({
          ...companyData,
          name: companyData.companyName,
          isMain: companies.length === 0,
        });
      }
      
      handleClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (company: Company) => {
    setEditingCompany(company);
    setSiret(company.siret);
    setOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await onDeleteCompany(id);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erreur lors de la suppression');
    }
  };

  const handleSetMain = async (id: string) => {
    try {
      // Update all companies to not be main
      await Promise.all(
        companies.map((company) =>
          onUpdateCompany(company.id, { isMain: company.id === id })
        )
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erreur lors de la mise à jour');
    }
  };

  return (
    <>
      <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant="h6">Mes Sociétés</Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
              disabled={companies.length >= maxCompanies}
              variant="contained"
              size="small"
            >
              Ajouter une société
            </Button>
          </Box>

          <Stack spacing={2}>
            {companies.map((company) => (
              <Card
                key={company.id}
                variant="outlined"
                sx={{
                  position: 'relative',
                  borderColor: company.isMain ? 'primary.main' : 'divider',
                  '&:hover': {
                    borderColor: company.isMain ? 'primary.main' : 'text.secondary',
                  },
                }}
              >
                {company.isMain && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: -1,
                      right: 16,
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      px: 1,
                      py: 0.5,
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      fontSize: '0.75rem',
                    }}
                  >
                    Principale
                  </Box>
                )}
                
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography variant="subtitle1" gutterBottom>
                        {company.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        SIRET: {company.siret}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {company.address}, {company.postalCode} {company.city}
                      </Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                      {!company.isMain && (
                        <Tooltip title="Définir comme société principale">
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => handleSetMain(company.id)}
                          >
                            Définir principale
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip title="Modifier">
                        <IconButton
                          size="small"
                          onClick={() => handleEdit(company)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {!company.isMain && (
                        <Tooltip title="Supprimer">
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(company.id)}
                            color="error"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            ))}

            {companies.length === 0 && (
              <Box
                sx={{
                  p: 3,
                  textAlign: 'center',
                  border: '2px dashed',
                  borderColor: 'divider',
                  borderRadius: 1,
                }}
              >
                <Typography color="text.secondary">
                  Aucune société ajoutée
                </Typography>
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingCompany ? 'Modifier la société' : 'Ajouter une société'}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="SIRET"
              value={siret}
              onChange={(e) => setSiret(e.target.value.replace(/\D/g, '').slice(0, 14))}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleSearchSiret}
                    disabled={loading || siret.length !== 14}
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SearchIcon />
                    )}
                  </IconButton>
                ),
              }}
              helperText="Entrez le numéro SIRET pour rechercher automatiquement les informations"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={handleSearchSiret}
            variant="contained"
            disabled={loading || siret.length !== 14}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : editingCompany ? (
              'Mettre à jour'
            ) : (
              'Ajouter'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
