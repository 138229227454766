import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Box,
  Typography,
  AutocompleteChangeReason,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { searchAddresses, getPlaceDetails, PlaceAutocompleteResult } from '../services/placesApi';

interface AddressAutocompleteProps {
  value: string;
  onAddressSelect: (details: {
    address: string;
    postalCode: string;
    city: string;
    country: string;
  }) => void;
  error?: boolean;
  helperText?: string;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  value,
  onAddressSelect,
  error,
  helperText,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceAutocompleteResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);

  const fetchAddressSuggestions = useCallback(
    debounce(async (input: string) => {
      if (!input || input.length < 3) {
        setOptions([]);
        return;
      }

      try {
        setLoading(true);
        setSearchError(null);
        const suggestions = await searchAddresses(input);
        setOptions(suggestions);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
        setSearchError('Erreur lors de la recherche d\'adresses');
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (_event: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
    fetchAddressSuggestions(newInputValue);
  };

  const handleOptionSelect = async (
    _event: React.SyntheticEvent, 
    value: string | PlaceAutocompleteResult | null, 
    reason: 'createOption' | 'selectOption' | 'removeOption' | 'blur' | 'clear'
  ) => {
    if (!value || typeof value === 'string') return;

    try {
      setLoading(true);
      const details = await getPlaceDetails(value.placeId);
      onAddressSelect(details);
    } catch (error) {
      console.error('Error fetching place details:', error);
      setSearchError('Erreur lors de la récupération des détails de l\'adresse');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => 
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Adresse"
          error={error || !!searchError}
          helperText={searchError || helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Box>
            <Typography variant="body1">{option.mainText}</Typography>
            <Typography variant="body2" color="text.secondary">
              {option.secondaryText}
            </Typography>
          </Box>
        </Box>
      )}
    />
  );
};

export default AddressAutocomplete;
