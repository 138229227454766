import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { 
  collection, 
  query, 
  orderBy, 
  addDoc, 
  updateDoc, 
  doc, 
  onSnapshot,
  Timestamp,
  where,
  getDocs
} from 'firebase/firestore';
import { db, auth } from '../config/firebase';

export interface Attachment {
  id: string;
  name: string;
  type: string;
  url: string;
  size: number;
}

export interface Message {
  id: string;
  conversationId: string;
  senderId: string;
  content: string;
  attachments: Attachment[];
  mentions: string[];
  timestamp: Date;
  read: boolean;
}

export interface Conversation {
  id: string;
  title: string;
  participants: string[];
  type: 'document' | 'client';
  status: 'normal' | 'urgent' | 'resolved';
  documentId?: string;
  clientId?: string;
  lastMessage?: Message;
  unreadCount: number;
  labels: string[];
  createdAt: Date;
  updatedAt: Date;
}

interface MessageState {
  conversations: Conversation[];
  messages: { [conversationId: string]: Message[] };
  selectedConversationId: string | null;
  isLoading: boolean;
  error: string | null;
  
  // Actions
  setSelectedConversation: (conversationId: string | null) => void;
  addMessage: (message: Omit<Message, 'id' | 'timestamp'>) => Promise<void>;
  markAsRead: (conversationId: string) => Promise<void>;
  addConversation: (conversation: Omit<Conversation, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateConversationStatus: (conversationId: string, status: Conversation['status']) => Promise<void>;
  addAttachment: (messageId: string, attachment: Omit<Attachment, 'id'>) => Promise<void>;
  addLabel: (conversationId: string, label: string) => Promise<void>;
  removeLabel: (conversationId: string, label: string) => Promise<void>;
  initializeListeners: () => () => void;
}

export const useMessageStore = create<MessageState>()(
  persist(
    (set, get) => ({
      conversations: [],
      messages: {},
      selectedConversationId: null,
      isLoading: false,
      error: null,

      initializeListeners: () => {
        // Check if user is authenticated
        if (!auth.currentUser) {
          set({ error: 'User not authenticated' });
          return () => {};
        }

        // Listen for conversations where the current user is a participant
        const conversationsQuery = query(
          collection(db, 'conversations'),
          where('participants', 'array-contains', auth.currentUser.uid),
          orderBy('updatedAt', 'desc')
        );

        const unsubscribeConversations = onSnapshot(
          conversationsQuery,
          (snapshot) => {
            const conversations = snapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
              createdAt: doc.data().createdAt?.toDate(),
              updatedAt: doc.data().updatedAt?.toDate(),
            })) as Conversation[];
            set({ conversations, error: null });
          },
          (error) => {
            set({ error: error.message });
          }
        );

        // Listen for messages in conversations where the user is a participant
        const messagesQuery = get().conversations.length > 0 ? query(
          collection(db, 'messages'),
          where('conversationId', 'in', get().conversations.map(c => c.id)),
          orderBy('timestamp', 'asc')
        ) : query(
          collection(db, 'messages'),
          where('conversationId', '==', 'no-conversations-yet'), // This will return no results
          orderBy('timestamp', 'asc')
        );

        const unsubscribeMessages = onSnapshot(
          messagesQuery,
          (snapshot) => {
            const messages = snapshot.docs.reduce((acc, doc) => {
              const message = {
                ...doc.data(),
                id: doc.id,
                timestamp: doc.data().timestamp?.toDate(),
              } as Message;
              
              const conversationId = message.conversationId;
              if (!acc[conversationId]) {
                acc[conversationId] = [];
              }
              acc[conversationId].push(message);
              return acc;
            }, {} as { [key: string]: Message[] });
            
            set({ messages, error: null });
          },
          (error) => {
            set({ error: error.message });
          }
        );

        return () => {
          unsubscribeConversations();
          unsubscribeMessages();
        };
      },

      setSelectedConversation: (conversationId) => {
        set({ selectedConversationId: conversationId });
        if (conversationId) {
          get().markAsRead(conversationId);
        }
      },

      addMessage: async (message) => {
        try {
          if (!auth.currentUser) {
            throw new Error('User not authenticated');
          }

          set({ isLoading: true, error: null });
          const messagesRef = collection(db, 'messages');
          await addDoc(messagesRef, {
            ...message,
            senderId: auth.currentUser.uid,
            timestamp: Timestamp.now(),
          });

          // Update conversation's lastMessage and updatedAt
          const conversationRef = doc(db, 'conversations', message.conversationId);
          await updateDoc(conversationRef, {
            lastMessage: message.content,
            updatedAt: Timestamp.now(),
            unreadCount: message.senderId !== auth.currentUser.uid ? 1 : 0,
          });

          set({ isLoading: false });
        } catch (error) {
          set({ error: (error as Error).message, isLoading: false });
        }
      },

      markAsRead: async (conversationId) => {
        try {
          const conversationRef = doc(db, 'conversations', conversationId);
          await updateDoc(conversationRef, {
            unreadCount: 0,
          });

          const messagesRef = collection(db, 'messages');
          const unreadMessages = query(
            messagesRef,
            where('conversationId', '==', conversationId),
            where('read', '==', false)
          );

          const snapshot = await getDocs(unreadMessages);
          const updatePromises = snapshot.docs.map(doc =>
            updateDoc(doc.ref, { read: true })
          );
          await Promise.all(updatePromises);
        } catch (error) {
          set({ error: (error as Error).message });
        }
      },

      addConversation: async (conversation) => {
        try {
          set({ isLoading: true, error: null });
          const conversationsRef = collection(db, 'conversations');
          await addDoc(conversationsRef, {
            ...conversation,
            unreadCount: 0,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
          });
          set({ isLoading: false });
        } catch (error) {
          set({ error: (error as Error).message, isLoading: false });
        }
      },

      updateConversationStatus: async (conversationId, status) => {
        try {
          const conversationRef = doc(db, 'conversations', conversationId);
          await updateDoc(conversationRef, { status });
        } catch (error) {
          set({ error: (error as Error).message });
        }
      },

      addAttachment: async (messageId, attachment) => {
        try {
          const messageRef = doc(db, 'messages', messageId);
          await updateDoc(messageRef, {
            attachments: attachment,
          });
        } catch (error) {
          set({ error: (error as Error).message });
        }
      },

      addLabel: async (conversationId, label) => {
        try {
          const conversationRef = doc(db, 'conversations', conversationId);
          await updateDoc(conversationRef, {
            labels: label,
          });
        } catch (error) {
          set({ error: (error as Error).message });
        }
      },

      removeLabel: async (conversationId, label) => {
        try {
          const conversationRef = doc(db, 'conversations', conversationId);
          const conversation = get().conversations.find(c => c.id === conversationId);
          if (conversation) {
            const updatedLabels = conversation.labels.filter(l => l !== label);
            await updateDoc(conversationRef, {
              labels: updatedLabels,
            });
          }
        } catch (error) {
          set({ error: (error as Error).message });
        }
      },
    }),
    {
      name: 'complydoc-messages',
    }
  )
);
