import { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Link as MuiLink,
  CircularProgress,
  Divider,
  Chip,
  Grid,
} from '@mui/material';
import { useAuthStore } from '../../stores/authStore';
import { RegisterData, SocialAuthProvider } from '../../types/auth';
import SocialLogin from '../../components/SocialLogin';
import { STRIPE_CONFIG } from '../../config/stripe';
import AuthLayout from '../../layouts/AuthLayout';

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, socialLogin } = useAuthStore();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSocialLoading, setIsSocialLoading] = useState<SocialAuthProvider | null>(null);
  const { t } = useTranslation();

  const selectedPlan = location.state?.selectedPlan || 'basic';
  const isAnnual = location.state?.isAnnual || false;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required(t('auth.register.errors.firstNameRequired'))
      .min(2, t('auth.register.errors.firstNameMin'))
      .max(50, t('auth.register.errors.firstNameMax')),
    lastName: Yup.string()
      .required(t('auth.register.errors.lastNameRequired'))
      .min(2, t('auth.register.errors.lastNameMin'))
      .max(50, t('auth.register.errors.lastNameMax')),
    email: Yup.string()
      .email(t('auth.register.errors.invalidEmail'))
      .required(t('auth.register.errors.emailRequired')),
    password: Yup.string()
      .min(8, t('auth.register.errors.passwordMin'))
      .matches(/[a-z]/, t('auth.register.errors.passwordLowercase'))
      .matches(/[A-Z]/, t('auth.register.errors.passwordUppercase'))
      .matches(/[0-9]/, t('auth.register.errors.passwordNumber'))
      .required(t('auth.register.errors.passwordRequired')),
    role: Yup.string()
      .oneOf(['freelancer', 'client'], t('auth.register.errors.invalidRole'))
      .required(t('auth.register.errors.roleRequired')),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: 'freelancer' as const,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        setError('');
        const registerData: RegisterData = {
          ...values,
          subscription: {
            plan: selectedPlan,
            isAnnual,
          },
        };
        await register(registerData);
        navigate('/dashboard');
      } catch (err) {
        setError(err instanceof Error ? err.message : t('auth.register.errors.registrationFailed'));
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const getPlanDetails = () => {
    const planKey = selectedPlan as keyof typeof STRIPE_CONFIG.plans;
    const plan = STRIPE_CONFIG.plans[planKey];
    const hasAnnualDiscount = 'annual' in plan;
    
    const price = isAnnual && hasAnnualDiscount
      ? Math.round(plan.price * 12 * (1 - (plan as typeof STRIPE_CONFIG.plans['pro']).annual.discount / 100) / 12)
      : plan.price;

    const planName = selectedPlan === 'basic' 
      ? t(`landing.plans.${STRIPE_CONFIG.plans.basic.name.toLowerCase()}.name`, 'Plan de base')
      : t(`landing.plans.${STRIPE_CONFIG.plans.pro.name.toLowerCase()}.name`, 'Plan pro');
    
    const billingPeriod = isAnnual 
      ? t('landing.pricing.perYear', '/an')
      : t('landing.pricing.perMonth', '/mois');

    return {
      name: planName,
      price,
      period: billingPeriod,
      color: selectedPlan === 'pro' ? 'primary' as const : 'default' as const
    };
  };

  const planDetails = getPlanDetails();

  return (
    <AuthLayout>
      <Container maxWidth="sm">
        <Paper 
          elevation={3}
          sx={{ 
            p: 4,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'background.paper' : 'background.default',
          }}
        >
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            {t('auth.register.title')}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {selectedPlan && (
            <Box 
              sx={{ 
                mb: 3,
                p: 2,
                borderRadius: 1,
                bgcolor: (theme) => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.02)',
                border: '1px solid',
                borderColor: (theme) => theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {t('auth.register.selectedPlan')}
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ fontWeight: 500 }}>
                  {planDetails.name}
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography 
                  variant="h6" 
                  component="div" 
                  color={planDetails.color}
                  sx={{ fontWeight: 600 }}
                >
                  {`€${planDetails.price}`}
                  <Typography 
                    component="span" 
                    variant="caption" 
                    color="text.secondary"
                    sx={{ ml: 0.5 }}
                  >
                    /mo
                  </Typography>
                </Typography>
                {isAnnual && planDetails.period && (
                  <Chip
                    size="small"
                    label={planDetails.period}
                    color={planDetails.color}
                    variant="outlined"
                    sx={{ mt: 0.5 }}
                  />
                )}
              </Box>
            </Box>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label={t('auth.register.firstName')}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label={t('auth.register.lastName')}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </Grid>

            <TextField
              fullWidth
              id="email"
              name="email"
              label={t('auth.register.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />

            <TextField
              fullWidth
              id="password"
              name="password"
              label={t('auth.register.password')}
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="role-label">{t('auth.register.role')}</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
                label={t('auth.register.role')}
              >
                <MenuItem value="freelancer">{t('auth.register.roles.freelancer')}</MenuItem>
                <MenuItem value="client">{t('auth.register.roles.client')}</MenuItem>
              </Select>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                t('auth.register.submit')
              )}
            </Button>
          </form>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="body2">
              {t('auth.register.haveAccount')}{' '}
              <MuiLink component={Link} to="/login">
                {t('auth.register.signIn')}
              </MuiLink>
            </Typography>

            <Divider sx={{ my: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {t('auth.register.or')}
              </Typography>
            </Divider>

            <SocialLogin
              onSocialLogin={socialLogin}
              isLoading={isSocialLoading}
              setIsLoading={setIsSocialLoading}
            />
          </Box>
        </Paper>
      </Container>
    </AuthLayout>
  );
};

export default Register;