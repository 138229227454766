import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Stack,
  Alert,
  CircularProgress,
  InputAdornment,
  FormControlLabel,
  Switch,
  Grid,
} from '@mui/material';
import {
  Close as CloseIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Client } from '../types';
import { searchCompanyBySiret } from '../services/inseeApi';
import AddressAutocomplete from './AddressAutocomplete';

interface ClientFormDrawerProps {
  open: boolean;
  onClose: () => void;
  onSave: (client: Client) => Promise<void>;
  client?: Client | null;
}

const validationSchema = Yup.object().shape({
  siret: Yup.string()
    .matches(/^\d{14}$/, 'Le SIRET doit contenir exactement 14 chiffres')
    .required('Le SIRET est requis'),
  companyName: Yup.string().required('Le nom de l\'entreprise est requis'),
  address: Yup.string().required('L\'adresse est requise'),
  postalCode: Yup.string()
    .matches(/^\d{5}$/, 'Le code postal doit contenir 5 chiffres')
    .required('Le code postal est requis'),
  city: Yup.string().required('La ville est requise'),
  country: Yup.string().required('Le pays est requis'),
  contactFirstName: Yup.string().required('Le prénom du contact est requis'),
  contactLastName: Yup.string().required('Le nom du contact est requis'),
  contactEmail: Yup.string()
    .email('Adresse email invalide')
    .required('L\'email du contact est requis'),
  contactPhone: Yup.string()
    .matches(/^(\+33|0)[1-9](\d{8})$/, 'Numéro de téléphone invalide')
    .required('Le téléphone du contact est requis'),
});

const ClientFormDrawer: React.FC<ClientFormDrawerProps> = ({
  open,
  onClose,
  onSave,
  client,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchingSiret, setSearchingSiret] = useState(false);

  const formik = useFormik({
    initialValues: {
      siret: '',
      companyName: '',
      address: '',
      postalCode: '',
      city: '',
      country: 'France',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
      isActive: true,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        setError(null);
        await onSave(values as Client);
        onClose();
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Une erreur est survenue');
      } finally {
        setLoading(false);
      }
    },
  });

  // Mettre à jour les valeurs du formulaire lorsque le client change
  useEffect(() => {
    if (client) {
      formik.setValues({
        siret: client.siret || '',
        companyName: client.companyName || '',
        address: client.address || '',
        postalCode: client.postalCode || '',
        city: client.city || '',
        country: client.country || 'France',
        contactFirstName: client.contactFirstName || '',
        contactLastName: client.contactLastName || '',
        contactEmail: client.contactEmail || '',
        contactPhone: client.contactPhone || '',
        isActive: client.isActive ?? true,
      });
    } else {
      formik.resetForm();
    }
  }, [client]);

  const handleAddressSelect = useCallback(
    (details: { address: string; postalCode: string; city: string; country: string }) => {
      formik.setValues({
        ...formik.values,
        address: details.address,
        postalCode: details.postalCode,
        city: details.city,
        country: details.country,
      });
    },
    [formik]
  );

  const handleSiretSearch = useCallback(async () => {
    if (!formik.values.siret || formik.values.siret.length !== 14) {
      setError('Veuillez saisir un numéro SIRET valide à 14 chiffres');
      return;
    }

    setSearchingSiret(true);
    setError(null);

    try {
      const companyData = await searchCompanyBySiret(formik.values.siret);
      formik.setValues({
        ...formik.values,
        ...companyData,
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Échec de la récupération des données de l\'entreprise');
    } finally {
      setSearchingSiret(false);
    }
  }, [formik.values.siret, formik.setValues]);

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setError(null);
    }
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      keepMounted={true}
      PaperProps={{
        sx: { 
          width: { xs: '100%', sm: 600 },
          paddingTop: '64px', // Hauteur de l'AppBar
        },
        role: 'dialog',
        'aria-modal': true,
        'aria-labelledby': 'client-form-title'
      }}
      SlideProps={{
        tabIndex: -1
      }}
    >
      <Box 
        sx={{ p: 3 }}
        role="form"
        aria-label={client ? 'Modifier le client' : 'Nouveau client'}
      >
        <Box sx={{ mb: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            id="client-form-title"
            tabIndex={0}
          >
            {client ? 'Modifier le client' : 'Nouveau client'}
          </Typography>
          {client && (
            <Box 
              sx={{ 
                mt: 1,
                p: 2,
                bgcolor: 'primary.main',
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography variant="subtitle1" color="primary.contrastText" fontWeight="bold">
                  {client.companyName}
                </Typography>
                <Typography variant="caption" color="primary.contrastText" sx={{ opacity: 0.8 }}>
                  SIRET : {client.siret}
                </Typography>
              </Box>
              <IconButton 
                size="small" 
                onClick={onClose}
                sx={{ 
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            {error && <Alert severity="error">{error}</Alert>}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SIRET"
                  name="siret"
                  value={formik.values.siret}
                  onChange={formik.handleChange}
                  error={formik.touched.siret && Boolean(formik.errors.siret)}
                  helperText={formik.touched.siret ? formik.errors.siret : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSiretSearch}
                          disabled={!formik.values.siret || searchingSiret}
                        >
                          {searchingSiret ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SearchIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nom de l'entreprise"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName ? formik.errors.companyName : ''}
                />
              </Grid>

              <Grid item xs={12}>
                <AddressAutocomplete
                  value={formik.values.address}
                  onAddressSelect={handleAddressSelect}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address ? formik.errors.address : ''}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Code postal"
                  name="postalCode"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                  helperText={formik.touched.postalCode ? formik.errors.postalCode : ''}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Ville"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city ? formik.errors.city : ''}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Pays"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country ? formik.errors.country : ''}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Prénom du contact"
                  name="contactFirstName"
                  value={formik.values.contactFirstName}
                  onChange={formik.handleChange}
                  error={formik.touched.contactFirstName && Boolean(formik.errors.contactFirstName)}
                  helperText={formik.touched.contactFirstName ? formik.errors.contactFirstName : ''}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Nom du contact"
                  name="contactLastName"
                  value={formik.values.contactLastName}
                  onChange={formik.handleChange}
                  error={formik.touched.contactLastName && Boolean(formik.errors.contactLastName)}
                  helperText={formik.touched.contactLastName ? formik.errors.contactLastName : ''}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email du contact"
                  name="contactEmail"
                  type="email"
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
                  helperText={formik.touched.contactEmail ? formik.errors.contactEmail : ''}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Téléphone du contact"
                  name="contactPhone"
                  value={formik.values.contactPhone}
                  onChange={formik.handleChange}
                  error={formik.touched.contactPhone && Boolean(formik.errors.contactPhone)}
                  helperText={formik.touched.contactPhone ? formik.errors.contactPhone : ''}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.isActive}
                      onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                      name="isActive"
                    />
                  }
                  label="Client actif"
                />
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
              <Button onClick={onClose}>Annuler</Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {client ? 'Modifier' : 'Créer'}
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

export default ClientFormDrawer;