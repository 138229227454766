import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Alert,
  Container,
  Link as MuiLink,
  CircularProgress,
  Divider,
} from '@mui/material';
import { useAuthStore } from '../../stores/authStore';
import { SocialAuthProvider } from '../../types/auth';
import SocialLogin from '../../components/SocialLogin';
import AuthLayout from '../../layouts/AuthLayout';

const Login = () => {
  const navigate = useNavigate();
  const { login, socialLogin } = useAuthStore();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSocialLoading, setIsSocialLoading] = useState<SocialAuthProvider | null>(null);
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('auth.login.errors.invalidEmail'))
      .required(t('auth.login.errors.emailRequired')),
    password: Yup.string()
      .required(t('auth.login.errors.passwordRequired')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        setError('');
        await login(values);
        navigate('/dashboard');
      } catch (err) {
        setError(err instanceof Error ? err.message : t('auth.login.errors.loginFailed'));
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <AuthLayout>
      <Container maxWidth="sm">
        <Paper 
          elevation={3}
          sx={{ 
            p: 4,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'background.paper' : 'background.default',
          }}
        >
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            {t('auth.login.title')}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label={t('auth.login.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              label={t('auth.login.password')}
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              margin="normal"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                t('auth.login.submit')
              )}
            </Button>
          </form>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <MuiLink component={Link} to="/forgot-password" variant="body2">
              {t('auth.login.forgotPassword')}
            </MuiLink>
          </Box>

          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t('auth.login.noAccount')}{' '}
              <MuiLink component={Link} to="/register">
                {t('auth.login.signUp')}
              </MuiLink>
            </Typography>

            <Divider>
              <Typography variant="body2" color="text.secondary">
                {t('auth.login.or')}
              </Typography>
            </Divider>

            <SocialLogin
              onSocialLogin={socialLogin}
              isLoading={isSocialLoading}
              setIsLoading={setIsSocialLoading}
            />
          </Box>
        </Paper>
      </Container>
    </AuthLayout>
  );
};

export default Login;