type SupportedLanguages = 'EN' | 'FR';

interface TemplateVariables {
  WARNING_DOCUMENTS: string;
  CRITICAL_DOCUMENTS: string;
  LANGUAGE: string;
}

interface DocumentExpirationTemplate {
  EN: number;
  FR: number;
  VARIABLES: TemplateVariables;
}

interface DocumentShareTemplate {
  ID: number;
  VARIABLES: {
    DOCUMENT_NAME: string;
    DOCUMENT_URL: string;
    EXPIRATION_DATE: string;
    ALLOW_DOWNLOAD: string;
    ALLOW_RESHARE: string;
    SENDER_NAME: string;
    COMPANY_NAME: string;
  }
}

interface BrevoConfig {
  API_KEY: string;
  SMTP: {
    HOST: string;
    PORT: number;
    USER: string;
    PASS: string;
  };
  NEWSLETTER_LIST_ID: number;
  TEMPLATES: {
    NEWSLETTER: {
      EN: number;
      FR: number;
    };
    DOCUMENT_EXPIRATION: DocumentExpirationTemplate;
    DOCUMENT_SHARE: DocumentShareTemplate;
  };
  DEFAULT_SENDER: {
    NAME: string;
    EMAIL: string;
  };
}

export const BREVO_CONFIG: BrevoConfig = {
  API_KEY: import.meta.env.VITE_BREVO_API_KEY,
  SMTP: {
    HOST: 'smtp-relay.brevo.com',
    PORT: 587,
    USER: import.meta.env.VITE_BREVO_SMTP_USER,
    PASS: import.meta.env.VITE_BREVO_SMTP_PASS,
  },
  NEWSLETTER_LIST_ID: 2,
  TEMPLATES: {
    NEWSLETTER: {
      EN: 1,
      FR: 2,
    },
    DOCUMENT_EXPIRATION: {
      EN: 3,
      FR: 4,
      VARIABLES: {
        WARNING_DOCUMENTS: 'warningDocuments',
        CRITICAL_DOCUMENTS: 'criticalDocuments',
        LANGUAGE: 'language'
      }
    },
    DOCUMENT_SHARE: {
      ID: 2,
      VARIABLES: {
        DOCUMENT_NAME: 'documentName',
        DOCUMENT_URL: 'documentUrl',
        EXPIRATION_DATE: 'expirationDate',
        ALLOW_DOWNLOAD: 'allowDownload',
        ALLOW_RESHARE: 'allowReshare',
        SENDER_NAME: 'senderName',
        COMPANY_NAME: 'companyName',
      }
    }
  },
  DEFAULT_SENDER: {
    NAME: 'ComplyDoc',
    EMAIL: 'notifications@complydoc.fr'
  }
};

export const getBrevoTemplateContent = () => `
<!DOCTYPE html>
<html>
<head>
    <style>
        body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
        .container { max-width: 600px; margin: 0 auto; padding: 20px; }
        .header { background: #f8f9fa; padding: 20px; border-radius: 5px; margin-bottom: 20px; }
        .document-info { background: #fff; border: 1px solid #dee2e6; padding: 20px; border-radius: 5px; }
        .button { display: inline-block; padding: 10px 20px; background: #007bff; color: #fff; text-decoration: none; border-radius: 5px; }
        .footer { margin-top: 30px; padding-top: 20px; border-top: 1px solid #dee2e6; font-size: 0.9em; color: #6c757d; }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <h2>{senderName} a partagé un document avec vous</h2>
        </div>
        
        <div class="document-info">
            <h3>Détails du document</h3>
            <p><strong>Nom du document :</strong> {documentName}</p>
            <p><strong>Date d'expiration :</strong> {expirationDate}</p>
            <p><strong>Téléchargement autorisé :</strong> {allowDownload}</p>
            <p><strong>Partage autorisé :</strong> {allowReshare}</p>
            
            <p style="margin-top: 20px;">
                <a href="{documentUrl}" class="button">Voir le document</a>
            </p>
        </div>
        
        <div class="footer">
            <p>Ce lien expirera le {expirationDate}. Pour des raisons de sécurité, ne partagez pas ce lien avec d'autres personnes.</p>
            <p>Email envoyé par {companyName}</p>
        </div>
    </div>
</body>
</html>
`;