import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme';
import AppRoutes from './routes';
import Layout from './components/Layout';
import { useEffect } from 'react';
import { useAuthStore } from './stores/authStore';
import Chatbot from './components/Chatbot';
import { ColorModeProvider } from './contexts/ColorModeContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

// Désactiver les avertissements de React Router
const originalConsoleWarn = console.warn;
console.warn = (...args) => {
  if (
    typeof args[0] === 'string' &&
    (args[0].includes('React Router') || args[0].includes('UNSAFE_'))
  ) {
    return;
  }
  originalConsoleWarn.apply(console, args);
};

function App() {
  const initAuth = useAuthStore(state => state.initAuth);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  return (
    <ColorModeProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter future={{ v7_startTransition: true }}>
            <Layout>
              <AppRoutes />
            </Layout>
            <Chatbot />
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </ColorModeProvider>
  );
}

export default App;