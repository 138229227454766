import { loadStripe, Stripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from 'firebase/firestore';
import { STRIPE_CONFIG } from '../config/stripe';
import { functions } from '../config/firebase';

let stripePromise: Promise<Stripe | null>;

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_CONFIG.publicKey);
  }
  return stripePromise;
};

interface Subscription {
  id: string;
  status: 'active' | 'canceled' | 'past_due' | 'incomplete';
  planId: string;
  planName: string;
  currentPeriodEnd: string;
  cancelAtPeriodEnd: boolean;
  createdAt: string;
  updatedAt: string;
}

interface Invoice {
  id: string;
  number: string;
  date: string;
  amount: number;
  status: 'paid' | 'open' | 'void';
  downloadUrl: string;
}

// Fetch current subscription
export const getCurrentSubscription = async (userId: string): Promise<Subscription | null> => {
  try {
    const db = getFirestore();
    const subscriptionsRef = collection(db, 'subscriptions');
    const q = query(
      subscriptionsRef,
      where('userId', '==', userId),
      where('status', 'in', ['active', 'past_due', 'incomplete']),
      orderBy('createdAt', 'desc'),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null;
    }

    const subscriptionDoc = querySnapshot.docs[0];
    return {
      id: subscriptionDoc.id,
      ...subscriptionDoc.data(),
    } as Subscription;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    throw error;
  }
};

// Fetch invoice history
export const getInvoiceHistory = async (userId: string): Promise<Invoice[]> => {
  try {
    const getInvoices = httpsCallable(functions, 'stripe-getInvoices');
    const { data } = await getInvoices();
    return data as Invoice[];
  } catch (error) {
    console.error('Error fetching invoices:', error);
    throw error;
  }
};

// Create new subscription
export const createSubscription = async (priceId: string, userId: string): Promise<void> => {
  try {
    const createSubscriptionCall = httpsCallable(functions, 'stripe-createSubscription');
    const { data } = await createSubscriptionCall({ priceId });
    return data as any;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

// Cancel subscription
export const cancelSubscription = async (subscriptionId: string): Promise<void> => {
  try {
    const cancelSubscriptionCall = httpsCallable(functions, 'stripe-cancelSubscription');
    await cancelSubscriptionCall({ subscriptionId });
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
};

// Update subscription
export const updateSubscription = async (subscriptionId: string, priceId: string): Promise<void> => {
  try {
    const updateSubscriptionCall = httpsCallable(functions, 'updateSubscription');
    await updateSubscriptionCall({ subscriptionId, priceId });
  } catch (error) {
    console.error('Error updating subscription:', error);
    throw error;
  }
};

// Get Stripe portal URL
export const getStripePortalUrl = async (): Promise<{ url: string }> => {
  try {
    const createPortalSession = httpsCallable(functions, 'stripe-createPortalSession');
    const result = await createPortalSession();
    return result.data as { url: string };
  } catch (error) {
    console.error('Error getting portal URL:', error);
    throw error;
  }
};

export const getSubscriptionStatus = async (userId: string) => {
  try {
    const getStatus = httpsCallable(functions, 'getSubscriptionStatus');
    const result = await getStatus({ userId });
    return result.data;
  } catch (error) {
    console.error('Error getting subscription status:', error);
    throw error;
  }
};