import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();
const getSiretInfoFunction = httpsCallable(functions, 'getSiretInfo');

export interface SiretResponse {
  siret: string;
  companyName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
}

export const searchCompanyBySiret = async (siret: string): Promise<SiretResponse> => {
  try {
    // Verify authentication
    const auth = getAuth();
    if (!auth.currentUser) {
      throw new Error('Vous devez être connecté pour effectuer cette opération');
    }

    // Call the Cloud Function
    console.log('Calling getSiretInfo with:', siret);
    const result = await getSiretInfoFunction({ siret });
    console.log('Got response:', result);

    if (!result.data || !(result.data as any).success || !(result.data as any).data) {
      throw new Error('Réponse invalide du serveur');
    }

    const data = (result.data as any).data;
    return {
      siret: data.siret,
      companyName: data.company.name,
      address: data.address.street,
      postalCode: data.address.postalCode,
      city: data.address.city,
      country: 'France'
    };
  } catch (error: any) {
    console.error('Erreur lors de la recherche SIRET:', error);
    
    // Handle specific Firebase function errors
    if (error.code === 'functions/not-found') {
      throw new Error('SIRET non trouvé. Veuillez vérifier le numéro et réessayer.');
    } else if (error.code === 'functions/permission-denied') {
      throw new Error('Erreur d\'authentification avec l\'API INSEE.');
    } else if (error.code === 'functions/unauthenticated') {
      throw new Error('Vous devez être connecté pour effectuer cette opération.');
    } else if (error.code === 'functions/invalid-argument') {
      throw new Error('Le format du SIRET est invalide.');
    } else if (error.code === 'functions/deadline-exceeded') {
      throw new Error('La requête a pris trop de temps. Veuillez réessayer.');
    } else if (error.code === 'functions/resource-exhausted') {
      throw new Error('Trop de requêtes. Veuillez réessayer plus tard.');
    }
    
    // If it's an error with a message, use it
    if (error.message) {
      throw new Error(error.message);
    }
    
    // Generic error
    throw new Error('Une erreur est survenue lors de la recherche. Veuillez réessayer plus tard.');
  }
};