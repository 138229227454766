import React, { useState, useCallback } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Stack,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon, CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { DocumentCategory } from '../types';
import { createDocument } from '../services/documents';
import { useAuthStore } from '../stores/authStore';
import { useDropzone } from 'react-dropzone';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fr } from 'date-fns/locale';
import { useCompanies } from '../hooks/useCompanies';

interface DocumentUploadDrawerProps {
  open: boolean;
  onClose: () => void;
  onUploadSuccess?: () => void;
}

const documentCategories: { value: DocumentCategory; label: string }[] = [
  { value: 'vigilance', label: 'Attestation de vigilance' },
  { value: 'fiscal', label: 'Attestation de régularité fiscale' },
  { value: 'kbis', label: 'Extrait K-bis / D1 / SIRENE' },
  { value: 'foreign_workers', label: 'Liste nominative des salariés étrangers' },
  { value: 'agefiph', label: 'Attestation AGEFIPH' },
  { value: 'identity', label: 'Documents d\'identité' },
  { value: 'insurance', label: 'Assurance RC PRO' },
  { value: 'tax', label: 'Documents fiscaux' },
  { value: 'inpi', label: 'Extrait INPI' },
  { value: 'bank', label: 'Compte bancaire' },
  { value: 'social', label: 'Attestation Urssaf' },
  { value: 'other', label: 'Autre' },
];

const DOCUMENT_VALIDITY_RULES: { [key in DocumentCategory]: { validUntilEndOfYear: boolean, minValidityOnUpload: number, minRemainingValidity: number } } = {
  vigilance: { validUntilEndOfYear: true, minValidityOnUpload: 0, minRemainingValidity: 0 },
  fiscal: { validUntilEndOfYear: true, minValidityOnUpload: 0, minRemainingValidity: 0 },
  kbis: { validUntilEndOfYear: true, minValidityOnUpload: 0, minRemainingValidity: 0 },
  foreign_workers: { validUntilEndOfYear: false, minValidityOnUpload: 30, minRemainingValidity: 30 },
  agefiph: { validUntilEndOfYear: false, minValidityOnUpload: 30, minRemainingValidity: 30 },
  identity: { validUntilEndOfYear: false, minValidityOnUpload: 0, minRemainingValidity: 0 },
  insurance: { validUntilEndOfYear: false, minValidityOnUpload: 0, minRemainingValidity: 0 },
  tax: { validUntilEndOfYear: false, minValidityOnUpload: 0, minRemainingValidity: 0 },
  inpi: { validUntilEndOfYear: false, minValidityOnUpload: 0, minRemainingValidity: 0 },
  bank: { validUntilEndOfYear: false, minValidityOnUpload: 0, minRemainingValidity: 0 },
  social: { validUntilEndOfYear: false, minValidityOnUpload: 0, minRemainingValidity: 0 },
  other: { validUntilEndOfYear: false, minValidityOnUpload: 0, minRemainingValidity: 0 },
};

const DocumentUploadDrawer: React.FC<DocumentUploadDrawerProps> = ({ 
  open, 
  onClose,
  onUploadSuccess 
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState<DocumentCategory>('other');
  const [expiryDate, setExpiryDate] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useAuthStore(state => state.user);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const { companies } = useCompanies();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles[0]) {
      const file = acceptedFiles[0];
      if (file.size > 10 * 1024 * 1024) { // 10MB limit
        setError('La taille du fichier doit être inférieure à 10 Mo');
        return;
      }
      setSelectedFile(file);
      setError('');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.png', '.jpg', '.jpeg']
    },
    maxSize: 10 * 1024 * 1024 // 10MB
  });

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.size > 10 * 1024 * 1024) { // 10MB limit
        setError('La taille du fichier doit être inférieure à 10 Mo');
        return;
      }
      setSelectedFile(file);
      setError('');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedFile) {
      setError('Veuillez sélectionner un fichier à télécharger');
      return;
    }
    if (!title) {
      setError('Veuillez saisir un titre pour le document');
      return;
    }
    if (!user?.id) {
      setError('Vous devez être connecté pour télécharger des documents');
      return;
    }

    // Validation de la date d'expiration selon les règles de la catégorie
    const rules = DOCUMENT_VALIDITY_RULES[category];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (rules.validUntilEndOfYear) {
      const endOfYear = new Date(today.getFullYear(), 11, 31);
      if (!expiryDate) {
        setExpiryDate(endOfYear.toISOString().split('T')[0]);
      } else {
        const selectedDate = new Date(expiryDate);
        if (selectedDate.getFullYear() !== today.getFullYear()) {
          setError('La date de validité doit être dans l\'année civile en cours');
          return;
        }
      }
    } else if (rules.minValidityOnUpload > 0 || rules.minRemainingValidity > 0) {
      if (!expiryDate) {
        setError('La date de validité est requise pour ce type de document');
        return;
      }

      const selectedDate = new Date(expiryDate);
      const uploadValidityDate = new Date(today);
      uploadValidityDate.setDate(today.getDate() - rules.minValidityOnUpload);

      if (selectedDate < uploadValidityDate) {
        setError(`Le document doit avoir été émis il y a moins de ${rules.minValidityOnUpload} jours`);
        return;
      }

      const minExpiryDate = new Date(today);
      minExpiryDate.setDate(today.getDate() + rules.minRemainingValidity);

      if (selectedDate < minExpiryDate) {
        setError(`Le document doit rester valide au moins ${rules.minRemainingValidity} jours`);
        return;
      }
    }

    setLoading(true);
    setError('');

    try {
      await createDocument(user.id, selectedFile, {
        title,
        category,
        status: 'pending',
        expiryDate: expiryDate || undefined,
        companyId: companies.length > 1 ? selectedCompanyId : companies[0]?.id,
      });

      // Reset form
      setSelectedFile(null);
      setTitle('');
      setCategory('other');
      setExpiryDate('');
      setSelectedCompanyId('');
      
      onUploadSuccess?.();
      onClose();
    } catch (err) {
      setError('Échec du téléchargement du document');
      console.error('Upload error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { 
          width: { xs: '100%', sm: 400 },
          borderTopLeftRadius: { xs: 0, sm: '12px' },
          borderBottomLeftRadius: { xs: 0, sm: '12px' },
        },
      }}
      SlideProps={{
        direction: 'left',
      }}
    >
      <Box sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', pt: { xs: 8, sm: 3 } }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6">Télécharger un document</Typography>
          <IconButton onClick={onClose} size="small" disabled={loading}>
            <CloseIcon />
          </IconButton>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit} style={{ flex: 1 }}>
          <Stack spacing={3}>
            <Box>
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed',
                  borderColor: isDragActive ? 'primary.main' : 'divider',
                  borderRadius: 1,
                  p: 2,
                  cursor: 'pointer',
                  bgcolor: isDragActive ? 'action.hover' : 'background.paper',
                  transition: 'all 0.2s ease',
                  height: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <input {...getInputProps()} />
                <Box sx={{ textAlign: 'center' }}>
                  <CloudUploadIcon sx={{ mb: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    {selectedFile 
                      ? selectedFile.name 
                      : isDragActive 
                        ? 'Déposez le fichier ici'
                        : 'Glissez et déposez un fichier ou cliquez pour sélectionner'}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="caption" color="text.secondary" display="block" mt={1}>
                Formats supportés : PDF, JPG, PNG (max 10 Mo)
              </Typography>
            </Box>

            <TextField
              select
              label="Société concernée"
              value={selectedCompanyId}
              onChange={(e) => setSelectedCompanyId(e.target.value)}
              fullWidth
              disabled={loading || companies.length <= 1}
              sx={{ mb: 2 }}
            >
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Titre du document"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              required
              disabled={loading}
            />

            <TextField
              select
              label="Catégorie"
              value={category}
              onChange={(e) => setCategory(e.target.value as DocumentCategory)}
              fullWidth
              required
              disabled={loading}
            >
              {documentCategories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
              <DatePicker
                label="Date de validité"
                value={expiryDate ? new Date(expiryDate) : null}
                onChange={(newValue) => {
                  setExpiryDate(newValue ? newValue.toISOString().split('T')[0] : '');
                }}
                minDate={new Date()} // Empêche la sélection de dates antérieures à aujourd'hui
                slotProps={{
                  textField: {
                    fullWidth: true,
                    disabled: loading,
                    helperText: "La date doit être supérieure ou égale à aujourd'hui"
                  }
                }}
              />
            </LocalizationProvider>
          </Stack>

          <Box sx={{ mt: 'auto', pt: 3 }}>
            <Stack spacing={2}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Télécharger'
                )}
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={onClose}
                disabled={loading}
                sx={{ display: { xs: 'block', sm: 'none' } }}
              >
                Retour
              </Button>
            </Stack>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default DocumentUploadDrawer;