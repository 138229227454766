import { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  Tab,
  Tabs,
  Alert,
  CircularProgress,
  Grid,
  Card,
  Stack,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonalInfoForm from './PersonalInfoForm';
import { CompanyList } from '../../components/profile/CompanyList';
import SecuritySettings from './SecuritySettings';
import NotificationSettings from './NotificationSettings';
import { useAuthStore } from '../../stores/authStore';
import { useProfileData } from '../../hooks/useProfileData';
import { useCompanies } from '../../hooks/useCompanies';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const Profile = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const { user, changePassword } = useAuthStore();
  const { data: profileData, isLoading: profileLoading, error: profileError } = useProfileData(user?.id);
  const {
    companies,
    loading: companiesLoading,
    error: companiesError,
    addCompany,
    updateCompany,
    deleteCompany,
    maxCompanies
  } = useCompanies();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const isLoading = profileLoading || companiesLoading;
  const error = profileError || companiesError;

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              {t('profile.title', 'Mon Profil')}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t('profile.description', 'Gérez vos informations personnelles, vos entreprises et vos préférences')}
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {t('profile.error', 'Une erreur est survenue lors du chargement de votre profil')}
            </Alert>
          )}

          <Card
            elevation={0}
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              bgcolor: 'background.default',
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label={t('profile.tabs.ariaLabel', 'Onglets du profil')}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  px: 2,
                  pt: 2,
                  '& .MuiTab-root': {
                    minHeight: 48,
                    fontWeight: 500,
                  },
                }}
              >
                <Tab 
                  label={t('profile.tabs.personal', 'Informations Personnelles')} 
                  {...a11yProps(0)}
                  sx={{ textTransform: 'none' }}
                />
                <Tab 
                  label={t('profile.tabs.companies', 'Entreprises')} 
                  {...a11yProps(1)}
                  sx={{ textTransform: 'none' }}
                />
                <Tab 
                  label={t('profile.tabs.security', 'Sécurité')} 
                  {...a11yProps(2)}
                  sx={{ textTransform: 'none' }}
                />
                <Tab 
                  label={t('profile.tabs.notifications', 'Notifications')} 
                  {...a11yProps(3)}
                  sx={{ textTransform: 'none' }}
                />
              </Tabs>
            </Box>

            <Box sx={{ p: 3 }}>
              <TabPanel value={tabValue} index={0}>
                <Stack spacing={3}>
                  <PersonalInfoForm initialData={profileData} />
                </Stack>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Stack spacing={3}>
                  <CompanyList
                    companies={companies}
                    onAddCompany={addCompany}
                    onUpdateCompany={updateCompany}
                    onDeleteCompany={deleteCompany}
                    maxCompanies={maxCompanies}
                  />
                </Stack>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Stack spacing={3}>
                  <SecuritySettings onPasswordChange={async (data) => {
                    await changePassword(data);
                  }} />
                </Stack>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Stack spacing={3}>
                  <NotificationSettings />
                </Stack>
              </TabPanel>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;