import { CertificationBadge, CertificationStatus } from '../types/certification';

export const COMPLIANCE_REQUIREMENTS = [
  {
    id: 'kbis',
    category: 'business',
    documentType: 'KBIS',
    isRequired: true,
    validityPeriod: 90, // 3 months
  },
  {
    id: 'urssaf',
    category: 'compliance',
    documentType: 'URSSAF Certificate',
    isRequired: true,
    validityPeriod: 180, // 6 months
  },
  {
    id: 'insurance',
    category: 'insurance',
    documentType: 'Professional Liability Insurance',
    isRequired: true,
    validityPeriod: 365, // 1 year
  },
  {
    id: 'tax',
    category: 'tax',
    documentType: 'Tax Compliance Certificate',
    isRequired: true,
    validityPeriod: 365, // 1 year
  },
  {
    id: 'identity',
    category: 'identity',
    documentType: 'ID Document',
    isRequired: true,
    validityPeriod: 3650, // 10 years
  },
  {
    id: 'bank',
    category: 'business',
    documentType: 'Bank Account Details',
    isRequired: false,
  },
];

export const CERTIFICATION_BADGES: Record<CertificationStatus['badge'], CertificationBadge> = {
  gold: {
    type: 'gold',
    label: 'Gold Certified',
    minScore: 95,
    color: '#FFD700',
    icon: 'verified',
  },
  silver: {
    type: 'silver',
    label: 'Silver Certified',
    minScore: 85,
    color: '#C0C0C0',
    icon: 'verified',
  },
  bronze: {
    type: 'bronze',
    label: 'Bronze Certified',
    minScore: 75,
    color: '#CD7F32',
    icon: 'verified_user',
  },
  none: {
    type: 'none',
    label: 'Not Certified',
    minScore: 0,
    color: '#9E9E9E',
    icon: 'gpp_maybe',
  },
} as const;