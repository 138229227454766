import { AppBar, Toolbar, Typography, IconButton, Avatar, Menu, MenuItem, Box } from '@mui/material';
import { Settings, Menu as MenuIcon } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../stores/authStore';
import { useLayoutStore } from '../../stores/layoutStore';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LanguageSwitcher from '../LanguageSwitcher';
import NotificationCenter from '../NotificationCenter';
import { Logo } from '../../assets';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, logout } = useAuthStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { toggleSidebar } = useLayoutStore();
  const { t } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    handleClose();
    navigate('/login');
  };

  const handleProfile = () => {
    handleClose();
    navigate('/profile');
  };

  const handleSupscription = () => {
    handleClose();
    navigate('/subscription');
  };

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'background.paper',
        color: 'text.primary',
        boxShadow: 1,
        width: '100%'
      }}
    >
      <Toolbar sx={{ 
        gap: 2,
        px: 2
      }}>
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebar}
            edge="start"
            sx={{ 
              mr: 1
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        
        <Box 
          component="div"
          sx={{ 
            width: { xs: '160px', sm: '200px', md: '380px' },
            height: '40px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              width: '100%',
              height: '100%',
              display: 'block'
            }
          }}
          onClick={() => navigate('/dashboard')}
        >
          <Logo />
        </Box>
        
        <Box sx={{ flexGrow: 1 }} />
        
        <NotificationCenter />
        
        <IconButton 
          size="large" 
          color="inherit"
          aria-label={t('common.settings')}
        >
          <Settings />
        </IconButton>
        
        <LanguageSwitcher />
        
        <IconButton 
          sx={{ ml: 1 }} 
          onClick={handleMenu}
          aria-label={t('common.userMenu')}
          aria-controls="user-menu"
          aria-haspopup="true"
        >
          <Avatar>{user?.firstName?.[0] || 'U'}</Avatar>
        </IconButton>
        
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleProfile}>
            {t('common.profile')}
          </MenuItem>
          <MenuItem onClick={handleSupscription}>
            {t('common.plan')}
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            {t('common.logout')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;