import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { ProfileData, NotificationSettings } from '../types/profile';

const PROFILES_COLLECTION = 'profiles';

interface InitialProfileData {
  firstName: string;
  lastName: string;
  email: string;
}

export const getUserProfile = async (userId: string): Promise<ProfileData> => {
  const docRef = doc(db, PROFILES_COLLECTION, userId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    throw new Error('Profile not found');
  }

  return {
    id: docSnap.id,
    ...docSnap.data(),
    createdAt: docSnap.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
    updatedAt: docSnap.data().updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
  } as ProfileData;
};

export const createDefaultProfile = async (
  userId: string,
  initialData: InitialProfileData
): Promise<ProfileData> => {
  const defaultProfile: Omit<ProfileData, 'id'> = {
    firstName: initialData.firstName,
    lastName: initialData.lastName,
    email: initialData.email,
    notificationSettings: {
      emailNotifications: true,
      documentExpiryAlerts: true,
      weeklyDigest: false,
      securityAlerts: true,
    },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  const docRef = doc(db, PROFILES_COLLECTION, userId);

  try {
    await setDoc(docRef, {
      ...defaultProfile,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    return {
      id: userId,
      ...defaultProfile,
    };
  } catch (error) {
    console.error('Error creating profile:', error);
    throw new Error('Failed to create user profile');
  }
};

export const updateUserProfile = async (userId: string, profileData: Partial<ProfileData>): Promise<void> => {
  const docRef = doc(db, PROFILES_COLLECTION, userId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    // Create new profile if it doesn't exist
    await setDoc(docRef, {
      ...profileData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  } else {
    // Update existing profile
    await updateDoc(docRef, {
      ...profileData,
      updatedAt: serverTimestamp(),
    });
  }
};

export const updateCompanyProfile = async (userId: string, companyData: Partial<ProfileData>): Promise<void> => {
  const docRef = doc(db, PROFILES_COLLECTION, userId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    // Create new profile with company data if it doesn't exist
    await setDoc(docRef, {
      ...companyData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  } else {
    // Update existing profile with company data
    await updateDoc(docRef, {
      ...companyData,
      updatedAt: serverTimestamp(),
    });
  }
};

export const updateNotificationSettings = async (userId: string, settings: NotificationSettings): Promise<void> => {
  const docRef = doc(db, PROFILES_COLLECTION, userId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    // Create new profile with notification settings if it doesn't exist
    await setDoc(docRef, {
      notificationSettings: settings,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  } else {
    // Update existing profile with notification settings
    await updateDoc(docRef, {
      notificationSettings: settings,
      updatedAt: serverTimestamp(),
    });
  }
};