import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Stack,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Security,
  CloudUpload,
  Notifications,
  AccessTime,
  Check as CheckIcon,
} from '@mui/icons-material';
import Header from './Header';
import Footer from '../../components/Footer';
import { STRIPE_CONFIG } from '../../config/stripe';
import { HeroIllustration } from '@/assets';

const features = [
  {
    icon: <CloudUpload />,
    translationKey: 'documentUpload',
  },
  {
    icon: <Notifications />,
    translationKey: 'reminders',
  },
  {
    icon: <Security />,
    translationKey: 'sharing',
  },
  {
    icon: <AccessTime />,
    translationKey: 'timeSaving',
  },
];

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const getFeatureDefaultTitle = (key: string): string => {
  const titles: Record<string, string> = {
    documentUpload: 'Gestion des documents',
    reminders: 'Rappels intelligents',
    sharing: 'Partage sécurisé',
    timeSaving: 'Gain de temps',
  };
  return titles[key] || key;
};

const getFeatureDefaultDescription = (key: string): string => {
  const descriptions: Record<string, string> = {
    documentUpload: 'Téléchargez et organisez vos documents en toute simplicité',
    reminders: 'Recevez des notifications pour vos échéances importantes',
    sharing: 'Partagez vos documents en toute sécurité avec vos clients',
    timeSaving: 'Automatisez vos tâches administratives',
  };
  return descriptions[key] || key;
};

const LandingPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAnnual, setIsAnnual] = useState(true);

  const [heroRef, heroInView] = useInView({ triggerOnce: true });
  const [featuresRef, featuresInView] = useInView({ triggerOnce: true });
  const [pricingRef, pricingInView] = useInView({ triggerOnce: true });

  const getProPrice = () => {
    const annualPrice = STRIPE_CONFIG.plans.pro.price;
    if (isAnnual) {
      const discount = STRIPE_CONFIG.plans.pro.annual.discount;
      return Math.round(annualPrice * (1 - discount / 100));
    }
    return Math.round((annualPrice / 12) * 100) / 100;
  };

  const handleStartTrial = (plan: 'basic' | 'pro') => {
    navigate('/register', { state: { selectedPlan: plan, isAnnual } });
  };

  const getTranslatedFeature = (planType: 'basic' | 'pro', feature: string) => {
    // Convert feature text to translation key by removing spaces and making it lowercase
    const key = feature.toLowerCase().replace(/\s+/g, '');
    const translationKey = `landing.plans.${planType}.features.${key}`;
    return t(translationKey, feature); // Fallback to original feature text if translation is missing
  };

  return (
    <Box>
      <Header />

      {/* Hero Section */}
      <Box
        ref={heroRef}
        component={motion.div}
        initial="hidden"
        animate={heroInView ? "visible" : "hidden"}
        variants={fadeInUp}
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          pt: { xs: 8, md: 12 },
          background: 'linear-gradient(180deg, rgba(10, 37, 64, 0.02) 0%, rgba(10, 37, 64, 0.05) 100%)',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 700,
                  lineHeight: 1.2,
                }}
              >
                {t('landing.hero.title', 'Simplifiez votre conformité freelance')}
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                sx={{ mb: 4 }}
              >
                {t('landing.hero.subtitle', 'Gérez vos documents et restez en conformité en toute simplicité')}
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => handleStartTrial('pro')}
                >
                  {t('landing.hero.startTrial', 'Commencer l\'essai gratuit')}
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => navigate('/login')}
                >
                  {t('landing.hero.signIn', 'Se connecter')}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: '100%',
                  maxWidth: 600,
                  height: 'auto',
                  '& svg': {
                    width: '100%',
                    height: 'auto'
                  }
                }}
              >
                <HeroIllustration />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box
        ref={featuresRef}
        component={motion.div}
        initial="hidden"
        animate={featuresInView ? "visible" : "hidden"}
        variants={fadeInUp}
        sx={{ py: 8 }}
        id="features"
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{ mb: 6 }}
          >
            {t('landing.features.title', 'Fonctionnalités principales')}
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature) => (
              <Grid item xs={12} md={6} key={feature.translationKey}>
                <Card
                  component={motion.div}
                  variants={fadeInUp}
                  sx={{
                    height: '100%',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                    },
                  }}
                >
                  <CardContent>
                    <Box sx={{ color: 'primary.main', mb: 2 }}>
                      {feature.icon}
                    </Box>
                    <Typography variant="h5" gutterBottom>
                      {t(`landing.features.${feature.translationKey}.title`, getFeatureDefaultTitle(feature.translationKey))}
                    </Typography>
                    <Typography color="text.secondary">
                      {t(`landing.features.${feature.translationKey}.description`, getFeatureDefaultDescription(feature.translationKey))}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Pricing Section */}
      <Box
        ref={pricingRef}
        component={motion.div}
        initial="hidden"
        animate={pricingInView ? "visible" : "hidden"}
        variants={fadeInUp}
        sx={{ py: 8, bgcolor: 'background.default' }}
        id="pricing"
      >
        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography variant="h2" gutterBottom>
              {t('landing.pricing.title', 'Tarifs simples et transparents')}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {t('landing.pricing.subtitle', 'Choisissez le plan qui correspond à vos besoins')}
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  checked={isAnnual}
                  onChange={(e) => setIsAnnual(e.target.checked)}
                />
              }
              label={t('subscription.annualBilling', 'Facturation annuelle')}
              sx={{ mt: 3 }}
            />

            {isAnnual && (
              <Typography
                variant="subtitle2"
                color="success.main"
                sx={{ mt: 1 }}
              >
                {t('subscription.annualDiscount', 'Économisez {{discount}}% avec la facturation annuelle', {
                  discount: STRIPE_CONFIG.plans.pro.annual.discount,
                })}
              </Typography>
            )}
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {/* Basic Plan */}
            <Grid item xs={12} md={6} lg={4}>
              <motion.div variants={fadeInUp} style={{ position: 'relative', paddingTop: '20px', height: '100%' }}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {t(`landing.plans.${STRIPE_CONFIG.plans.basic.name.toLowerCase()}.name`, 'Plan de base')}
                    </Typography>
                    <Typography variant="h3" component="div" gutterBottom>
                      €{STRIPE_CONFIG.plans.basic.price}
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="text.secondary"
                      >
                        {t(`landing.pricing.${isAnnual ? 'perYear' : 'perMonth'}`, isAnnual ? '/an' : '/mois')}
                      </Typography>
                    </Typography>
                    <List>
                      {STRIPE_CONFIG.plans.basic.features.map((feature, index) => (
                        <ListItem key={index} disableGutters>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={getTranslatedFeature('basic', feature)}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Button
                      fullWidth
                      variant="outlined"
                      size="large"
                      onClick={() => handleStartTrial('basic')}
                      sx={{ mt: 2 }}
                    >
                      {t('landing.pricing.startFree', 'Commencer gratuitement')}
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>

            {/* Pro Plan */}
            <Grid item xs={12} md={6} lg={4}>
              <motion.div variants={fadeInUp} style={{ position: 'relative', paddingTop: '20px', height: '100%' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 22,
                    right: 24,
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    px: 2,
                    py: 0.5,
                    borderRadius: 1,
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    zIndex: 2,
                    transform: 'translateY(-50%)',
                    boxShadow: 1,
                    whiteSpace: 'nowrap'
                  }}
                >
                  {t('subscription.recommended', 'Recommandé')}
                </Box>
                <Card
                  sx={{
                    position: 'relative',
                    border: '2px solid',
                    borderColor: 'primary.main',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {t(`landing.plans.${STRIPE_CONFIG.plans.pro.name.toLowerCase()}.name`, STRIPE_CONFIG.plans.pro.name)}
                    </Typography>
                    <Typography variant="h3" component="div" gutterBottom>
                      €{getProPrice()}
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="text.secondary"
                      >
                        {t(`landing.pricing.${isAnnual ? 'perYear' : 'perMonth'}`, isAnnual ? '/an' : '/mois')}
                      </Typography>
                    </Typography>
                    <List>
                      {STRIPE_CONFIG.plans.pro.features.map((feature, index) => (
                        <ListItem key={index} disableGutters>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={getTranslatedFeature('pro', feature)}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      onClick={() => handleStartTrial('pro')}
                      sx={{ mt: 2 }}
                    >
                      {t('landing.pricing.startTrial', 'Commencer l\'essai gratuit')}
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default LandingPage;