import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Link,
  Alert,
  Snackbar,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import { subscribeToNewsletter } from '../services/newsletter';
import { Logo } from '../assets';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !email.includes('@')) {
      setShowError(true);
      return;
    }

    setLoading(true);
    try {
      await subscribeToNewsletter(email, i18n.language);
      setShowSuccess(true);
      setEmail('');
    } catch (error) {
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'background.paper',
        py: { xs: 4, md: 6 },
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {t('footer.about.title', 'About Us')}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {t('footer.about.description', 'We help freelancers manage their administrative compliance with ease and security.')}
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
              <Box 
                component={Logo as React.ElementType} 
                sx={{ 
                  width: 180, 
                  height: 'auto',
                  display: 'block',
                  padding: 0,
                  margin: 0
                }} 
              />
            </Box>
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {t('footer.followUs', 'Follow Us')}
            </Typography>
            <IconButton
              href="https://www.linkedin.com/company/your-company"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              <LinkedIn />
            </IconButton>
          </Grid>

          {/* Newsletter Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {t('footer.newsletter.title', 'Newsletter')}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {t('footer.newsletter.description', 'Subscribe to our newsletter for updates and compliance tips.')}
            </Typography>
            <form onSubmit={handleSubscribe}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: isMobile ? 'column' : 'row' }}>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder={t('footer.newsletter.placeholder', 'Enter your email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ flexGrow: 1 }}
                  disabled={loading}
                  type="email"
                  required
                />
                <Button 
                  type="submit" 
                  variant="contained"
                  disabled={loading}
                  sx={{ minWidth: isMobile ? '100%' : 'auto' }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    t('footer.newsletter.subscribe', 'Subscribe')
                  )}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 4 }}
        >
          {new Date().getFullYear()} {t('footer.copyright', 'All rights reserved.')}
        </Typography>

        <Snackbar
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => setShowSuccess(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity="success">
            {t('footer.newsletter.success', 'Successfully subscribed to newsletter!')}
          </Alert>
        </Snackbar>

        <Snackbar
          open={showError}
          autoHideDuration={6000}
          onClose={() => setShowError(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity="error">
            {t('footer.newsletter.error', 'Please enter a valid email address')}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Footer;