import axios from 'axios';
import { BREVO_CONFIG } from '../config/brevo';

interface SendEmailResponse {
  messageId: string;
}

interface DocumentExpirationData {
  warningDocuments: Array<{
    title: string;
    category: string;
    expiryDate: string;
    daysUntilExpiry: number;
  }>;
  criticalDocuments: Array<{
    title: string;
    category: string;
    expiryDate: string;
    daysUntilExpiry: number;
  }>;
}

class EmailService {
  private apiKey: string;
  private baseUrl = 'https://api.brevo.com/v3';

  constructor() {
    const apiKey = import.meta.env.VITE_BREVO_API_KEY;
    if (!apiKey) {
      throw new Error('BREVO_API_KEY is not defined in environment variables');
    }
    this.apiKey = apiKey;
  }

  async sendDocumentShareEmail(
    recipientEmail: string, 
    documentName: string,
    sharedUrl: string,
    expiresAt: Date,
    config: {
      allowDownload: boolean;
      allowReshare: boolean;
    },
    senderName: string = BREVO_CONFIG.DEFAULT_SENDER.NAME
  ): Promise<boolean> {
    try {
      const vars = BREVO_CONFIG.TEMPLATES.DOCUMENT_SHARE.VARIABLES;
      
      const payload = {
        to: [{ email: recipientEmail }],
        templateId: BREVO_CONFIG.TEMPLATES.DOCUMENT_SHARE.ID,
        params: {
          documentName: documentName,
          documentUrl: sharedUrl,
          expirationDate: expiresAt.toLocaleDateString('fr-FR'),
          allowDownload: config.allowDownload ? 'Oui' : 'Non',
          allowReshare: config.allowReshare ? 'Oui' : 'Non',
          senderName: senderName,
          companyName: BREVO_CONFIG.DEFAULT_SENDER.NAME,
        },
        sender: BREVO_CONFIG.DEFAULT_SENDER
      };

      await this.sendEmail(payload);
      return true;
    } catch (error: any) {
      console.error('Error sending document share email:', error.response?.data || error.message);
      throw new Error(error.response?.data?.message || 'Erreur lors de l\'envoi de l\'email');
    }
  }

  async sendDocumentExpirationEmail(
    recipientEmail: string,
    data: DocumentExpirationData,
    language: 'en' | 'fr' = 'fr'
  ): Promise<boolean> {
    try {
      const templateVars = BREVO_CONFIG.TEMPLATES.DOCUMENT_EXPIRATION.VARIABLES;
      const upperLanguage = language.toUpperCase() as 'EN' | 'FR';
      
      const payload = {
        to: [{ email: recipientEmail }],
        templateId: BREVO_CONFIG.TEMPLATES.DOCUMENT_EXPIRATION[upperLanguage],
        params: {
          [templateVars.WARNING_DOCUMENTS]: data.warningDocuments,
          [templateVars.CRITICAL_DOCUMENTS]: data.criticalDocuments,
          [templateVars.LANGUAGE]: language,
        },
        sender: BREVO_CONFIG.DEFAULT_SENDER
      };

      await this.sendEmail(payload);
      return true;
    } catch (error: any) {
      console.error('Error sending document expiration email:', error.response?.data || error.message);
      throw new Error(error.response?.data?.message || 'Erreur lors de l\'envoi de l\'email');
    }
  }

  private async sendEmail(payload: any): Promise<SendEmailResponse> {
    const response = await axios.post<SendEmailResponse>(
      `${this.baseUrl}/smtp/email`,
      payload,
      {
        headers: {
          'api-key': this.apiKey,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );

    console.log('Email sent successfully:', response.data);
    return response.data;
  }
}

export const emailService = new EmailService();
