import { addDays, isAfter, isBefore } from 'date-fns';
import { Document } from '../types';
import { CertificationStatus, ComplianceRequirement } from '../types/certification';
import { COMPLIANCE_REQUIREMENTS, CERTIFICATION_BADGES } from '../config/certification';

export const calculateCertificationStatus = (
  documents: Document[],
  requirements: ComplianceRequirement[] = COMPLIANCE_REQUIREMENTS
): CertificationStatus => {
  const now = new Date();
  const requirementStatus: CertificationStatus['requirements'] = {};
  let totalScore = 0;
  let requiredCount = 0;

  requirements.forEach(req => {
    if (req.isRequired) requiredCount++;
    const matchingDoc = documents.find(
      doc => doc.category === req.category && doc.title.toLowerCase().includes(req.documentType.toLowerCase())
    );

    if (!matchingDoc) {
      requirementStatus[req.id] = {
        met: false,
        status: 'missing',
      };
      return;
    }

    const expiryDate = matchingDoc.expiryDate ? new Date(matchingDoc.expiryDate) : undefined;
    const isExpired = expiryDate && isAfter(now, expiryDate);
    const isExpiringSoon = expiryDate && 
      isBefore(expiryDate, addDays(now, 30)) && 
      isAfter(expiryDate, now);

    requirementStatus[req.id] = {
      met: !isExpired,
      documentId: matchingDoc.id,
      expiryDate: matchingDoc.expiryDate,
      status: isExpired ? 'expired' : isExpiringSoon ? 'expiring_soon' : 'valid',
    };

    if (!isExpired) {
      totalScore += req.isRequired ? 80 : 20;
      if (!isExpiringSoon) totalScore += req.isRequired ? 20 : 5;
    }
  });

  // Calculate final score (0-100)
  const finalScore = Math.min(100, totalScore / requiredCount);

  // Determine badge level
  let badge: CertificationStatus['badge'] = 'none';
  if (finalScore >= CERTIFICATION_BADGES.gold.minScore) badge = 'gold';
  else if (finalScore >= CERTIFICATION_BADGES.silver.minScore) badge = 'silver';
  else if (finalScore >= CERTIFICATION_BADGES.bronze.minScore) badge = 'bronze';

  // Valid until date is the earliest expiry date of any required document
  const validUntil = Object.entries(requirementStatus)
    .filter(([id]) => requirements.find(r => r.id === id)?.isRequired)
    .map(([, status]) => status.expiryDate)
    .filter(Boolean)
    .sort()[0] || addDays(now, 30).toISOString();

  return {
    isCompliant: finalScore >= CERTIFICATION_BADGES.bronze.minScore,
    score: Math.round(finalScore),
    lastChecked: now.toISOString(),
    validUntil,
    requirements: requirementStatus,
    badge,
  };
};