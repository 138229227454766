import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
  Button
} from '@mui/material';
import {
  Dashboard,
  Description,
  People,
  Message,
  Assessment,
  Star
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLayoutStore } from '../../stores/layoutStore';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isSidebarOpen, toggleSidebar } = useLayoutStore();
  const { t } = useTranslation();

  const menuItems = [
    { text: 'dashboard', icon: <Dashboard />, path: '/dashboard', label: 'Dashboard' },
    { text: 'documents', icon: <Description />, path: '/documents', label: 'Documents' },
    { text: 'clients', icon: <People />, path: '/clients', label: 'Clients' },
    { text: 'messages', icon: <Message />, path: '/messages', label: 'Messages' },
    { text: 'reports', icon: <Assessment />, path: '/reports', label: 'Reports' },
  ];

  const drawer = (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{ mt: 8 }}>
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                selected={location.pathname === item.path}
                onClick={() => {
                  navigate(item.path);
                  if (isMobile) {
                    toggleSidebar();
                  }
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={t(`navigation.${item.text}`, { defaultValue: item.label })} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      
      <Box sx={{ 
        mt: 'auto', 
        p: 2,
      }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            color: 'white',
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -15,
              right: -15,
              width: 60,
              height: 60,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          />
          <Star sx={{ 
            fontSize: 20, 
            mb: 1,
            color: 'rgba(255, 255, 255, 0.9)'
          }} />
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 'medium',
              mb: 1,
              fontSize: '0.875rem',
              lineHeight: 1.3
            }}
          >
            {t('subscription.promoMessage', { defaultValue: 'Passez à la version Pro pour accéder à toutes les fonctionnalités' })}
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate('/subscription')}
            sx={{
              mt: 1,
              backgroundColor: 'white',
              color: '#2196F3',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              },
              textTransform: 'none',
              fontWeight: 'medium'
            }}
          >
            {t('subscription.upgrade', { defaultValue: 'Passer à la version Pro' })}
          </Button>
        </Paper>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <Drawer
        variant="temporary"
        anchor="left"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    );
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: 1,
          borderColor: 'divider',
          height: '100%',
          top: 0,
        },
      }}
      open
    >
      {drawer}
    </Drawer>
  );
};

export default Sidebar;