import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
import.meta.env.VITE_STRIPE_SECRET_KEY;

export const STRIPE_CONFIG = {
  apiUrl: 'https://api.stripe.com',
  publicKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  secretKey: import.meta.env.VITE_STRIPE_SECRET_KEY,
  plans: {
    basic: {
      id: 'prod_RH39YykntRgi2R',
      name: 'Basic',
      price: 0,
      features: [
        'Single company',
        'Basic document management',
        'Manual document upload',
        'Basic notifications',
      ],
    },
    pro: {
      id: 'prod_RH39lVN7dwBukw',
      name: 'Pro',
      price: 35,  // This is the annual price
      features: [
        'Multiple companies',
        'Automatic document retrieval',
        'Advanced notifications',
        'Detailed dashboard',
        'Priority support',
      ],
      annual: {
        id: 'price_pro_annual',
        discount: 10,
      },
    },
  },
};