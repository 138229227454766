import React from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Invoice {
  id: string;
  number: string;
  date: string;
  amount: number;
  status: string;
  downloadUrl: string;
}

interface InvoiceHistoryProps {
  invoices: Invoice[];
}

export const InvoiceHistory: React.FC<InvoiceHistoryProps> = ({ invoices }) => {
  const { t } = useTranslation();

  if (!invoices.length) {
    return (
      <Card elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
        <Typography variant="body1" color="text.secondary" align="center">
          {t('subscription.billing.noInvoices')}
        </Typography>
      </Card>
    );
  }

  return (
    <Card elevation={0} sx={{ border: '1px solid', borderColor: 'divider' }}>
      <Box sx={{ p: 3, pb: 2 }}>
        <Typography variant="h6" gutterBottom>
          {t('subscription.billing.history')}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('subscription.billing.invoice')}</TableCell>
              <TableCell>{t('subscription.billing.date')}</TableCell>
              <TableCell align="right">{t('subscription.billing.amount')}</TableCell>
              <TableCell align="center">{t('subscription.billing.status')}</TableCell>
              <TableCell align="right">{t('subscription.billing.downloadInvoice')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.id} hover>
                <TableCell>{invoice.number}</TableCell>
                <TableCell>
                  {new Date(invoice.date).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </TableCell>
                <TableCell align="right">
                  {new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(invoice.amount / 100)}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        invoice.status === 'paid'
                          ? 'success.main'
                          : invoice.status === 'void'
                          ? 'error.main'
                          : 'warning.main',
                      textTransform: 'capitalize',
                    }}
                  >
                    {invoice.status}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Link href={invoice.downloadUrl} target="_blank" rel="noopener">
                    {t('subscription.billing.downloadInvoice')}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
