import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Card,
  CardContent,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  Description as DocumentIcon,
  People as ClientIcon,
  NotificationsActive as AlertIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ComplianceBadge from '../components/ComplianceBadge';
import { calculateCertificationStatus } from '../services/certification';
import { Document, Client } from '../types';
import { getDocuments } from '../services/documents';
import { getClients } from '../services/firestore';
import { useAuthStore } from '../stores/authStore';

const Dashboard = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const user = useAuthStore(state => state.user);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.id) return;

      try {
        setLoading(true);
        const [fetchedDocs, fetchedClients] = await Promise.all([
          getDocuments(user.id),
          getClients(user.id)
        ]);
        setDocuments(fetchedDocs);
        setClients(fetchedClients);
      } catch (err) {
        setError('Failed to load dashboard data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.id]);

  const certificationStatus = calculateCertificationStatus(documents);

  const stats = [
    {
      title: 'Total Documents',
      value: documents.length,
      icon: <DocumentIcon sx={{ fontSize: 40 }} />,
      color: 'primary.main',
    },
    {
      title: 'Active Clients',
      value: clients.filter(c => c.isActive).length,
      icon: <ClientIcon sx={{ fontSize: 40 }} />,
      color: 'success.main',
    },
    {
      title: 'Pending Alerts',
      value: documents.filter(d => d.status === 'pending').length,
      icon: <AlertIcon sx={{ fontSize: 40 }} />,
      color: 'warning.main',
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('dashboard.title', 'Dashboard')}
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {stats.map((stat) => (
          <Grid item xs={12} sm={4} key={stat.title}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box sx={{ color: stat.color }}>{stat.icon}</Box>
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h4">{stat.value}</Typography>
                    <Typography color="text.secondary">{stat.title}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Typography variant="h6">
                {t('certification.title', 'Certification Status')}
              </Typography>
              <ComplianceBadge status={certificationStatus} />
            </Box>
            <Typography color="text.secondary">
              {t('certification.description', 'Your current certification status and compliance requirements.')}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;