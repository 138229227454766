import {
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  Timestamp,
  updateDoc,
  setDoc,
  orderBy,
  increment,
  deleteDoc,
  writeBatch
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { emailService } from './emailService';
import { Document } from '../types';
import { createNotification } from './notifications';
import { v4 as uuidv4 } from 'uuid';
import { addDays } from 'date-fns';
import { CLIENTS_COLLECTION } from './firestore';
import { NotificationType } from '../types/notification';


const SHARE_TOKENS_COLLECTION = 'document_share_tokens';
const TOKEN_VALIDITY_DAYS = 7; // Durée de validité du token en jours
const SETTINGS_DOC_ID = 'share_token_settings';

interface ShareToken {
  id: string;
  token: string;
  documentIds: string[];
  clientEmail: string;
  createdAt: string;
  expiresAt: string;
  isUsed: boolean;
  accessCount?: number;
  lastAccessedAt?: string;
  createdBy?: string;
}

interface ShareTokenSettings {
  defaultValidityDays: number;
  maxValidityDays: number;
  allowReshare: boolean;
  allowDownload: boolean;
  notifyOnAccess: boolean;
}

export const generateShareToken = async (
  documents: Document[],
  clientEmail: string,
  clientId?: string
): Promise<string> => {
  try {
    const token = uuidv4();
    const now = new Date();
    const expiresAt = addDays(now, TOKEN_VALIDITY_DAYS);

    const tokenData: Omit<ShareToken, 'id'> = {
      token,
      documentIds: documents.map(doc => doc.id),
      clientEmail,
      createdAt: now.toISOString(),
      expiresAt: expiresAt.toISOString(),
      isUsed: false
    };

    const tokenRef = await addDoc(
      collection(db, SHARE_TOKENS_COLLECTION),
      tokenData
    );

    return token;
  } catch (error) {
    console.error('Error generating share token:', error);
    throw new Error('Failed to generate share token');
  }
};

export const validateShareToken = async (token: string): Promise<ShareToken | null> => {
  try {
    const tokensRef = collection(db, SHARE_TOKENS_COLLECTION);
    const q = query(tokensRef, where('token', '==', token));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    const tokenDoc = querySnapshot.docs[0];
    const tokenData = tokenDoc.data() as ShareToken;
    tokenData.id = tokenDoc.id;

    const now = new Date();
    if (new Date(tokenData.expiresAt) < now || tokenData.isUsed) {
      return null;
    }

    return tokenData;
  } catch (error) {
    console.error('Error validating share token:', error);
    throw new Error('Failed to validate share token');
  }
};

export const markTokenAsUsed = async (tokenId: string): Promise<void> => {
  try {
    const tokenRef = doc(db, SHARE_TOKENS_COLLECTION, tokenId);
    await updateDoc(tokenRef, { isUsed: true });
  } catch (error) {
    console.error('Error marking token as used:', error);
    throw new Error('Failed to mark token as used');
  }
};

export const shareDocumentsWithClient = async (
  documents: Document[],
  clientEmail: string,
  baseUrl: string,
  clientId?: string,
  config: {
    allowDownload?: boolean;
    allowReshare?: boolean;
    senderName?: string;
  } = {}
): Promise<void> => {
  try {
    // Si le client a un compte, envoyer une notification in-app
    if (clientId) {
      await createNotification({
        userId: clientId,
        type: NotificationType.DOCUMENTS_SHARED,
        title: 'Nouveaux documents disponibles',
        message: `${documents.length} document(s) ont été partagés avec vous`,
        data: {
          documentIds: documents.map(doc => doc.id)
        }
      });
    } 
    // Sinon, générer un token et envoyer un email
    else {
      const token = await generateShareToken(documents, clientEmail);
      const shareUrl = `${baseUrl}/shared-documents/${token}`;
      const expiresAt = addDays(new Date(), TOKEN_VALIDITY_DAYS);

      // Utiliser le service email existant avec Brevo
      await emailService.sendDocumentShareEmail(
        clientEmail,
        documents.length === 1 ? documents[0].title : `${documents.length} documents partagés`,
        shareUrl,
        expiresAt,
        {
          allowDownload: config.allowDownload ?? true,
          allowReshare: config.allowReshare ?? false
        },
        config.senderName || 'ComplyDoc'
      );
    }
  } catch (error) {
    console.error('Error sharing documents:', error);
    throw new Error('Failed to share documents');
  }
};

export const getSharedDocuments = async (token: string): Promise<Document[]> => {
  try {
    const tokenData = await validateShareToken(token);
    if (!tokenData) {
      throw new Error('Invalid or expired token');
    }

    const documents: Document[] = [];
    for (const docId of tokenData.documentIds) {
      const docRef = doc(db, 'documents', docId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        documents.push({
          id: docSnap.id,
          ...docSnap.data()
        } as Document);
      }
    }

    return documents;
  } catch (error) {
    console.error('Error fetching shared documents:', error);
    throw new Error('Failed to fetch shared documents');
  }
};

export const getShareTokenSettings = async (): Promise<ShareTokenSettings> => {
  try {
    const settingsRef = doc(db, 'settings', SETTINGS_DOC_ID);
    const settingsSnap = await getDoc(settingsRef);

    if (!settingsSnap.exists()) {
      // Paramètres par défaut
      const defaultSettings: ShareTokenSettings = {
        defaultValidityDays: 7,
        maxValidityDays: 30,
        allowReshare: false,
        allowDownload: true,
        notifyOnAccess: true,
      };

      await setDoc(settingsRef, defaultSettings);
      return defaultSettings;
    }

    return settingsSnap.data() as ShareTokenSettings;
  } catch (error) {
    console.error('Error getting share token settings:', error);
    throw new Error('Failed to get share token settings');
  }
};

export const updateShareTokenSettings = async (
  settings: ShareTokenSettings
): Promise<void> => {
  try {
    const settingsRef = doc(db, 'settings', SETTINGS_DOC_ID);
    await setDoc(settingsRef, settings);
  } catch (error) {
    console.error('Error updating share token settings:', error);
    throw new Error('Failed to update share token settings');
  }
};

export const getActiveShareTokens = async (): Promise<ShareToken[]> => {
  try {
    const tokensRef = collection(db, SHARE_TOKENS_COLLECTION);
    const q = query(
      tokensRef,
      where('expiresAt', '>', Timestamp.fromDate(new Date())),
      orderBy('expiresAt', 'desc')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt?.toDate().toISOString() || '',
        expiresAt: data.expiresAt?.toDate().toISOString() || '',
        lastAccessedAt: data.lastAccessedAt?.toDate().toISOString() || undefined,
        accessCount: data.accessCount || 0
      } as ShareToken;
    });
  } catch (error) {
    console.error('Error getting active share tokens:', error);
    throw new Error('Failed to get active share tokens');
  }
};

export const revokeShareToken = async (tokenId: string): Promise<void> => {
  try {
    const tokenRef = doc(db, SHARE_TOKENS_COLLECTION, tokenId);
    await deleteDoc(tokenRef);
  } catch (error) {
    console.error('Error revoking share token:', error);
    throw new Error('Failed to revoke share token');
  }
};

export const extendShareToken = async (
  tokenId: string,
  days: number
): Promise<void> => {
  try {
    const settings = await getShareTokenSettings();
    if (days > settings.maxValidityDays) {
      throw new Error(`La durée maximale autorisée est de ${settings.maxValidityDays} jours`);
    }

    const tokenRef = doc(db, SHARE_TOKENS_COLLECTION, tokenId);
    const tokenSnap = await getDoc(tokenRef);

    if (!tokenSnap.exists()) {
      throw new Error('Token not found');
    }

    const currentExpiryDate = (tokenSnap.data().expiresAt as Timestamp).toDate();
    const newExpiryDate = addDays(currentExpiryDate, days);

    await updateDoc(tokenRef, {
      expiresAt: Timestamp.fromDate(newExpiryDate)
    });
  } catch (error) {
    console.error('Error extending share token:', error);
    throw new Error('Failed to extend share token');
  }
};

export const incrementAccessCount = async (tokenId: string): Promise<void> => {
  try {
    const tokenRef = doc(db, SHARE_TOKENS_COLLECTION, tokenId);
    await updateDoc(tokenRef, {
      accessCount: increment(1),
      lastAccessedAt: serverTimestamp()
    });

    const settings = await getShareTokenSettings();
    if (settings.notifyOnAccess) {
      const tokenSnap = await getDoc(tokenRef);
      if (tokenSnap.exists()) {
        const tokenData = tokenSnap.data() as ShareToken;
        if (tokenData.createdBy) {
          await createNotification({
            userId: tokenData.createdBy,
            type: NotificationType.DOCUMENT_ACCESSED,
            title: 'Documents consultés',
            message: `Les documents partagés avec ${tokenData.clientEmail} ont été consultés`,
            data: {
              tokenId,
              documentIds: tokenData.documentIds
            }
          });
        }
      }
    }
  } catch (error) {
    console.error('Error incrementing access count:', error);
    throw new Error('Failed to increment access count');
  }
};

export const shareDocuments = async (
  documentIds: string[],
  clientId: string,
  userId: string
): Promise<void> => {
  try {
    console.log('[DEBUG] Début du partage des documents:', {
      documentIds,
      clientId,
      userId,
      timestamp: new Date().toISOString()
    });
    
    const batch = writeBatch(db);
    const now = new Date().toISOString();
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 30); // 30 jours de validité
    
    // Générer un token unique pour ce partage
    const newShareToken = {
      clientId,
      token: uuidv4(),
      createdAt: now,
      expiresAt: expiresAt.toISOString(),
      isUsed: false,
      createdBy: userId,
      accessCount: 0
    };
    
    console.log('[DEBUG] Token de partage généré:', {
      shareToken: newShareToken,
      timestamp: new Date().toISOString()
    });

    // Mettre à jour chaque document avec le token de partage et le clientId
    for (const documentId of documentIds) {
      console.log(`[DEBUG] Traitement du document ${documentId}`);
      
      const docRef = doc(db, 'documents', documentId);
      console.log('[DEBUG] Récupération du document');
      const docSnapshot = await getDoc(docRef);
      
      if (!docSnapshot.exists()) {
        console.error(`[ERROR] Document ${documentId} n'existe pas`);
        continue;
      }
      
      const documentData = docSnapshot.data();
      console.log('[DEBUG] Données du document:', {
        documentId,
        userId: documentData.userId,
        currentUserId: userId,
        isOwner: documentData.userId === userId
      });
      
      // Vérifier que l'utilisateur est le propriétaire du document
      if (documentData.userId !== userId) {
        console.error(`[ERROR] L'utilisateur ${userId} n'est pas autorisé à partager le document ${documentId}. Propriétaire: ${documentData.userId}`);
        continue;
      }

      // Récupérer les partages existants ou initialiser un tableau vide
      const existingShares = documentData.shares || [];
      
      // Vérifier si le document est déjà partagé avec ce client
      const existingShareIndex = existingShares.findIndex((share: { clientId: string }) => share.clientId === clientId);
      
      let updatedShares;
      if (existingShareIndex !== -1) {
        // Mettre à jour le partage existant
        updatedShares = [...existingShares];
        updatedShares[existingShareIndex] = newShareToken;
      } else {
        // Ajouter un nouveau partage
        updatedShares = [...existingShares, newShareToken];
      }
      
      const updateData = {
        shares: updatedShares,
        sharedWithClientIds: Array.from(new Set([...(documentData.sharedWithClientIds || []), clientId])),
        updatedAt: now
      };
      
      console.log(`[DEBUG] Mise à jour du document ${documentId}:`, {
        updateData,
        timestamp: new Date().toISOString()
      });
      
      batch.update(docRef, updateData);
    }

    // Créer une notification de partage
    console.log('[DEBUG] Création de la notification');
    const notificationRef = collection(db, 'notifications');
    const notification = {
      type: NotificationType.DOCUMENTS_SHARED,
      userId,
      clientId,
      documentIds,
      shareToken: newShareToken.token,
      createdAt: now,
      read: false
    };
    
    console.log('[DEBUG] Données de notification:', {
      notification,
      timestamp: new Date().toISOString()
    });
    
    batch.set(doc(notificationRef), notification);
    
    console.log('[DEBUG] Début du batch commit');
    await batch.commit();
    console.log('[DEBUG] Batch commit réussi');

    // Créer un index composé pour les requêtes de documents partagés
    try {
      console.log('[DEBUG] Création des index');
      const documentsRef = collection(db, 'documents');
      await Promise.all([
        // Index pour les documents partagés directement
        addCollectionIndex(documentsRef, [
          { field: 'sharedWithClientIds', direction: 'asc' },
          { field: 'updatedAt', direction: 'desc' }
        ]),
        // Index pour les documents partagés via token
        addCollectionIndex(documentsRef, [
          { field: 'shares.clientId', direction: 'asc' },
          { field: 'updatedAt', direction: 'desc' }
        ])
      ]);
      console.log('[DEBUG] Index créés avec succès');
    } catch (error) {
      console.warn('[WARN] Erreur lors de la création des index:', error);
    }
  } catch (error) {
    console.error('[ERROR] Erreur lors du partage des documents:', error);
    throw error;
  }
};

// Fonction utilitaire pour créer un index composé
const addCollectionIndex = async (collectionRef: any, fields: { field: string, direction: 'asc' | 'desc' }[]) => {
  // Cette fonction est un placeholder - dans une vraie application,
  // les index seraient créés via la console Firebase
  console.log('Creating index for fields:', fields);
};

export const getSharedDocumentsForClient = async (clientId: string): Promise<string[]> => {
  try {
    console.log('Récupération des documents partagés pour le client:', clientId);
    
    // 1. Vérifier les documents directement partagés
    const documentsRef = collection(db, 'documents');
    const directShareQuery = query(documentsRef, where('sharedWithClientIds', 'array-contains', clientId));
    const directShareDocs = await getDocs(directShareQuery);
    
    // 2. Vérifier les documents partagés via token
    const tokenShareQuery = query(documentsRef, where('shares', 'array-contains-any', 
      [{ clientId: clientId }]));
    const tokenShareDocs = await getDocs(tokenShareQuery);
    
    // Combiner les résultats
    const sharedDocIds = new Set([
      ...directShareDocs.docs.map(doc => doc.id),
      ...tokenShareDocs.docs.map(doc => doc.id)
    ]);
    
    console.log('Documents partagés trouvés:', {
      clientId,
      directShare: directShareDocs.docs.map(doc => doc.id),
      tokenShare: tokenShareDocs.docs.map(doc => doc.id),
      total: Array.from(sharedDocIds)
    });
    
    return Array.from(sharedDocIds);
  } catch (error) {
    console.error('Erreur lors de la récupération des documents partagés:', error);
    return [];
  }
};
