import { useState } from 'react';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../stores/authStore';
import { updateNotificationSettings } from '../../services/profile';
import { NotificationSettings as NotificationSettingsType } from '../../types/profile';

const NotificationSettings = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [settings, setSettings] = useState<NotificationSettingsType>({
    emailNotifications: true,
    documentExpiryAlerts: true,
    weeklyDigest: false,
    securityAlerts: true,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSave = async () => {
    if (!user?.id) return;

    setIsSaving(true);
    setError('');
    setSuccess(false);

    try {
      await updateNotificationSettings(user.id, settings);
      setSuccess(true);
    } catch (err) {
      setError(t('profile.notifications.error', 'Échec de la mise à jour des paramètres de notification'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {t('profile.notifications.title', 'Paramètres de Notification')}
      </Typography>
      <Typography color="text.secondary" paragraph>
        {t('profile.notifications.description', 'Personnalisez la façon dont vous souhaitez recevoir les notifications et les alertes')}
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {t('profile.notifications.success', 'Paramètres de notification mis à jour avec succès')}
        </Alert>
      )}

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={settings.emailNotifications}
              onChange={handleChange}
              name="emailNotifications"
            />
          }
          label={t('profile.notifications.email', 'Notifications par e-mail')}
        />
        <FormControlLabel
          control={
            <Switch
              checked={settings.documentExpiryAlerts}
              onChange={handleChange}
              name="documentExpiryAlerts"
            />
          }
          label={t('profile.notifications.expiry', 'Alertes d\'expiration des documents')}
        />
        <FormControlLabel
          control={
            <Switch
              checked={settings.weeklyDigest}
              onChange={handleChange}
              name="weeklyDigest"
            />
          }
          label={t('profile.notifications.digest', 'Résumé hebdomadaire')}
        />
        <FormControlLabel
          control={
            <Switch
              checked={settings.securityAlerts}
              onChange={handleChange}
              name="securityAlerts"
            />
          }
          label={t('profile.notifications.security', 'Alertes de sécurité')}
        />
      </FormGroup>

      <Button
        variant="contained"
        onClick={handleSave}
        disabled={isSaving}
        startIcon={isSaving ? <CircularProgress size={20} /> : null}
        sx={{ mt: 3 }}
      >
        {t('profile.notifications.save', 'Enregistrer les modifications')}
      </Button>
    </Box>
  );
};

export default NotificationSettings;