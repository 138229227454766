import { Button, Box, CircularProgress } from '@mui/material';
import { Google as GoogleIcon, GitHub as GitHubIcon } from '@mui/icons-material';
import { SocialAuthProvider } from '../types/auth';
import { useTranslation } from 'react-i18next';

interface SocialLoginProps {
  onSocialLogin: (provider: SocialAuthProvider) => Promise<void>;
  isLoading: SocialAuthProvider | null;
  setIsLoading: (provider: SocialAuthProvider | null) => void;
}

const SocialLogin = ({ onSocialLogin, isLoading, setIsLoading }: SocialLoginProps) => {
  const { t } = useTranslation();

  const handleSocialLogin = async (provider: SocialAuthProvider) => {
    try {
      setIsLoading(provider);
      await onSocialLogin(provider);
    } catch (error) {
      console.error('Social login error:', error);
    } finally {
      setIsLoading(null);
    }
  };

  return (
    <Box>
      <Button
        fullWidth
        variant="outlined"
        size="large"
        startIcon={<GoogleIcon />}
        onClick={() => handleSocialLogin('google')}
        disabled={!!isLoading}
        sx={{ mb: 2 }}
      >
        {isLoading === 'google' ? (
          <CircularProgress size={24} />
        ) : (
          t('auth.socialAuth.continueWithGoogle')
        )}
      </Button>
      <Button
        fullWidth
        variant="outlined"
        size="large"
        startIcon={<GitHubIcon />}
        onClick={() => handleSocialLogin('github')}
        disabled={!!isLoading}
      >
        {isLoading === 'github' ? (
          <CircularProgress size={24} />
        ) : (
          t('auth.socialAuth.continueWithGithub')
        )}
      </Button>
    </Box>
  );
};

export default SocialLogin;