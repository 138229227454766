import { 
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  FirestoreError
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { Client } from '../types';

export const CLIENTS_COLLECTION = 'clients';

const handleFirestoreError = (error: FirestoreError) => {
  console.error('Firestore error:', error);
  switch (error.code) {
    case 'permission-denied':
      throw new Error('You do not have permission to perform this action');
    case 'not-found':
      throw new Error('Document not found');
    default:
      throw new Error('An error occurred while accessing the database');
  }
};

export const getClients = async (userId: string): Promise<Client[]> => {
  try {
    const clientsRef = collection(db, CLIENTS_COLLECTION);
    const q = query(clientsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    
    const clients = querySnapshot.docs.map(doc => {
      const data = doc.data();
      console.log('Document Firestore:', { id: doc.id, data });
      
      return {
        id: doc.id, 
        ...data,
        createdAt: data.createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
        updatedAt: data.updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      } as Client;
    });
    
    console.log('Clients récupérés:', clients);
    return clients;
  } catch (error) {
    handleFirestoreError(error as FirestoreError);
    throw error;
  }
};

export const getClient = async (clientId: string): Promise<Client> => {
  try {
    const docRef = doc(db, CLIENTS_COLLECTION, clientId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Client not found');
    }

    const data = docSnap.data();
    return {
      id: docSnap.id,
      ...data,
      createdAt: data.createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      updatedAt: data.updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
    } as Client;
  } catch (error) {
    handleFirestoreError(error as FirestoreError);
    throw error;
  }
};

export const createClient = async (userId: string, clientData: Omit<Client, 'id'>): Promise<Client> => {
  try {
    const newClient = {
      ...clientData,
      userId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    const clientsRef = collection(db, CLIENTS_COLLECTION);
    const docRef = await addDoc(clientsRef, newClient);
    return {
      id: docRef.id,
      ...clientData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    } as Client;
  } catch (error) {
    handleFirestoreError(error as FirestoreError);
    throw error;
  }
};

export const updateClient = async (clientId: string, clientData: Partial<Client>): Promise<void> => {
  try {
    if (!clientId) {
      throw new Error('Client ID is required');
    }
    
    // Remove id from the update data to prevent duplicate creation
    const { id, ...updateData } = clientData;
    
    const clientRef = doc(db, CLIENTS_COLLECTION, clientId);
    await updateDoc(clientRef, {
      ...updateData,
      updatedAt: serverTimestamp(),
    });
  } catch (error) {
    handleFirestoreError(error as FirestoreError);
    throw error;
  }
};

export const deleteClient = async (clientId: string): Promise<void> => {
  try {
    const docRef = doc(db, CLIENTS_COLLECTION, clientId);
    await deleteDoc(docRef);
  } catch (error) {
    handleFirestoreError(error as FirestoreError);
    throw error;
  }
};