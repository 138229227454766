import { FC, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

interface SecuritySettingsProps {
  onPasswordChange: (data: PasswordChangeData) => Promise<void>;
}

export interface PasswordChangeData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const SecuritySettings: FC<SecuritySettingsProps> = ({ onPasswordChange }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const passwordSchema = z.object({
    currentPassword: z.string().min(1, t('profile.security.password.validation.required')),
    newPassword: z.string().min(8, t('profile.security.password.validation.length')),
    confirmPassword: z.string(),
  }).refine((data) => data.newPassword === data.confirmPassword, {
    message: t('profile.security.password.validation.match'),
    path: ['confirmPassword'],
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PasswordChangeData>({
    resolver: zodResolver(passwordSchema),
  });

  const onSubmit = async (data: PasswordChangeData) => {
    setIsLoading(true);
    setSuccess(false);
    try {
      await onPasswordChange(data);
      setSuccess(true);
      reset();
    } catch (error) {
      console.error('Error changing password:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('profile.security.password.title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('profile.security.password.description')}
          </Typography>
        </Box>

        {success && (
          <Alert severity="success" sx={{ mb: 3 }}>
            {t('profile.security.password.success')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              {...register('currentPassword')}
              label={t('profile.security.password.current')}
              type="password"
              error={!!errors.currentPassword}
              helperText={errors.currentPassword?.message}
              fullWidth
            />

            <TextField
              {...register('newPassword')}
              label={t('profile.security.password.new')}
              type="password"
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              fullWidth
            />

            <TextField
              {...register('confirmPassword')}
              label={t('profile.security.password.confirm')}
              type="password"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              fullWidth
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
              >
                {isLoading ? t('common.updating') : t('profile.security.password.update')}
              </Button>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default SecuritySettings;