import { useState } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  Drawer,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Stack,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  GppMaybe as GppMaybeIcon,
  Verified as VerifiedIcon,
  VerifiedUser as VerifiedUserIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { CertificationStatus, CertificationBadge } from '../types/certification';
import { CERTIFICATION_BADGES } from '../config/certification';

interface ComplianceBadgeProps {
  status: CertificationStatus;
  showDetails?: boolean;
}

const ComplianceBadge = ({ status, showDetails = true }: ComplianceBadgeProps) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const badge: CertificationBadge = CERTIFICATION_BADGES[status.badge];

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'valid':
        return <CheckCircleIcon color="success" />;
      case 'expiring_soon':
        return <WarningIcon color="warning" />;
      case 'expired':
        return <ErrorIcon color="error" />;
      default:
        return <InfoIcon color="disabled" />;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'valid':
        return 'success';
      case 'expiring_soon':
        return 'warning';
      case 'expired':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: showDetails ? 'pointer' : 'default',
        }}
        onClick={() => showDetails && setDrawerOpen(true)}
      >
        {(() => {
          switch (badge.icon) {
            case 'gpp_maybe':
              return <GppMaybeIcon htmlColor={badge.color} fontSize="medium" />;
            case 'verified':
              return <VerifiedIcon htmlColor={badge.color} fontSize="medium" />;
            case 'verified_user':
              return <VerifiedUserIcon htmlColor={badge.color} fontSize="medium" />;
            default:
              return <InfoIcon htmlColor={badge.color} fontSize="medium" />;
          }
        })()}
        <Typography variant="subtitle2" sx={{ color: badge.color }}>
          {t(`certification.badges.${badge.type}`, badge.label)}
        </Typography>
        <Chip
          label={`${status.score}%`}
          size="small"
          sx={{
            bgcolor: badge.color,
            color: 'white',
            '.MuiChip-label': { px: 1 },
          }}
        />
      </Box>

      {showDetails && (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            sx: { 
              width: { xs: '100%', sm: '500px' },
              boxSizing: 'border-box',
              paddingTop: '64px', // Header height
            }
          }}
        >
          <Box sx={{ p: 2, height: '100%', overflow: 'auto' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h6">
                {t('certification.details.title', 'Certification Details')}
              </Typography>
              <IconButton
                onClick={() => setDrawerOpen(false)}
                size="small"
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                {t('certification.details.status', 'Current Status')}:
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                {(() => {
                  switch (badge.icon) {
                    case 'gpp_maybe':
                      return <GppMaybeIcon htmlColor={badge.color} sx={{ fontSize: 40 }} />;
                    case 'verified':
                      return <VerifiedIcon htmlColor={badge.color} sx={{ fontSize: 40 }} />;
                    case 'verified_user':
                      return <VerifiedUserIcon htmlColor={badge.color} sx={{ fontSize: 40 }} />;
                    default:
                      return <InfoIcon htmlColor={badge.color} sx={{ fontSize: 40 }} />;
                  }
                })()}
                <Box>
                  <Typography variant="h6" sx={{ color: badge.color }}>
                    {t(`certification.badges.${badge.type}`, badge.label)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('certification.details.score', 'Score')}: {status.score}%
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Typography variant="subtitle1" gutterBottom>
              {t('certification.details.validity', 'Validity Period')}:
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2">
                {t('certification.details.lastChecked', 'Last Checked')}: {' '}
                {format(new Date(status.lastChecked), 'PPP')}
              </Typography>
              <Typography variant="body2">
                {t('certification.details.validUntil', 'Valid Until')}: {' '}
                {format(new Date(status.validUntil), 'PPP')}
              </Typography>
            </Box>

            <Typography variant="subtitle1" gutterBottom>
              {t('certification.details.requirements', 'Requirements')}:
            </Typography>
            <List>
              {Object.entries(status.requirements).map(([id, req]) => (
                <ListItem key={id}>
                  <ListItemIcon>
                    {getStatusIcon(req.status)}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(`certification.requirements.${id}`, id)}
                    secondary={req.expiryDate && (
                      t('certification.details.expires', {
                        date: format(new Date(req.expiryDate), 'PPP'),
                        defaultValue: `Expires: ${format(new Date(req.expiryDate), 'PPP')}`
                      })
                    )}
                  />
                  <Chip
                    label={t(`certification.status.${req.status}`, req.status)}
                    color={getStatusColor(req.status) as any}
                    size="small"
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default ComplianceBadge;