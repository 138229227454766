import axios from 'axios';
import { emailService } from './emailService';

interface ShareConfig {
  expiresIn?: number; // Durée en heures
  allowDownload?: boolean;
  allowReshare?: boolean;
}

interface DocumentStats {
  downloads: number;
  shares: number;
  lastAccessed?: Date;
}

interface ShareResponse {
  success: boolean;
  sharedUrl?: string;
  expiresAt?: Date;
  error?: string;
}

const STORAGE_KEY = 'document_stats';

class DocumentService {
  private stats: Map<string, DocumentStats>;

  constructor() {
    this.stats = new Map();
    this.loadStats();
  }

  private loadStats(): void {
    try {
      const savedStats = localStorage.getItem(STORAGE_KEY);
      if (savedStats) {
        const parsed = JSON.parse(savedStats);
        this.stats = new Map(Object.entries(parsed));
      }
    } catch (error) {
      console.error('Erreur lors du chargement des statistiques:', error);
    }
  }

  private saveStats(): void {
    try {
      const statsObj = Object.fromEntries(this.stats);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(statsObj));
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des statistiques:', error);
    }
  }

  private getDocumentIdFromUrl(url: string): string {
    try {
      if (!url) {
        throw new Error('URL invalide: URL vide');
      }

      // Si l'URL contient déjà un token, l'utiliser directement
      if (url.includes('/document/')) {
        const parts = url.split('/document/');
        if (parts.length < 2) {
          throw new Error('URL invalide: format incorrect');
        }
        return parts[1].split('?')[0].split('.')[0];
      }

      // Nettoyer l'URL des slashes au début et à la fin
      const cleanUrl = url.replace(/^\/+|\/+$/g, '');
      
      // Extraire le nom du fichier (dernier segment de l'URL)
      const fileName = cleanUrl.split('/').pop();
      if (!fileName) {
        throw new Error('URL invalide: pas de nom de fichier');
      }
      
      // Retirer l'extension si elle existe
      return fileName.replace(/\.[^/.]+$/, '');
    } catch (error) {
      console.error('Erreur lors de l\'extraction de l\'ID du document:', error);
      throw error;
    }
  }

  private updateStats(documentId: string, action: 'download' | 'share'): void {
    const stats = this.stats.get(documentId) || { downloads: 0, shares: 0 };
    if (action === 'download') {
      stats.downloads += 1;
    } else {
      stats.shares += 1;
    }
    stats.lastAccessed = new Date();
    this.stats.set(documentId, stats);
    this.saveStats();
  }

  async shareDocument(
    documentUrl: string,
    email: string,
    config: ShareConfig = {}
  ): Promise<ShareResponse> {
    try {
      const expiresAt = new Date(Date.now() + (config.expiresIn || 24) * 3600000);
      const sharedUrl = `${documentUrl}?shared=true&expires=${expiresAt.getTime()}`;

      // Extraire le nom du document de l'URL
      const documentName = decodeURIComponent(documentUrl.split('/').pop() || 'Document');

      // Envoyer l'email avec Brevo
      await emailService.sendDocumentShareEmail(
        email,
        documentName,
        sharedUrl,
        expiresAt,
        {
          allowDownload: config.allowDownload ?? true,
          allowReshare: config.allowReshare ?? false,
        }
      );

      // Mettre à jour les statistiques
      const documentId = this.getDocumentIdFromUrl(documentUrl);
      this.updateStats(documentId, 'share');

      return {
        success: true,
        sharedUrl,
        expiresAt,
      };
    } catch (error: any) {
      console.error('Error sharing document:', error);
      return {
        success: false,
        error: error.message || 'Erreur lors du partage du document',
      };
    }
  }

  async trackDownload(documentUrl: string): Promise<void> {
    const documentId = this.getDocumentIdFromUrl(documentUrl);
    this.updateStats(documentId, 'download');
  }

  getDocumentStats(documentUrl: string): DocumentStats {
    const documentId = this.getDocumentIdFromUrl(documentUrl);
    return this.stats.get(documentId) || { downloads: 0, shares: 0 };
  }
}

export const documentService = new DocumentService();
