import { 
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  FirestoreError
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { Document } from '../types';
import { uploadDocument as uploadToStorage, deleteDocument as deleteFromStorage } from './storage';
import { checkDocumentExpirations } from './documentNotifications';
import { useAuthStore } from '../stores/authStore';

const DOCUMENTS_COLLECTION = 'documents';

const handleFirestoreError = (error: FirestoreError | unknown) => {
  console.error('Firestore error:', error);
  if (error instanceof FirestoreError) {
    switch (error.code) {
      case 'permission-denied':
        throw new Error('You do not have permission to perform this action');
      case 'not-found':
        throw new Error('Document not found');
      default:
        throw new Error('An error occurred while accessing the database');
    }
  }
  throw new Error('An unexpected error occurred');
};

export const getDocuments = async (userId: string): Promise<Document[]> => {
  try {
    const documentsRef = collection(db, 'documents');
    
    // 1. Récupérer les documents possédés par l'utilisateur
    const ownedDocsQuery = query(documentsRef, where('userId', '==', userId));
    const ownedDocsSnapshot = await getDocs(ownedDocsQuery);
    const ownedDocs = ownedDocsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      updatedAt: doc.data().updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
    } as Document));

    console.log('Documents possédés:', ownedDocs);

    // 2. Récupérer les documents partagés directement
    const sharedDirectlyQuery = query(documentsRef, where('sharedWithClientId', '==', userId));
    const sharedDirectlySnapshot = await getDocs(sharedDirectlyQuery);
    const sharedDirectlyDocs = sharedDirectlySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      updatedAt: doc.data().updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
    } as Document));

    // 3. Récupérer les documents partagés via token
    const sharedViaTokenQuery = query(documentsRef, where('shareToken.clientId', '==', userId));
    const sharedViaTokenSnapshot = await getDocs(sharedViaTokenQuery);
    const sharedViaTokenDocs = sharedViaTokenSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      updatedAt: doc.data().updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
    } as Document));

    console.log('Documents partagés directement:', sharedDirectlyDocs);
    console.log('Documents partagés via token:', sharedViaTokenDocs);

    // 4. Combiner tous les documents uniques par ID
    const allDocs = Array.from(
      new Map(
        [...ownedDocs, ...sharedDirectlyDocs, ...sharedViaTokenDocs]
          .map(doc => [doc.id, doc])
      ).values()
    );

    console.log('Tous les documents (uniques):', allDocs);
    
    // Check for expirations after fetching documents
    try {
      const user = useAuthStore.getState().user;
      if (user?.email) {
        await checkDocumentExpirations(
          allDocs,
          user.email,
          'fr' // Utiliser le français par défaut
        );
      }
    } catch (error) {
      console.error('Error checking document expirations:', error);
      // Ne pas propager l'erreur pour éviter d'interrompre le chargement des documents
    }

    return allDocs;
  } catch (error) {
    handleFirestoreError(error);
    throw error;
  }
};

export const getDocument = async (documentId: string): Promise<Document> => {
  try {
    const docRef = doc(db, DOCUMENTS_COLLECTION, documentId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Document not found');
    }

    const data = docSnap.data();
    return {
      id: docSnap.id,
      ...data,
      createdAt: data.createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      updatedAt: data.updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
    } as Document;
  } catch (error) {
    handleFirestoreError(error);
    throw error;
  }
};

export const createDocument = async (
  userId: string, 
  file: File, 
  metadata: {
    title: string;
    category: string;
    status: string;
    expiryDate?: string;
    companyId?: string;
  }
): Promise<Document> => {
  try {
    // Upload file to storage
    const fileUrl = await uploadToStorage(file, userId);

    // Create document in Firestore
    const newDocument = {
      userId,
      fileUrl,
      ...metadata,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, DOCUMENTS_COLLECTION), newDocument);
    
    return {
      id: docRef.id,
      ...newDocument,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    } as Document;
  } catch (error) {
    handleFirestoreError(error);
    throw error;
  }
};

export const deleteDocumentWithFile = async (documentId: string): Promise<void> => {
  try {
    // Get document data first to get the file URL
    const document = await getDocument(documentId);
    
    // Delete the file from storage
    await deleteFromStorage(document.fileUrl);
    
    // Delete the document from Firestore
    const docRef = doc(db, DOCUMENTS_COLLECTION, documentId);
    await deleteDoc(docRef);
  } catch (error) {
    handleFirestoreError(error);
    throw error;
  }
};