import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchCompanyData, fetchUserCompanies, downloadDocument } from '../../../services/pappersApi';
import { documentService } from '../../../services/documentService';
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
  Snackbar,
  Alert,
  FormControlLabel,
  Switch,
  Chip,
  Tooltip,
  ListItemIcon,
  ListItemText,
  Fade,
} from '@mui/material';
import {
  Download as DownloadIcon,
  Share as ShareIcon,
  MoreVert as MoreVertIcon,
  PictureAsPdf as PictureAsPdfIcon,
  ContentCopy as ContentCopyIcon,
  Description as DescriptionIcon,
  Assignment as AssignmentIcon,
  Verified as VerifiedIcon,
  AccountBalance as AccountBalanceIcon,
  Business as BusinessIcon,
  Timeline as TimelineIcon,
  Group as GroupIcon,
  Notifications as NotificationsIcon,
  Refresh as RefreshIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  TrendingFlat as TrendingFlatIcon,
  GridView as GridViewIcon,
  ViewList as ViewListIcon,
  Article as ArticleIcon,
} from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import { ReportType, ReportFilters } from '../../../types/reports';

interface CompanyInfo {
  name: string;
  siren: string;
  legalForm: string;
  address: string;
  creationDate: Date;
  capital: number;
  activity: string;
}

interface FinancialData {
  year: number;
  revenue: number;
  netIncome: number;
  equity: number;
  employees: number;
}

interface Executive {
  name: string;
  role: string;
  appointmentDate: Date;
}

interface Document {
  type: string;
  name: string;
  date: Date | null;
  url: string;
  description?: string;
  downloads?: number;
  shares?: number;
  category: 'legal' | 'financial' | 'corporate' | 'other';
  token: string;
}

interface ReportData {
  companyInfo: CompanyInfo;
  financialData: FinancialData[];
  executives: Executive[];
  documents: Document[];
}

interface ReportViewerProps {
  siren: string;
  type?: ReportType;
  filters?: ReportFilters;
  isLoading?: boolean;
}

interface PappersFinanceData {
  annee: number;
  chiffre_affaires: number;
  resultat: number;
  fonds_propres: number;
  effectif: number | string;
}

interface PappersRepresentant {
  nom_complet: string;
  qualite: string;
  date_prise_de_poste: string;
}

interface PappersDocument {
  nom_fichier_pdf: string;
  date_depot: string;
  date_cloture?: string;
  annee_cloture?: number;
  token: string;
  actes?: Array<{ decision: string }>;
}

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const formatNumber = (value: number): string => {
  return new Intl.NumberFormat('fr-FR').format(value);
};

const transformPappersData = (pappersData: any): ReportData => {
  console.log('Données brutes Pappers:', pappersData);

  // Transformation des informations de l'entreprise
  const companyInfo: CompanyInfo = {
    name: pappersData.denomination || pappersData.nom_entreprise || '',
    siren: pappersData.siren || '',
    legalForm: pappersData.forme_juridique || '',
    address: `${pappersData.siege?.adresse_ligne_1 || ''} ${pappersData.siege?.code_postal || ''} ${pappersData.siege?.ville || ''}`.trim(),
    creationDate: new Date(pappersData.date_creation),
    capital: pappersData.capital || 0,
    activity: pappersData.libelle_code_naf || '',
  };

  // Transformation des données financières avec tri par année
  const financialData: FinancialData[] = (pappersData.finances || [])
    .map((finance: PappersFinanceData) => ({
      year: finance.annee || 0,
      revenue: finance.chiffre_affaires || 0,
      netIncome: finance.resultat || 0,
      equity: finance.fonds_propres || 0,
      employees: typeof finance.effectif === 'string' ? 
        parseInt(finance.effectif.match(/\d+/)?.[0] || '0') : 
        (finance.effectif || 0),
    }))
    .sort((a: FinancialData, b: FinancialData) => b.year - a.year);

  // Transformation des dirigeants avec tri par date de prise de poste
  const executives: Executive[] = (pappersData.representants || [])
    .map((rep: PappersRepresentant) => ({
      name: rep.nom_complet || '',
      role: rep.qualite || '',
      appointmentDate: new Date(rep.date_prise_de_poste || new Date()),
    }))
    .sort((a: Executive, b: Executive) => b.appointmentDate.getTime() - a.appointmentDate.getTime());

  // Transformation des documents
  const documents: Document[] = [
    ...(pappersData.comptes || []).map((doc: PappersDocument) => ({
      type: 'financial',
      name: doc.nom_fichier_pdf || `Comptes annuels ${doc.annee_cloture || ''}`,
      date: doc.date_depot ? new Date(doc.date_depot) : null,
      url: '',
      category: 'financial' as const,
      token: doc.token || '',
      description: doc.date_cloture ? 
        `Comptes annuels - Exercice clos le ${new Date(doc.date_cloture).toLocaleDateString('fr-FR')}` : 
        'Comptes annuels',
    })),
    ...(pappersData.depots_actes || []).map((doc: PappersDocument) => ({
      type: 'legal',
      name: doc.nom_fichier_pdf || 'Acte',
      date: doc.date_depot ? new Date(doc.date_depot) : null,
      url: '',
      category: 'legal' as const,
      token: doc.token || '',
      description: doc.actes?.map(acte => acte.decision).filter(Boolean).join(', ') || 'Acte juridique',
    })),
  ].sort((a: Document, b: Document) => (b.date?.getTime() || 0) - (a.date?.getTime() || 0));

  const transformedData = {
    companyInfo,
    financialData,
    executives,
    documents,
  };

  console.log('Données transformées:', transformedData);
  return transformedData;
};

const ReportViewer = ({ siren, type, filters, isLoading }: ReportViewerProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ReportData | null>(null);
  const [selectedTab, setSelectedTab] = useState<'overview' | 'financial' | 'executives' | 'documents'>('overview');
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareEmail, setShareEmail] = useState('');
  const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

  useEffect(() => {
    if (siren) {
      loadCompanyData();
    }
  }, [siren]);

  const loadCompanyData = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Chargement des données pour le SIREN:', siren);
      const rawCompanyData = await fetchCompanyData(siren);
      console.log('Données reçues de l\'API:', rawCompanyData);
      const transformedData = transformPappersData(rawCompanyData);
      setData(transformedData);
    } catch (error: any) {
      console.error('Erreur lors du chargement des données:', error);
      setError(error.message || 'Erreur lors du chargement des données');
    } finally {
      setLoading(false);
    }
  };

  const handleShareClick = () => {
    setShareDialogOpen(true);
  };

  const getFullUrl = (url: string): string => {
    try {
      // Si c'est déjà une URL valide, la retourner
      new URL(url);
      return url;
    } catch {
      // Sinon, construire une URL valide
      const baseUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000';
      const cleanPath = url.startsWith('/') ? url : `/${url}`;
      return `${baseUrl}${cleanPath}`;
    }
  };

  const handleCopyLink = async (document: Document) => {
    try {
      if (!document.url) {
        throw new Error('URL du document non disponible');
      }

      // Valider l'URL avant de la copier
      const fullUrl = getFullUrl(document.url);
      try {
        new URL(fullUrl);
      } catch {
        throw new Error('URL du document invalide');
      }

      await navigator.clipboard.writeText(fullUrl);
      setNotification({
        message: 'Lien copié dans le presse-papiers',
        type: 'success'
      });
    } catch (error: any) {
      console.error('Erreur de copie:', error);
      setNotification({
        message: error.message || 'Erreur lors de la copie du lien',
        type: 'error'
      });
    }
  };

  const handleDownload = async (document: Document) => {
    try {
      if (!document.url) {
        throw new Error('URL du document non disponible');
      }

      setIsDownloading(true);
      const blob = await downloadDocument(document.url);
      
      // Créer un URL temporaire pour le blob
      const url = window.URL.createObjectURL(blob);
      
      // Créer un lien temporaire et déclencher le téléchargement
      const link = globalThis.document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${document.type || 'document'}.pdf`);
      globalThis.document.body.appendChild(link);
      link.click();
      
      // Nettoyer
      globalThis.document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      setNotification({
        message: 'Document téléchargé avec succès',
        type: 'success'
      });
    } catch (error: any) {
      console.error('Erreur lors du téléchargement:', error);
      setNotification({
        message: error.message || 'Erreur lors du téléchargement du document',
        type: 'error'
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleShare = async () => {
    if (!selectedDocument || !shareEmail) {
      setNotification({
        message: 'Veuillez sélectionner un document et saisir une adresse email',
        type: 'error'
      });
      return;
    }

    try {
      const result = await documentService.shareDocument(selectedDocument.url, shareEmail, {
        expiresIn: 24, // 24 heures
        allowDownload: true,
        allowReshare: false
      });

      if (result.success) {
        setNotification({
          message: 'Document partagé avec succès',
          type: 'success'
        });
        setShareDialogOpen(false);
        setShareEmail('');
      } else {
        throw new Error(result.error);
      }
    } catch (error: any) {
      setNotification({
        message: error.message || 'Erreur lors du partage du document',
        type: 'error'
      });
    }
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      if (data?.companyInfo?.siren) {
        const refreshedData = await fetchCompanyData(data.companyInfo.siren, true);
        setData(refreshedData);
        setNotification({
          message: 'Données rafraîchies avec succès',
          type: 'success'
        });
      }
    } catch (error: any) {
      console.error('Erreur lors du rafraîchissement des données:', error);
      setNotification({
        message: error.message || 'Erreur lors du rafraîchissement des données',
        type: 'error'
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  const calculateGrowth = (current: number, previous: number): number => {
    if (!previous) return 0;
    return ((current - previous) / previous) * 100;
  };

  const getGrowthIcon = (growth: number) => {
    if (growth > 0) return <TrendingUpIcon color="success" />;
    if (growth < 0) return <TrendingDownIcon color="error" />;
    return <TrendingFlatIcon color="action" />;
  };

  const formatGraphValue = (value: number): string => {
    return new Intl.NumberFormat('fr-FR', {
      notation: 'compact',
      maximumFractionDigits: 0,
      compactDisplay: 'short'
    }).format(value / 1000) + 'k';
  };

  const CompanyOverview = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: 'primary.main' }}>
                <BusinessIcon />
              </Avatar>
            }
            title={data?.companyInfo.name}
            subheader={`SIREN: ${data?.companyInfo.siren}`}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Forme juridique
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.companyInfo.legalForm}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Date de création
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.companyInfo.creationDate.toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary">
                  Adresse
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.companyInfo.address}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary">
                  Activité
                </Typography>
                <Typography variant="body1">
                  {data?.companyInfo.activity}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const FinancialOverview = () => {
    const sortedData = [...(data?.financialData || [])].sort((a, b) => a.year - b.year);
    const chartData = sortedData.map(yearData => ({
      year: yearData.year,
      revenue: yearData.revenue,
      netIncome: yearData.netIncome,
      equity: yearData.equity,
      employees: yearData.employees
    }));

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                  <TimelineIcon />
                </Avatar>
              }
              title="Données Financières"
              subheader="Évolution des indicateurs clés"
            />
            <CardContent>
              <Box sx={{ mb: 4, height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis 
                      yAxisId="left" 
                      tickFormatter={formatGraphValue}
                    />
                    <YAxis 
                      yAxisId="right" 
                      orientation="right"
                      tickFormatter={(value: number) => value.toString()}
                    />
                    <RechartsTooltip 
                      formatter={(value: number, name: string) => {
                        if (name === 'Effectif') return [value.toString(), name];
                        return [formatCurrency(value), name];
                      }}
                    />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="revenue" name="CA" stroke="#2196f3" />
                    <Line yAxisId="left" type="monotone" dataKey="netIncome" name="Résultat" stroke="#4caf50" />
                    <Line yAxisId="left" type="monotone" dataKey="equity" name="Capitaux" stroke="#ff9800" />
                    <Line yAxisId="right" type="monotone" dataKey="employees" name="Effectif" stroke="#9c27b0" />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
              
              {sortedData.map((yearData, index) => {
                const previousYear = index > 0 ? sortedData[index - 1] : null;
                const revenueGrowth = previousYear ? calculateGrowth(yearData.revenue, previousYear.revenue) : 0;
                const netIncomeGrowth = previousYear ? calculateGrowth(yearData.netIncome, previousYear.netIncome) : 0;
                const equityGrowth = previousYear ? calculateGrowth(yearData.equity, previousYear.equity) : 0;
                const employeesGrowth = previousYear ? calculateGrowth(yearData.employees, previousYear.employees) : 0;

                return (
                  <Fade key={yearData.year} in timeout={300} style={{ transitionDelay: `${index * 100}ms` }}>
                    <Box mb={2}>
                      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {yearData.year}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <Paper sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Chiffre d'affaires
                              </Typography>
                              {previousYear && (
                                <Tooltip title={`${revenueGrowth.toFixed(1)}% vs ${previousYear.year}`}>
                                  {getGrowthIcon(revenueGrowth)}
                                </Tooltip>
                              )}
                            </Box>
                            <Typography variant="h6">
                              {formatCurrency(yearData.revenue)}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Paper sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Résultat net
                              </Typography>
                              {previousYear && (
                                <Tooltip title={`${netIncomeGrowth.toFixed(1)}% vs ${previousYear.year}`}>
                                  {getGrowthIcon(netIncomeGrowth)}
                                </Tooltip>
                              )}
                            </Box>
                            <Typography variant="h6" color={yearData.netIncome >= 0 ? 'success.main' : 'error.main'}>
                              {formatCurrency(yearData.netIncome)}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Paper sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Capitaux propres
                              </Typography>
                              {previousYear && (
                                <Tooltip title={`${equityGrowth.toFixed(1)}% vs ${previousYear.year}`}>
                                  {getGrowthIcon(equityGrowth)}
                                </Tooltip>
                              )}
                            </Box>
                            <Typography variant="h6">
                              {formatCurrency(yearData.equity)}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Paper sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Effectif
                              </Typography>
                              {previousYear && (
                                <Tooltip title={`${employeesGrowth.toFixed(1)}% vs ${previousYear.year}`}>
                                  {getGrowthIcon(employeesGrowth)}
                                </Tooltip>
                              )}
                            </Box>
                            <Typography variant="h6">
                              {yearData.employees}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                      {index < sortedData.length - 1 && <Divider sx={{ my: 2 }} />}
                    </Box>
                  </Fade>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const ExecutivesOverview = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: 'primary.main' }}>
                <GroupIcon />
              </Avatar>
            }
            title="Dirigeants"
            subheader="Équipe de direction"
          />
          <CardContent>
            <Grid container spacing={2}>
              {data?.executives.map((executive, index) => (
                <Grid item xs={12} key={index}>
                  <Paper sx={{ p: 2 }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="subtitle1">
                          {executive.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {executive.role}
                        </Typography>
                      </Box>
                      <Typography variant="caption" color="text.secondary">
                        Nommé le {executive.appointmentDate.toLocaleDateString()}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const DocumentsOverview = () => {
    const getDocumentStats = (document: Document) => {
      try {
        // Use the token if available, otherwise try to extract from URL
        const url = document.token ? `${import.meta.env.VITE_PAPPERS_API_URL}/document/${document.token}` : document.url;
        return documentService.getDocumentStats(url);
      } catch (error) {
        console.warn('Impossible de récupérer les statistiques du document:', error);
        return { downloads: 0, shares: 0 };
      }
    };

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                  <ArticleIcon />
                </Avatar>
              }
              title="Documents"
              subheader="Documents légaux et financiers"
              action={
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Tooltip title="Vue en grille">
                    <IconButton 
                      onClick={() => setViewMode('grid')}
                      color={viewMode === 'grid' ? 'primary' : 'default'}
                    >
                      <GridViewIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Vue en liste">
                    <IconButton 
                      onClick={() => setViewMode('list')}
                      color={viewMode === 'list' ? 'primary' : 'default'}
                    >
                      <ViewListIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                {data?.documents.map((document, index) => (
                  <Grid item xs={12} md={viewMode === 'grid' ? 4 : 12} key={index}>
                    <Paper 
                      sx={{ 
                        p: 2,
                        display: 'flex',
                        flexDirection: viewMode === 'grid' ? 'column' : 'row',
                        gap: 2,
                        alignItems: viewMode === 'grid' ? 'stretch' : 'center',
                        justifyContent: viewMode === 'grid' ? 'space-between' : 'flex-start'
                      }}
                    >
                      <Box sx={{ 
                        flex: viewMode === 'list' ? 1 : 'auto',
                        minWidth: 0
                      }}>
                        <Typography variant="subtitle1" noWrap>
                          {document.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" noWrap>
                          {document.description}
                        </Typography>
                        {document.date && (
                          <Typography variant="caption" color="text.secondary" display="block">
                            {document.date.toLocaleDateString('fr-FR')}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={{ 
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap',
                        justifyContent: viewMode === 'grid' ? 'center' : 'flex-end',
                        alignItems: 'center',
                        mt: viewMode === 'grid' ? 2 : 0
                      }}>
                        <Tooltip title="Télécharger">
                          <IconButton
                            size="small"
                            onClick={() => handleDownload(document)}
                            color="primary"
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Partager">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSelectedDocument(document);
                              setShareDialogOpen(true);
                            }}
                            color="primary"
                          >
                            <ShareIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Copier le lien">
                          <IconButton
                            size="small"
                            onClick={() => handleCopyLink(document)}
                            color="primary"
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const ShareDialog = () => (
    <Dialog
      open={shareDialogOpen}
      onClose={() => setShareDialogOpen(false)}
      aria-labelledby="share-dialog-title"
    >
      <DialogTitle id="share-dialog-title">Partager le document</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Adresse email"
          type="email"
          fullWidth
          variant="outlined"
          value={shareEmail}
          onChange={(e) => setShareEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShareDialogOpen(false)}>Annuler</Button>
        <Button onClick={handleShare} variant="contained">Partager</Button>
      </DialogActions>
    </Dialog>
  );

  if (loading || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Paper sx={{ mb: 3 }} elevation={0}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root': {
                minWidth: 'auto',
                px: 3,
                py: 2,
              },
            }}
          >
            <Tab 
              label="Vue d'ensemble"
              value="overview"
              icon={<BusinessIcon />}
              iconPosition="start"
            />
            <Tab 
              label="Finances"
              value="financial"
              icon={<TimelineIcon />}
              iconPosition="start"
            />
            <Tab 
              label="Dirigeants"
              value="executives"
              icon={<GroupIcon />}
              iconPosition="start"
            />
            <Tab 
              label="Documents"
              value="documents"
              icon={<DescriptionIcon />}
              iconPosition="start"
            />
          </Tabs>
          <IconButton 
            onClick={handleRefresh} 
            disabled={isRefreshing}
            sx={{ mr: 2 }}
            title="Rafraîchir les données"
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Paper>

      <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
        {selectedTab === 'overview' && <CompanyOverview />}
        {selectedTab === 'financial' && <FinancialOverview />}
        {selectedTab === 'executives' && <ExecutivesOverview />}
        {selectedTab === 'documents' && <DocumentsOverview />}
      </Box>

      <ShareDialog />

      {notification && (
        <Snackbar
          open={!!notification}
          autoHideDuration={6000}
          onClose={() => setNotification(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setNotification(null)}
            severity={notification.type}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ReportViewer;
