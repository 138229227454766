import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Logo } from '../../assets';


const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: t('landing.nav.features', 'Features'), path: '#features' },
    { label: t('landing.nav.pricing', 'Pricing'), path: '#pricing' },
  ];

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{
        background: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ justifyContent: 'space-between', px: { xs: 0, sm: 2 } }}>
          <Box
            component={Logo as React.ElementType}
            alt={t('common.appName', 'Freelance Compliance Platform')}
            sx={{
              height: 52,
              cursor: 'pointer',
              marginLeft: isMobile ? 16 : 0,
            }}
            onClick={() => navigate('/')}
          />

          {isMobile ? (
            <>
              <IconButton
                edge="end"
                color="primary"
                aria-label={t('common.menu', 'Menu')}
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {menuItems.map((item) => (
                  <MenuItem
                    key={item.label}
                    onClick={() => {
                      handleClose();
                      const element = document.querySelector(item.path);
                      element?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem onClick={() => {
                  handleClose();
                  navigate('/login');
                }}>
                  {t('auth.login.title', 'Sign In')}
                </MenuItem>
                <MenuItem onClick={() => {
                  handleClose();
                  navigate('/register');
                }}>
                  {t('auth.register.title', 'Sign Up')}
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {menuItems.map((item) => (
                <Button
                  key={item.label}
                  color="primary"
                  onClick={() => {
                    const element = document.querySelector(item.path);
                    element?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  {item.label}
                </Button>
              ))}
              <Button
                color="primary"
                variant="outlined"
                onClick={() => navigate('/login')}
                sx={{ ml: 2 }}
              >
                {t('auth.login.title', 'Sign In')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate('/register')}
              >
                {t('auth.register.title', 'Sign Up')}
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;