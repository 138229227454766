import { create } from 'zustand';
import { auth, googleProvider, githubProvider } from '../config/firebase';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
  setPersistence,
  browserLocalPersistence,
  signInWithPopup,
  AuthProvider,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { AuthState, LoginCredentials, RegisterData, SocialAuthProvider, PasswordChangeData } from '../types/auth';
import { checkConnection } from '../config/firebase';
import { createDefaultProfile } from '../services/profile';

const handleAuthError = (error: FirebaseError) => {
  console.error('Auth error:', error);

  if (!checkConnection()) {
    throw new Error('No internet connection. Please check your network and try again.');
  }

  switch (error.code) {
    case 'auth/email-already-in-use':
      throw new Error('This email is already registered');
    case 'auth/invalid-email':
      throw new Error('Invalid email address');
    case 'auth/operation-not-allowed':
      throw new Error('Email/password accounts are not enabled');
    case 'auth/weak-password':
      throw new Error('Password should be at least 6 characters');
    case 'auth/user-disabled':
      throw new Error('This account has been disabled');
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      throw new Error('Invalid email or password');
    case 'auth/network-request-failed':
      throw new Error('Network error. Please check your connection and try again');
    case 'auth/too-many-requests':
      throw new Error('Too many attempts. Please try again later');
    case 'auth/internal-error':
      throw new Error('An internal error occurred. Please try again');
    case 'auth/account-exists-with-different-credential':
      throw new Error('An account already exists with the same email address but different sign-in credentials');
    case 'auth/popup-blocked':
      throw new Error('Sign-in popup was blocked by your browser');
    case 'auth/popup-closed-by-user':
      throw new Error('Sign-in popup was closed before completing the sign-in');
    default:
      throw new Error('Authentication failed. Please try again');
  }
};

const getProviderInstance = (provider: SocialAuthProvider): AuthProvider => {
  switch (provider) {
    case 'google':
      return googleProvider;
    case 'github':
      return githubProvider;
    default:
      throw new Error('Unsupported provider');
  }
};

// Initialize Firebase Auth persistence
await setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('Error setting persistence:', error);
});

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  token: null,
  isAuthenticated: false,
  isInitialized: false,

  initAuth: () => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(true);
          const currentUser = auth.currentUser;
          set({
            user: {
              id: user.uid,
              email: user.email || '',
              firstName: currentUser?.displayName?.split(' ')[0] || 'User',
              lastName: currentUser?.displayName?.split(' ')[1] || '',
              role: 'freelancer',
            },
            token,
            isAuthenticated: true,
            isInitialized: true,
          });
        } catch (error) {
          console.error('Error refreshing token:', error);
          set({
            user: null,
            token: null,
            isAuthenticated: false,
            isInitialized: true,
          });
        }
      } else {
        set({
          user: null,
          token: null,
          isAuthenticated: false,
          isInitialized: true,
        });
      }
    });

    return () => unsubscribe();
  },

  login: async (credentials: LoginCredentials) => {
    if (!checkConnection()) {
      throw new Error('No internet connection. Please check your network and try again.');
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );

      const token = await userCredential.user.getIdToken();

      set({
        user: {
          id: userCredential.user.uid,
          email: userCredential.user.email || '',
          firstName: userCredential.user.displayName?.split(' ')[0] || 'User',
          lastName: userCredential.user.displayName?.split(' ')[1] || '',
          role: 'freelancer',
        },
        token,
        isAuthenticated: true,
      });
    } catch (error) {
      handleAuthError(error as FirebaseError);
    }
  },

  socialLogin: async (provider: SocialAuthProvider) => {
    if (!checkConnection()) {
      throw new Error('No internet connection. Please check your network and try again.');
    }

    try {
      const providerInstance = getProviderInstance(provider);
      const result = await signInWithPopup(auth, providerInstance);
      const token = await result.user.getIdToken();
      const names = result.user.displayName?.split(' ') || ['User', ''];

      const userData = {
        id: result.user.uid,
        email: result.user.email || '',
        firstName: names[0],
        lastName: names.slice(1).join(' '),
        role: 'freelancer' as const,
      };

      // Create profile for social login
      await createDefaultProfile(result.user.uid, {
        firstName: names[0],
        lastName: names.slice(1).join(' '),
        email: result.user.email || '',
      });

      set({
        user: userData,
        token,
        isAuthenticated: true,
      });
    } catch (error) {
      handleAuthError(error as FirebaseError);
    }
  },

  register: async (data: RegisterData) => {
    if (!checkConnection()) {
      throw new Error('No internet connection. Please check your network and try again.');
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      if (auth.currentUser) {
        await updateProfile(auth.currentUser, {
          displayName: `${data.firstName} ${data.lastName}`,
        });
      }

      const token = await userCredential.user.getIdToken();

      // Create profile for new user
      await createDefaultProfile(userCredential.user.uid, {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      });

      set({
        user: {
          id: userCredential.user.uid,
          email: userCredential.user.email || '',
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
        },
        token,
        isAuthenticated: true,
      });
    } catch (error) {
      handleAuthError(error as FirebaseError);
    }
  },

  changePassword: async (data: PasswordChangeData) => {
    try {
      const user = auth.currentUser;
      if (!user || !user.email) {
        throw new Error('User not authenticated');
      }

      // First, re-authenticate the user
      const credential = EmailAuthProvider.credential(user.email, data.currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Then update the password
      await updatePassword(user, data.newPassword);
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleAuthError(error);
      }
      throw error;
    }
  },

  resetPassword: async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            throw new Error('User not found');
          case 'auth/too-many-requests':
            throw new Error('Too many attempts');
          default:
            throw new Error('Reset failed');
        }
      }
      throw error;
    }
  },

  logout: async () => {
    try {
      await signOut(auth);
      set({ user: null, token: null, isAuthenticated: false });
    } catch (error) {
      handleAuthError(error as FirebaseError);
    }
  },
}));