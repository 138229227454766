import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: "complydoc.fr",
  projectId: "complydoc-65992",
  storageBucket: "complydoc-65992.firebasestorage.app",
  messagingSenderId: "508250876005",
  appId: "1:508250876005:web:a41f0273a1fe540d95be8c",
  measurementId: "G-MK9DH041E4"
};

export const initFirebase = () => {
  try {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const storage = getStorage(app);
    const functions = getFunctions(app);
    
    return { app, auth, db, storage, functions };
  } catch (error) {
    console.error('Auth project not found', error);
    throw error;
  }
};

export const { app, auth, db, storage, functions } = initFirebase();

// Initialize providers
export const googleProvider = new GoogleAuthProvider();
export const githubProvider = new GithubAuthProvider();

// Connection check utility
export const checkConnection = () => navigator.onLine;