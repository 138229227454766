import { addDays, differenceInDays, isAfter, isBefore, endOfYear } from 'date-fns';
import { Document, DocumentCategory } from '../types';
import { emailService } from './emailService';

interface NotificationSchedule {
  days: number;
  type: 'warning' | 'critical';
}

const DEFAULT_NOTIFICATION_SCHEDULE: NotificationSchedule[] = [
  { days: 30, type: 'warning' },
  { days: 14, type: 'warning' },
  { days: 7, type: 'critical' },
  { days: 0, type: 'critical' },
];

interface NotificationPreferences {
  schedule: NotificationSchedule[];
  emailNotifications: boolean;
  inAppNotifications: boolean;
}

export const checkDocumentExpirations = async (
  documents: Document[],
  userEmail: string,
  userLanguage: 'fr' | 'en' = 'fr',
  preferences: NotificationPreferences = {
    schedule: DEFAULT_NOTIFICATION_SCHEDULE,
    emailNotifications: true,
    inAppNotifications: true
  }
): Promise<void> => {
  try {
    const now = new Date();
    const notifications: Array<{
      document: Document;
      daysUntilExpiry: number;
      type: 'warning' | 'critical';
    }> = [];

    // Vérifier chaque document
    documents.forEach(doc => {
      if (!doc.expiryDate) return;

      const expiryDate = new Date(doc.expiryDate);
      
      // Vérifier si le document expire à la fin de l'année
      if (doc.category === 'fiscal' || doc.category === 'agefiph') {
        const currentYearEnd = endOfYear(now);
        if (expiryDate > currentYearEnd) return;
      }

      const daysUntilExpiry = differenceInDays(expiryDate, now);

      // Trouver la notification appropriée selon le calendrier
      preferences.schedule.forEach(schedule => {
        if (daysUntilExpiry <= schedule.days && daysUntilExpiry > (schedule.days - 1)) {
          notifications.push({
            document: doc,
            daysUntilExpiry,
            type: schedule.type
          });
        }
      });

      // Notification le jour de l'expiration
      if (daysUntilExpiry === 0) {
        notifications.push({
          document: doc,
          daysUntilExpiry: 0,
          type: 'critical'
        });
      }
    });

    if (notifications.length === 0) return;

    // Préparer et envoyer les notifications
    const warningNotifications = notifications.filter(n => n.type === 'warning');
    const criticalNotifications = notifications.filter(n => n.type === 'critical');

    if (preferences.emailNotifications) {
      await emailService.sendDocumentExpirationEmail(
        userEmail,
        {
          warningDocuments: warningNotifications
            .filter(n => n.document.expiryDate) // Filtrer les documents sans date d'expiration
            .map(n => ({
              title: n.document.title,
              category: n.document.category,
              expiryDate: n.document.expiryDate as string, // Assertion de type car on a déjà filtré
              daysUntilExpiry: n.daysUntilExpiry
            })),
          criticalDocuments: criticalNotifications
            .filter(n => n.document.expiryDate) // Filtrer les documents sans date d'expiration
            .map(n => ({
              title: n.document.title,
              category: n.document.category,
              expiryDate: n.document.expiryDate as string, // Assertion de type car on a déjà filtré
              daysUntilExpiry: n.daysUntilExpiry
            }))
        },
        userLanguage
      );
    }

    if (preferences.inAppNotifications) {
      // Implémenter les notifications in-app ici
      // Cette partie sera implémentée avec le système de notification in-app
    }

  } catch (error) {
    console.error('Error checking document expirations:', error);
    throw new Error('Failed to check document expirations');
  }
};

export const scheduleAutomaticSharing = async (
  document: Document,
  clientIds: string[],
  shareDate: Date
): Promise<void> => {
  try {
    // Implémenter la logique de partage automatique
    // Cette fonction sera appelée par un job CRON
    
  } catch (error) {
    console.error('Error scheduling automatic sharing:', error);
    throw new Error('Failed to schedule automatic sharing');
  }
};