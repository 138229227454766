import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Button,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Divider,
  FormGroup,
  Alert,
  Paper,
  IconButton,
  Stack,
  Chip,
} from '@mui/material';
import { Document, Client } from '../types';
import {
  Description as DocumentIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Close as CloseIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

interface ShareDocumentsDrawerProps {
  open: boolean;
  onClose: () => void;
  clients: Client[];
  documents: Document[];
  onShare: (clientIds: string[], documentIds: string[]) => Promise<void>;
}

const getDocumentStatus = (document: Document) => {
  const expiryDate = document.expiryDate ? new Date(document.expiryDate) : null;
  const today = new Date();

  if (!expiryDate) {
    return { status: 'valid', icon: CheckCircleIcon, color: 'success' as const };
  }

  if (expiryDate < today) {
    return { status: 'expired', icon: ErrorIcon, color: 'error' as const };
  }

  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(today.getDate() + 30);

  if (expiryDate <= thirtyDaysFromNow) {
    return { status: 'warning', icon: WarningIcon, color: 'warning' as const };
  }

  return { status: 'valid', icon: CheckCircleIcon, color: 'success' as const };
};

const ShareDocumentsDrawer: React.FC<ShareDocumentsDrawerProps> = ({
  open,
  onClose,
  clients,
  documents,
  onShare,
}) => {
  const { t } = useTranslation();
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [shareAll, setShareAll] = useState(false);
  const [legalAccepted, setLegalAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sharedDocuments, setSharedDocuments] = useState<string[]>([]);

  useEffect(() => {
    if (open && clients.length > 0 && documents) {
      const sharedIds = documents.filter(doc => {
        // Vérifier si le document est partagé avec le client actuel
        const isSharedDirectly = doc.sharedWithClientIds?.includes(clients[0].id);
        const isSharedViaToken = doc.shares?.some(share => share.clientId === clients[0].id);
        return isSharedDirectly || isSharedViaToken;
      }).map(doc => doc.id);
      
      setSharedDocuments(sharedIds);
      setSelectedDocuments(sharedIds);
      setShareAll(sharedIds.length === documents.length);
    }
  }, [open, clients, documents]);

  useEffect(() => {
    if (!open) {
      setError(null);
      setLegalAccepted(false);
    }
  }, [open]);

  const handleToggleDocument = (documentId: string) => {
    setSelectedDocuments(prev => {
      const newSelection = prev.includes(documentId)
        ? prev.filter(id => id !== documentId)
        : [...prev, documentId];
      return newSelection;
    });
  };

  const handleToggleAll = () => {
    const newShareAll = !shareAll;
    setShareAll(newShareAll);
    const newSelection = newShareAll 
      ? documents.map(doc => doc.id)
      : [...sharedDocuments];
    setSelectedDocuments(newSelection);
  };

  const handleShare = async () => {
    if (!legalAccepted) {
      setError("Veuillez accepter la mention légale avant de partager les documents");
      return;
    }

    if (selectedDocuments.length === 0) {
      setError("Veuillez sélectionner au moins un document à partager");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const newDocuments = selectedDocuments.filter(id => !sharedDocuments.includes(id));
      
      if (newDocuments.length > 0) {
        await onShare(clients.map(client => client.id), newDocuments);
        const updatedSharedDocs = [...new Set([...sharedDocuments, ...newDocuments])];
        setSharedDocuments(updatedSharedDocs);
        setSelectedDocuments(updatedSharedDocs);
      }
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : "Une erreur s'est produite lors du partage");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedDocuments([...sharedDocuments]);
    setShareAll(false);
    setError(null);
    setLegalAccepted(false);
    onClose();
  };

  if (clients.length === 0) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      keepMounted={true}
      PaperProps={{
        sx: { 
          width: { xs: '100%', sm: 600 },
          paddingTop: '64px',
        },
        role: 'dialog',
        'aria-modal': true,
        'aria-labelledby': 'share-documents-title'
      }}
      SlideProps={{
        tabIndex: -1
      }}
    >
      <Box 
        sx={{ p: 3 }}
        role="form"
        aria-label="Partager des documents"
      >
        <Box sx={{ mb: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            id="share-documents-title"
            tabIndex={0}
          >
            Partager des documents
          </Typography>
          <Box 
            sx={{ 
              mt: 1,
              p: 2,
              bgcolor: 'primary.main',
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Typography variant="subtitle1" color="primary.contrastText" fontWeight="bold">
                {clients.map(client => client.companyName).join(', ')}
              </Typography>
              {clients.map(client => client.siret).join(', ') && (
                <Typography variant="caption" color="primary.contrastText" sx={{ opacity: 0.8 }}>
                  SIRET : {clients.map(client => client.siret).join(', ')}
                </Typography>
              )}
            </Box>
            <IconButton 
              size="small" 
              onClick={handleClose}
              sx={{ 
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.dark'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shareAll}
                onChange={handleToggleAll}
                color="primary"
              />
            }
            label="Tous les documents"
          />
        </Box>

        <Paper variant="outlined" sx={{ flex: 1, overflow: 'hidden', mb: 3 }}>
          <List sx={{ height: '100%', overflow: 'auto' }}>
            {documents.map((document, index) => {
              const status = getDocumentStatus(document);
              const StatusIcon = status.icon;
              const isLastItem = index === documents.length - 1;

              return (
                <React.Fragment key={document.id}>
                  <ListItem
                    dense
                    sx={{
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                      py: 1,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <DocumentIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography component="span" variant="body1">{document.title}</Typography>
                          <StatusIcon color={status.color} sx={{ fontSize: 20 }} />
                        </Box>
                      }
                      secondary={
                        <Box component="span" sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography component="span" variant="body2" color="text.secondary">
                            {document.category}
                          </Typography>
                          {document.expiryDate && (
                            <Typography component="span" variant="caption" color="text.secondary">
                              Expire le {format(new Date(document.expiryDate), 'dd MMMM yyyy', { locale: fr })}
                            </Typography>
                          )}
                        </Box>
                      }
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                      <Checkbox
                        edge="end"
                        checked={selectedDocuments.includes(document.id)}
                        onChange={() => handleToggleDocument(document.id)}
                        tabIndex={-1}
                      />
                    </Box>
                  </ListItem>
                  {!isLastItem && <Divider component="li" variant="middle" />}
                </React.Fragment>
              );
            })}
          </List>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default', mb: 3 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={legalAccepted}
                  onChange={(e) => setLegalAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  Je souhaite partager des documents déposés sur ComplyDoc à {clients.map(client => client.companyName).join(', ')}
                </Typography>
              }
            />
          </FormGroup>
        </Paper>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={handleClose}>
            Annuler
          </Button>
          <Button
            onClick={handleShare}
            variant="contained"
            disabled={!legalAccepted || selectedDocuments.length === 0 || loading}
          >
            {loading ? 'Partage en cours...' : 'Partager'}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ShareDocumentsDrawer;
