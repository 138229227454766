import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  useTheme,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useColorMode } from '../hooks/useColorMode';
import { Logo } from '../assets';

const Header = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { toggleColorMode } = useColorMode();
  const location = useLocation();

  const isAuthPage = ['/login', '/register', '/forgot-password'].includes(location.pathname);

  return (
    <AppBar 
      position="sticky" 
      elevation={0}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box 
              component={Logo as React.ElementType} 
              sx={{ 
                width: 180, 
                height: 'auto',
                display: 'block',
                padding: 0,
                margin: 0
              }} 
            />
          </Link>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton onClick={toggleColorMode} color="inherit" size="small">
              {theme.palette.mode === 'dark' ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>

            {!isAuthPage && (
              <>
                <Button
                  component={Link}
                  to="/login"
                  color="inherit"
                  sx={{ textTransform: 'none' }}
                >
                  {t('auth.header.login', 'Login')}
                </Button>
                <Button
                  component={Link}
                  to="/register"
                  variant="contained"
                  sx={{ textTransform: 'none' }}
                >
                  {t('auth.header.register', 'Register')}
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
