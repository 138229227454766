import React, { useState } from 'react';
import {
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Box,
  Divider,
  Chip,
  Tooltip,
  Button,
  Stack,
} from '@mui/material';
import {
  CheckCircle as ValidIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Close as CloseIcon,
  FolderShared as FolderSharedIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { Document } from '../types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface SharedDocumentsBadgeProps {
  documents: Document[];
  onClick?: () => void;
  onEdit?: (document: Document) => void;
  onView?: (document: Document) => void;
}

const getDocumentStatus = (document: Document) => {
  if (!document.expiryDate) return 'valid';
  
  const expiryDate = new Date(document.expiryDate);
  const today = new Date();
  
  if (document.status === 'expired' || expiryDate < today) {
    return 'expired';
  }
  
  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(today.getDate() + 30);
  
  if (expiryDate <= thirtyDaysFromNow) {
    return 'warning';
  }
  
  return 'valid';
};

const getStatusText = (doc: Document) => {
  if (!doc.expiryDate) return 'Valide';
  const expiryDate = new Date(doc.expiryDate);
  const today = new Date();
  
  if (expiryDate < today) {
    return `Non conforme depuis ${format(expiryDate, 'dd/MM/yyyy', { locale: fr })}`;
  }
  
  return `Valide jusqu'au ${format(expiryDate, 'dd/MM/yyyy', { locale: fr })}`;
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'valid':
      return 'success';
    case 'warning':
      return 'warning';
    case 'expired':
      return 'error';
    default:
      return 'default';
  }
};

export const SharedDocumentsBadge: React.FC<SharedDocumentsBadgeProps> = ({
  documents,
  onClick,
  onEdit,
  onView,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  if (!documents || documents.length === 0) {
    return null;
  }

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDrawerOpen(true);
    if (onClick) onClick();
  };

  const handleEdit = (event: React.MouseEvent, doc: Document) => {
    event.stopPropagation();
    if (onEdit) onEdit(doc);
  };

  const handleView = (event: React.MouseEvent, doc: Document) => {
    event.stopPropagation();
    if (onView) onView(doc);
  };

  return (
    <>
      <Tooltip title={`${documents.length} document${documents.length > 1 ? 's' : ''} partagé${documents.length > 1 ? 's' : ''}`}>
        <Chip
          icon={<FolderSharedIcon />}
          label={documents.length}
          onClick={handleClick}
          sx={{ ml: 1 }}
        />
      </Tooltip>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { 
            width: '500px',
            boxSizing: 'border-box',
            paddingTop: '64px', // Hauteur du header
          }
        }}
      >
        <Box 
          sx={{ 
            p: 2,
            height: '100%',
            overflow: 'auto'
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h6">
              {documents.length} document{documents.length > 1 ? 's' : ''} commun{documents.length > 1 ? 's' : ''}
            </Typography>
            <IconButton
              onClick={() => setDrawerOpen(false)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <List sx={{ width: '100%' }}>
            {documents.map((doc, index) => (
              <React.Fragment key={doc.id}>
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    bgcolor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
                    py: 2,
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    width: '100%',
                    mb: 1
                  }}>
                    <Typography variant="subtitle1">
                      {doc.title}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Button
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={(e) => handleEdit(e, doc)}
                      >
                        Éditer
                      </Button>
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon />}
                        onClick={(e) => handleView(e, doc)}
                      >
                        Consulter
                      </Button>
                    </Stack>
                  </Box>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <Typography variant="body2" color="text.secondary">
                      Statut
                    </Typography>
                    <Chip
                      label={getStatusText(doc)}
                      color={getStatusColor(getDocumentStatus(doc))}
                      size="small"
                      sx={{ borderRadius: 1 }}
                    />
                  </Box>
                </ListItem>
                {index < documents.length - 1 && (
                  <Divider />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default SharedDocumentsBadge;
