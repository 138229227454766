import React from 'react';
import {
  Box,
  Card,
  Typography,
  Chip,
  Button,
  Stack,
  Divider,
} from '@mui/material';
import { CheckCircle, Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface CurrentSubscriptionProps {
  subscription: {
    status: 'active' | 'canceled' | 'past_due' | 'incomplete';
    planName: string;
    currentPeriodEnd: string;
    cancelAtPeriodEnd: boolean;
  };
  onManageSubscription: () => void;
  onCancelSubscription: () => void;
}

export const CurrentSubscription: React.FC<CurrentSubscriptionProps> = ({
  subscription,
  onManageSubscription,
  onCancelSubscription,
}) => {
  const { t } = useTranslation();

  const statusConfig = {
    active: { color: 'success', icon: CheckCircle, label: t('subscription.currentPlan.status.active', 'Actif') },
    canceled: { color: 'error', icon: Warning, label: t('subscription.currentPlan.status.canceled', 'Annuler') },
    past_due: { color: 'warning', icon: Warning, label: t('subscription.currentPlan.status.past_due', 'Expiré') },
    incomplete: { color: 'warning', icon: Warning, label: t('subscription.currentPlan.status.incomplete', 'Incomplet') },
  } as const;

  const { color, icon: Icon, label } = statusConfig[subscription.status];

  return (
    <Card
      elevation={0}
      sx={{
        p: 3,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.default',
      }}
    >
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box>
            <Typography variant="h6" gutterBottom>
              {t('subscription.currentPlan.title', 'Abonnement actuel')}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {subscription.planName}
            </Typography>
            <Chip
              size="small"
              icon={<Icon />}
              label={label}
              color={color}
              sx={{ mt: 1 }}
            />
          </Box>
        </Box>

        <Divider />

        <Box>
          <Typography variant="body2" color="text.secondary">
            {t('subscription.currentPlan.renewalInfo', {
              action: t(subscription.cancelAtPeriodEnd 
                ? 'subscription.currentPlan.renewal.end'
                : 'subscription.currentPlan.renewal.renew' 
              )
            }, )}
          </Typography>
          <Typography variant="body1">
            {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            onClick={onManageSubscription}
            fullWidth
          >
            {t('subscription.actions.manage', 'Gérer l\'abonnement')}
          </Button>
          {!subscription.cancelAtPeriodEnd && subscription.status === 'active' && (
            <Button
              variant="outlined"
              color="error"
              onClick={onCancelSubscription}
              fullWidth
            >
              {t('subscription.actions.cancel', 'Annuler l\'abonnement')}
            </Button>
          )}
        </Box>
      </Stack>
    </Card>
  );
};
