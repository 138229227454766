import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../stores/authStore';
import { CurrentSubscription } from '../../components/subscription/CurrentSubscription';
import { InvoiceHistory } from '../../components/subscription/InvoiceHistory';
import { getStripePortalUrl } from '../../services/stripe';

export default function Subscription() {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [invoices, setInvoices] = useState<any[]>([]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Fetch subscription and invoices data from your backend
        // This is just a placeholder, implement your actual data fetching logic
        const subscriptionData = {
          status: 'active',
          planName: 'Professional Plan',
          currentPeriodEnd: '2024-12-31',
          cancelAtPeriodEnd: false,
        };
        
        const invoicesData = [
          {
            id: '1',
            number: 'INV-001',
            date: '2024-01-01',
            amount: 9900,
            status: 'paid',
            downloadUrl: '#',
          },
          // Add more invoice data here
        ];

        setSubscription(subscriptionData);
        setInvoices(invoicesData);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load subscription data');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchSubscriptionData();
    }
  }, [user]);

  const handleManageSubscription = async () => {
    try {
      const { url } = await getStripePortalUrl();
      window.location.href = url;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to redirect to billing portal');
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await handleManageSubscription();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to cancel subscription');
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              {t('subscription.title')}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t('subscription.description')}
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {subscription && (
            <CurrentSubscription
              subscription={subscription}
              onManageSubscription={handleManageSubscription}
              onCancelSubscription={handleCancelSubscription}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InvoiceHistory invoices={invoices} />
        </Grid>
      </Grid>
    </Container>
  );
}