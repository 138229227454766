import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Skeleton,
} from '@mui/material';
import ReportViewer from './Reports/components/ReportViewer';
import { fetchUserCompanies } from '../services/pappersApi';

interface Company {
  siren: string;
  name: string;
}

const Reports = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedSiren, setSelectedSiren] = useState<string>('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = async () => {
    try {
      const userCompanies = await fetchUserCompanies();
      setCompanies(userCompanies);
      if (userCompanies.length > 0) {
        setSelectedSiren(userCompanies[0].siren);
      }
    } catch (error) {
      console.error('Error loading companies:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <Skeleton variant="text" width={200} height={40} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={400} />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4" sx={{ flex: 1 }}>
          Reports
        </Typography>
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="company-select-label">Société</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={selectedSiren}
            label="Société"
            onChange={(e) => setSelectedSiren(e.target.value)}
          >
            {companies.map((company) => (
              <MenuItem key={company.siren} value={company.siren}>
                {company.name} - {company.siren}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {selectedSiren && <ReportViewer siren={selectedSiren} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reports;