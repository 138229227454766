import { useState, useRef } from 'react';
import {
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
  InputBase,
  Divider,
  Badge,
  Chip,
  TextField,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Search,
  FilterList,
  AttachFile,
  Send,
  MoreVert,
  Label,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useMessageStore } from '../../stores/messageStore';
import { v4 as uuidv4 } from 'uuid';

const getLabelDefaultText = (label: string): string => {
  const labels: Record<string, string> = {
    important: 'Important',
    urgent: 'Urgent',
    document: 'Document',
    client: 'Client',
    archived: 'Archivé',
    draft: 'Brouillon',
  };
  return labels[label] || label;
};

interface Message {
  id: string;
  conversationId: string;
  senderId: string;
  content: string;
  attachments: {
    id: string;
    name: string;
    size: number;
    type: string;
    url: string;
  }[];
  mentions: string[];
  timestamp: Date;
  read: boolean;
}

const Messages = () => {
  const { t } = useTranslation();
  const [messageText, setMessageText] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    conversations,
    messages,
    selectedConversationId,
    setSelectedConversation,
    addMessage,
    addAttachment,
  } = useMessageStore();

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleSendMessage = () => {
    if (selectedConversationId && (messageText.trim())) {
      const newMessage: Omit<Message, 'id' | 'timestamp'> = {
        conversationId: selectedConversationId,
        content: messageText.trim(),
        senderId: 'currentUser',
        attachments: [],
        mentions: [],
        read: true,
      };

      addMessage(newMessage);
      setMessageText('');
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0 && selectedConversationId) {
      const file = files[0];
      // Dans un cas réel, vous devriez télécharger le fichier vers votre serveur
      const attachment = {
        id: uuidv4(),
        name: file.name,
        type: file.type,
        url: URL.createObjectURL(file),
        size: file.size,
      };
      
      const newMessage: Omit<Message, 'id' | 'timestamp'> = {
        conversationId: selectedConversationId,
        content: `A partagé un fichier: ${file.name}`,
        senderId: 'currentUser',
        attachments: [attachment],
        mentions: [],
        read: true,
      };
      
      addMessage(newMessage);
      setAttachmentDialogOpen(false);
    }
  };

  const selectedConversation = conversations.find(
    (conv) => conv.id === selectedConversationId
  );

  const conversationMessages = selectedConversationId
    ? messages[selectedConversationId] || []
    : [];

  return (
    <Box sx={{ height: 'calc(100vh - 140px)' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        {/* Liste des conversations */}
        <Grid item xs={12} md={4} sx={{ height: '100%' }}>
          <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Barre de recherche et filtres */}
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', flex: 1, bgcolor: 'background.default', borderRadius: 1 }}>
                <IconButton sx={{ p: 1 }}>
                  <Search />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={t('messages.searchPlaceholder', 'Rechercher une conversation...')}
                />
              </Box>
              <IconButton onClick={handleFilterClick}>
                <FilterList />
              </IconButton>
              <Menu
                anchorEl={filterAnchorEl}
                open={Boolean(filterAnchorEl)}
                onClose={handleFilterClose}
              >
                <MenuItem>{t('messages.filters.all', 'Toutes les conversations')}</MenuItem>
                <MenuItem>{t('messages.filters.unread', 'Non lus')}</MenuItem>
                <MenuItem>{t('messages.filters.documents', 'Documents')}</MenuItem>
                <MenuItem>{t('messages.filters.clients', 'Clients')}</MenuItem>
              </Menu>
            </Box>

            <Divider />

            {/* Liste des conversations */}
            <List sx={{ flex: 1, overflow: 'auto' }}>
              {conversations.map((conv) => (
                <ListItem
                  key={conv.id}
                  button
                  selected={selectedConversationId === conv.id}
                  onClick={() => setSelectedConversation(conv.id)}
                >
                  <ListItemAvatar>
                    <Badge
                      badgeContent={conv.unreadCount}
                      color="primary"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <Avatar>{conv.type === 'document' ? '📄' : conv.title[0]}</Avatar>
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1">{conv.title}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {new Date(conv.updatedAt).toLocaleTimeString()}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {conv.lastMessage?.content}
                        </Typography>
                        {conv.status === 'urgent' && (
                          <Chip
                            label={t('messages.status.urgent', 'Urgent')}
                            size="small"
                            color="error"
                            sx={{ height: 20 }}
                          />
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Zone de conversation */}
        <Grid item xs={12} md={8} sx={{ height: '100%' }}>
          {selectedConversation ? (
            <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {/* En-tête de la conversation */}
              <Box sx={{ p: 2, display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
                <Avatar sx={{ mr: 2 }}>
                  {selectedConversation.type === 'document' ? '📄' : selectedConversation.title[0]}
                </Avatar>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">
                    {selectedConversation.title}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {selectedConversation.labels.map((label) => (
                      <Chip
                        key={label}
                        icon={<Label />}
                        label={t(`messages.labels.${label}`, getLabelDefaultText(label))}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Box>
                <IconButton>
                  <MoreVert />
                </IconButton>
              </Box>

              {/* Zone des messages */}
              <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                {conversationMessages.map((message: Message) => (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: message.senderId === 'currentUser' ? 'row-reverse' : 'row',
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        bgcolor: message.senderId === 'currentUser' ? 'primary.main' : 'background.default',
                        color: message.senderId === 'currentUser' ? 'primary.contrastText' : 'text.primary',
                        borderRadius: 2,
                        p: 2,
                      }}
                    >
                      <Typography variant="body1">{message.content}</Typography>
                      {message.attachments.map((attachment) => (
                        <Box
                          key={attachment.id}
                          sx={{
                            mt: 1,
                            p: 1,
                            bgcolor: 'rgba(0, 0, 0, 0.04)',
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <AttachFile sx={{ fontSize: 20 }} />
                          <Typography variant="body2">
                            {attachment.name}
                          </Typography>
                        </Box>
                      ))}
                      <Typography variant="caption" color="inherit" sx={{ opacity: 0.7 }}>
                        {new Date(message.timestamp).toLocaleTimeString()}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>

              {/* Zone de saisie */}
              <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <TextField
                    fullWidth
                    multiline
                    maxRows={4}
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    placeholder={t('messages.typePlaceholder', 'Écrivez votre message...')}
                    variant="outlined"
                    size="small"
                  />
                  <IconButton 
                    color="primary"
                    onClick={() => setAttachmentDialogOpen(true)}
                  >
                    <AttachFile />
                  </IconButton>
                  <Button
                    variant="contained"
                    endIcon={<Send />}
                    onClick={handleSendMessage}
                    disabled={!messageText.trim()}
                  >
                    {t('messages.send', 'Envoyer')}
                  </Button>
                </Box>
              </Box>
            </Paper>
          ) : (
            <Paper
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6" color="text.secondary">
                {t('messages.selectConversation', 'Sélectionnez une conversation pour commencer')}
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* Dialog pour les pièces jointes */}
      <Dialog
        open={attachmentDialogOpen}
        onClose={() => setAttachmentDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {t('messages.attachFile', 'Joindre un fichier')}
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            onClick={() => setAttachmentDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          <Button
            variant="outlined"
            fullWidth
            onClick={() => fileInputRef.current?.click()}
            sx={{ mt: 2 }}
          >
            {t('messages.selectFile', 'Sélectionner un fichier')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttachmentDialogOpen(false)}>
            {t('common.cancel', 'Annuler')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Messages;
