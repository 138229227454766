import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  updateDoc,
  doc,
  writeBatch,
  getDocs,
  serverTimestamp,
  addDoc,
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { Notification, NotificationType } from '../types/notification';

const NOTIFICATIONS_COLLECTION = 'notifications';
const PAGE_SIZE = 10;

interface SubscriptionCallbacks {
  onData: (data: {
    notifications: Notification[];
    unreadCount: number;
    lastDoc: any;
  }) => void;
  onError: (error: Error) => void;
}

export const subscribeToNotifications = (userId: string, callbacks: SubscriptionCallbacks) => {
  const notificationsRef = collection(db, NOTIFICATIONS_COLLECTION);
  const q = query(
    notificationsRef,
    where('userId', '==', userId),
    orderBy('createdAt', 'desc'),
    limit(PAGE_SIZE)
  );

  return onSnapshot(
    q,
    (snapshot) => {
      const notifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      })) as Notification[];

      const unreadCount = notifications.filter(n => !n.read).length;

      callbacks.onData({
        notifications,
        unreadCount,
        lastDoc: snapshot.docs[snapshot.docs.length - 1],
      });
    },
    (error) => callbacks.onError(error)
  );
};

export const markNotificationAsRead = async (userId: string, notificationId: string): Promise<void> => {
  const notificationRef = doc(db, NOTIFICATIONS_COLLECTION, notificationId);
  await updateDoc(notificationRef, {
    read: true,
    updatedAt: serverTimestamp(),
  });
};

export const markAllNotificationsAsRead = async (userId: string): Promise<void> => {
  const batch = writeBatch(db);
  const q = query(
    collection(db, NOTIFICATIONS_COLLECTION),
    where('userId', '==', userId),
    where('read', '==', false)
  );

  const snapshot = await getDocs(q);
  snapshot.docs.forEach(doc => {
    batch.update(doc.ref, {
      read: true,
      updatedAt: serverTimestamp(),
    });
  });

  await batch.commit();
};

export const createNotification = async (
  params: {
    userId: string;
    type: NotificationType;
    title: string;
    message?: string;
    data?: any;
    link?: string;
  }
): Promise<void> => {
  const notification = {
    userId: params.userId,
    type: params.type,
    title: params.title,
    message: params.message,
    data: params.data,
    read: false,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    link: params.link
  };

  await addDoc(collection(db, 'notifications'), notification);
};