import axios from 'axios';
import { cacheService } from './cacheService';
import { CompanyData } from '../types/reports';

const PAPPERS_API_KEY = import.meta.env.VITE_PAPPERS_API_KEY;
const PAPPERS_API_URL = import.meta.env.VITE_PAPPERS_API_URL;

const CACHE_NAMESPACE = 'pappers';
const CACHE_KEYS = {
  COMPANY_DATA: (siren: string) => cacheService.getKeyWithNamespace(CACHE_NAMESPACE, `company:${siren}`),
  USER_COMPANIES: 'user:companies'
};

// Extrait le token du document à partir de son URL
const extractDocumentToken = (url: string): string => {
  try {
    // L'URL est de la forme : https://api.pappers.fr/v2/document/[TOKEN].pdf
    const token = url.split('/').pop()?.split('.')[0];
    if (!token) {
      throw new Error('Token non trouvé dans l\'URL');
    }
    return token;
  } catch (error) {
    console.error('Erreur lors de l\'extraction du token:', error);
    throw new Error('URL du document invalide');
  }
};

export const fetchCompanyData = async (siren: string, forceRefresh: boolean = false) => {
  try {
    // Vérifier le cache d'abord (sauf si forceRefresh est true)
    const cachedData = await cacheService.getItem<CompanyData>(
      CACHE_KEYS.COMPANY_DATA(siren),
      { forceRefresh }
    );

    if (cachedData && !forceRefresh) {
      console.log('Données récupérées depuis le cache pour le SIREN:', siren);
      return cachedData;
    }

    // Si pas en cache, expiré, ou forceRefresh, faire l'appel API
    console.log(`Récupération des données depuis l'API Pappers pour le SIREN: ${siren}${forceRefresh ? ' (rafraîchissement forcé)' : ''}`);
    const response = await axios.get(`${PAPPERS_API_URL}/entreprise`, {
      params: {
        api_token: PAPPERS_API_KEY,
        siren: siren,
        integrer_diffusions_bodacc: true,
        integrer_publications_bodacc: true,
        integrer_comptes: true,
        integrer_documents: true,
      },
    });

    // Retourner directement les données brutes de l'API
    const apiData = response.data;
    console.log('Données reçues de l\'API Pappers:', apiData);

    // Sauvegarder dans le cache
    await cacheService.setItem(CACHE_KEYS.COMPANY_DATA(siren), apiData);

    return apiData;
  } catch (error: any) {
    console.error('Erreur lors de la récupération des données Pappers:', error);
    throw new Error(error.response?.data?.message || error.message || 'Erreur lors de la récupération des données');
  }
};

export const fetchUserCompanies = async (forceRefresh: boolean = false) => {
  try {
    // Vérifier le cache d'abord (sauf si forceRefresh est true)
    const cachedData = await cacheService.getItem<Array<{ siren: string; name: string; }>>(
      CACHE_KEYS.USER_COMPANIES,
      { forceRefresh }
    );

    if (cachedData) {
      console.log('Liste des entreprises récupérée depuis le cache');
      return cachedData;
    }

    // Si pas en cache, expiré, ou forceRefresh, faire l'appel API
    console.log(`Récupération de la liste des entreprises depuis l'API${forceRefresh ? ' (rafraîchissement forcé)' : ''}`);
    const companies = [
      {
        siren: "498380344",
        name: "INSITOO IT",
      },
      {
        siren: "519938815",
        name: "SOCIETE GENERALE DE COURTAGE D'ASSURANCES",
      },
    ];

    // Sauvegarder dans le cache
    await cacheService.setItem(CACHE_KEYS.USER_COMPANIES, companies);

    return companies;
  } catch (error) {
    console.error('Erreur lors de la récupération de la liste des entreprises:', error);
    throw error;
  }
};

export const downloadDocument = async (documentUrl: string): Promise<Blob> => {
  try {
    const token = extractDocumentToken(documentUrl);
    
    const response = await axios.get(
      `${PAPPERS_API_URL}/document/telechargement`, 
      {
        params: { 
          token,
          api_token: PAPPERS_API_KEY 
        },
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf',
        }
      }
    );

    if (!response.data || response.headers['content-type'] !== 'application/pdf') {
      throw new Error('Le document téléchargé n\'est pas un PDF valide');
    }

    return response.data;
  } catch (error: any) {
    console.error('Erreur lors du téléchargement du document:', error);
    
    if (error.response?.status === 401) {
      throw new Error('Erreur d\'authentification avec l\'API Pappers');
    } else if (error.response?.status === 404) {
      throw new Error('Document non trouvé');
    }
    
    throw error.message ? error : new Error('Impossible de télécharger le document');
  }
};
